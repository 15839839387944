import _ from "lodash";
import { Switch, SwitchProps } from "@samacare/design";

import { useFormContext, UseControllerProps, useWatch } from "react-hook-form";
import { InputHTMLAttributes } from "react";

export type SwitchFieldProps = SwitchProps & UseControllerProps;

const SwitchField: React.FC<SwitchFieldProps> = ({
  name,
  value,
  defaultChecked = false,
  shouldUnregister = false,
  rules = {},
  ...materialProperties
}) => {
  const { control, register } = useFormContext();
  const { ref, ...fields } = register(name, {
    ...rules,
    shouldUnregister,
    value,
  });

  // controlled
  const checked = useWatch({
    name,
    control,
    defaultValue: !!defaultChecked,
  });

  const id = `SwitchField-${name}`;
  const props = {
    id,
    inputRef: ref,
    checked,
    ...fields,
    ...materialProperties,
    inputProps: {
      "data-cy": _.camelCase(`field.${name}`),
    } as InputHTMLAttributes<HTMLInputElement>,
  };

  return <Switch {...props} />;
};

export default SwitchField;
