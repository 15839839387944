import { useState } from "react";
import * as React from "react";
import { UseFormRegisterReturn } from "@samacare/form";
import { Input } from "./Input";
import { Eye } from "@@ui-kit/Icons";

export type PasswordFieldProps = React.ComponentProps<typeof PasswordField>;

/**
 * A password field with an eye icon that the user can click on to hide or show the password if permissible.
 */
/**
 * @deprecated Use component from design/forms instead
 */
export const PasswordField: React.VoidFunctionComponent<{
  inputId: string;
  placeholderText?: string;
  formRegister: UseFormRegisterReturn;
  canUserViewPassword: boolean;
}> = ({ inputId, placeholderText = "", formRegister, canUserViewPassword }) => {
  const [passwordHidden, setPasswordHidden] = useState(true);

  const handleChangePasswordHidden = () => {
    if (canUserViewPassword) {
      setPasswordHidden((prevValue) => !prevValue);
    }
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Input
        autoComplete="new-password"
        id={inputId}
        placeholder={placeholderText}
        {...formRegister}
        type={passwordHidden ? "password" : "text"}
      />
      {canUserViewPassword ? (
        <Eye
          style={{
            fontSize: "24px",
            marginLeft: "-30px",
          }}
          onClick={handleChangePasswordHidden}
        />
      ) : null}
    </div>
  );
};
