import { useFormContext, TextField, AutocompleteField } from "@samacare/form";
import { Stack, Typography, Box } from "@samacare/design/core";
import { QuestionnaireField } from "../../components/Questionnaire";
import { QuestionTypeEnumType } from "@samacare/graphql";
import { useEnrollmentContext } from "../../routes/Enrollment/EnrollmentProvider";
import Link from "@samacare/design/core/Link";

export type PrescriptionInfoBlockProps = {
  isSubmitted?: boolean;
  isRequired?: boolean;
};

export const PrescriptionInfoBlock: React.FC<PrescriptionInfoBlockProps> = ({
  isSubmitted = false,
  isRequired = false,
}) => {
  const { watch } = useFormContext();
  const { enrollmentProgram } = useEnrollmentContext();

  return (
    <Stack direction="column">
      <Stack mb={2} spacing={2} direction="column">
        <Typography variant="body1" color="text.primary">
          To view administrative instructions –{" "}
          <Link
            href={enrollmentProgram?.pdfUrl ?? ""}
            target="_blank"
            rel="noreferrer"
          >
            View form here
          </Link>
        </Typography>
        <Box>
          <strong> Medication:</strong> TEPEZZA® (teprotumumab-trbw) for
          injection, for intravenous use // 500-mg vial
        </Box>
      </Stack>

      <Typography variant="body1" color="grey.A300">
        Does
      </Typography>
      <Stack my={2} spacing={2} direction="row" maxWidth="600px">
        <Stack ml={2} direction="column" width="100%">
          <TextField
            label="Infusion 1"
            name="questionnaire.sama-prescriptionInfo-infusion1"
            disabled={isSubmitted}
            placeholder="mg (10mg/kg)"
            type={QuestionTypeEnumType.Number}
            fullWidth
            required={isRequired}
          />
          <Typography variant="caption" color="grey.A200">
            21-day supply; 1 prescription; no refill
          </Typography>
        </Stack>

        <Stack ml={2} direction="column" width="100%">
          <TextField
            label="Infusion 2-8"
            name="questionnaire.sama-prescriptionInfo-infusion2To8"
            type={QuestionTypeEnumType.Number}
            disabled={isSubmitted}
            placeholder="mg (20mg/kg)"
            fullWidth
            required={isRequired}
          />
          <Typography variant="caption" color="grey.A200">
            21-day supply; 1 prescription; 6 refills
          </Typography>
        </Stack>
      </Stack>
      <Stack my={2} spacing={2} direction="row" maxWidth="600px">
        <TextField
          label="Weight"
          name="patient.weightPounds"
          disabled={isSubmitted}
          type={QuestionTypeEnumType.Number}
          fullWidth
          required={isRequired}
        />
        <AutocompleteField<string, false, true, false>
          disableClearable
          options={["lbs", "kgs"]}
          filterSelectedOptions
          label="Unit"
          name="questionnaire.sama-prescriptionInfo-patientWeightUnit"
          required={isRequired}
          value={
            watch("questionnaire.sama-prescriptionInfo-patientWeightUnit") ??
            undefined
          }
          disabled={isSubmitted}
        />
      </Stack>
      <Stack my={2} maxWidth="600px">
        <TextField
          label="Drug Allergies"
          name="questionnaire.sama-prescriptionInfo-allergies"
          disabled={isSubmitted}
          fullWidth
        />
      </Stack>
      <Stack spacing={2} direction="column">
        <QuestionnaireField
          id="sama-prescriptionInfo-medicalUrgent"
          type={QuestionTypeEnumType.Boolean}
          text="Patient is Medically Urgent. I attest the patient is both (1) is experiencing compressive optic neuropathy secondary to Thyroid Eye Disease and (2) requires accelerated treatment with TEPEZZA."
          disabled={isSubmitted}
        />
        <QuestionnaireField
          id="sama-prescriptionInfo-nursingOrder"
          type={QuestionTypeEnumType.Boolean}
          text="Nursing orders for home infusion: Provide skilled nursing visit to administer medication, provide education, and assess patient (required for home infusion).
          Saline flushes and other administration supplies authorized as needed."
          disabled={isSubmitted}
        />
      </Stack>
    </Stack>
  );
};
