import {
  useForm,
  SubmitHandler,
  FormProvider,
  ErrorMessage,
  TextField,
} from "@samacare/form";

import { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  Alert,
  Stack,
  Typography,
  LoadingButton,
} from "@samacare/design/core";

import { useAuth } from "../../providers/AuthProvider";
import { format } from "date-fns";
import { AuthTitle } from "./AuthTitle";

type AuthVerificationForm = {
  confirmationCode: string;
};

const LOCAL_STORAGE_TIMEOUT = "samacare-verification-reset";

function getResetTimeout() {
  const item = localStorage.getItem(LOCAL_STORAGE_TIMEOUT);

  if (item === null) {
    return 0;
  }
  const start = Date.now();
  const end = parseInt(item);

  const reset = end - start;

  if (reset <= 0) {
    localStorage.removeItem(LOCAL_STORAGE_TIMEOUT);
    return 0;
  }

  return reset;
}

export const AuthVerification: React.FC = () => {
  const [reset, setReset] = useState(() => getResetTimeout());
  const [sent, setSent] = useState(() => reset > 0);

  const methods = useForm<AuthVerificationForm>();
  const {
    onError,
    onSignOut,
    onConfirmUserAttribute,
    onSendUserAttributeVerificationCode,
    error,
    loading,
  } = useAuth();

  const onSetTimeout = () => {
    localStorage.setItem(LOCAL_STORAGE_TIMEOUT, String(Date.now() + 120000));
    setReset(getResetTimeout());
  };

  const onSubmit: SubmitHandler<AuthVerificationForm> = async ({
    confirmationCode,
  }) => {
    await onConfirmUserAttribute({
      userAttributeKey: "email",
      confirmationCode,
    });
  };

  const sendVerificationCode = useCallback(
    async function sendVerificationCodeCallback() {
      try {
        await onSendUserAttributeVerificationCode({
          userAttributeKey: "email",
        });
      } catch (err) {
        onError((err as Error)?.message ?? err);
      }
    },
    [onSendUserAttributeVerificationCode, onError]
  );

  const hasResetTimeout = reset > 0;

  useEffect(() => {
    if (!hasResetTimeout && !loading && !sent) {
      setSent(true);
      void sendVerificationCode();
    }
  }, [hasResetTimeout, loading, sent, sendVerificationCode]);

  useEffect(() => {
    if (hasResetTimeout) {
      setTimeout(() => {
        setReset(getResetTimeout());
      }, 1000);
    }
  }, [reset, hasResetTimeout, setReset]);

  return (
    <FormProvider {...methods}>
      <form name="login" onSubmit={methods.handleSubmit(onSubmit)} noValidate>
        <AuthTitle>Enter Verification Code</AuthTitle>
        <Stack spacing={2}>
          <Typography variant="body2">
            A verification code was sent to your email address.
          </Typography>
          <Button
            type="button"
            disabled={hasResetTimeout}
            onClick={async () => {
              onSetTimeout();
              await onSendUserAttributeVerificationCode({
                userAttributeKey: "email",
              });
            }}
          >
            {hasResetTimeout ? (
              <>Request new code in {format(reset, "mm:ss")}</>
            ) : (
              <>Request new code</>
            )}
          </Button>
          <Box pt={1}>
            <TextField
              type="text"
              inputMode="numeric"
              fullWidth
              size="medium"
              name="confirmationCode"
              label="Code"
              rules={{
                minLength: 6,
                maxLength: 6,
              }}
              InputLabelProps={{
                shrink: false,
              }}
            />
          </Box>
          <ErrorMessage
            name="confirmationCode"
            errors={methods.formState.errors}
            render={() => <Alert color="error">Code must be six numbers</Alert>}
          />
          {error && <Alert color="error">{error}</Alert>}
          <LoadingButton
            loading={loading}
            type="submit"
            disabled={!methods.formState.isDirty}
            size="large"
            variant="contained"
          >
            Verify
          </LoadingButton>
          <LoadingButton
            size="large"
            loading={loading}
            onClick={async () => {
              await onSignOut();
            }}
          >
            Back to Login
          </LoadingButton>
        </Stack>
      </form>
    </FormProvider>
  );
};
