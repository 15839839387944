export const isValidQuantity = (value?: string): boolean => {
  if (!value || value !== value.trim()) return false;

  const parsedValue = Number(value);

  if (Number.isInteger(parsedValue)) {
    return parsedValue > 0;
  }

  return false;
};

export const ensurePositiveValue = (value: string): string => {
  if (value === "") return "";

  const parsedValue = Number(value);

  if (!Number.isInteger(parsedValue) || parsedValue === 0) {
    return "1";
  }

  return parsedValue < 0
    ? Math.abs(parsedValue).toString()
    : parsedValue.toString();
};
