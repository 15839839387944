import { Stack, Typography } from "@samacare/design";

import { useUnifiedBenefitsCheckContext } from "../UnifiedBenefitsCheckProvider";

const getStatusLabel = (status?: string | null) => {
  if (status == null) {
    return { label: "-", color: "primary" };
  }

  if (status.toLowerCase().startsWith("covered")) {
    return { label: status, color: "green" };
  }
  if (status.toLowerCase().startsWith("inactive")) {
    return { label: status, color: "error" };
  }

  return { label: status || "-", color: "primary" };
};

export const CareMetxMedeBvCoverageStatus: React.FC = () => {
  const { unifiedBenefitsCheck } = useUnifiedBenefitsCheckContext();
  const { label, color } = getStatusLabel(
    unifiedBenefitsCheck?.fullData?.outcome?.data?.EBvResponse?.Status
  );
  return (
    <Stack flexDirection="row">
      <Typography variant="subtitle2" color={color}>
        {label}
      </Typography>
    </Stack>
  );
};
