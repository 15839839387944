import { Stack } from "@samacare/design/core";
import { EnrollmentSection } from "./EnrollmentSection";
import { QuestionnaireField } from "../../components/Questionnaire";
import { QuestionTypeEnumType } from "@samacare/graphql";
import { useWatch, PhoneField, TextField } from "@samacare/form";

export type CareGiverInfoBlockProps = {
  isSubmitted?: boolean;
};

export const CareGiverInfoBlock: React.FC<CareGiverInfoBlockProps> = ({
  isSubmitted = false,
}) => {
  const questionnaireData = useWatch({
    name: "questionnaire",
  });

  return (
    <EnrollmentSection title="Caregiver Information (optional)">
      <Stack my={2} ml={2} spacing={2} direction="row">
        <QuestionnaireField
          id="ifHasCareGiverInfo"
          type={QuestionTypeEnumType.Choice}
          text="Does patient have a caregiver with whom they would like ApellisAssist to share information?"
          disabled={isSubmitted}
          answerOption={[{ valueString: "Yes" }, { valueString: "No" }]}
          required
        />
      </Stack>
      {questionnaireData.ifHasCareGiverInfo === "Yes" && (
        <Stack ml={2} direction="column">
          <EnrollmentSection title="Caregiver Information">
            <Stack gap={2}>
              <Stack gap={1} direction="row" maxWidth={600}>
                <TextField
                  name="questionnaire.sama-careGiver-firstName"
                  label="First name"
                  fullWidth
                  required
                  disabled={isSubmitted}
                />
                <TextField
                  name="questionnaire.sama-careGiver-lastName"
                  label="Last name"
                  fullWidth
                  required
                  disabled={isSubmitted}
                />
              </Stack>
              <Stack gap={1} direction="row" maxWidth={600}>
                <PhoneField
                  name="questionnaire.sama-careGiver-phone"
                  label="Phone"
                  fullWidth
                  disabled={isSubmitted}
                  usePhoneType
                  PhoneTypeProps={{
                    name: "questionnaire.sama-careGiver-phoneType",
                  }}
                  required
                />
                <TextField
                  name="questionnaire.sama-careGiver-email"
                  label="Email"
                  disabled={isSubmitted}
                  fullWidth
                  required
                />
              </Stack>
              <QuestionnaireField
                id="careGiverRelationship"
                type={QuestionTypeEnumType.Choice}
                text="What is the caregiver’s relationship to the patient?"
                disabled={isSubmitted}
                answerOption={[
                  { valueString: "Legal Guardian" },
                  { valueString: "Spouse" },
                  { valueString: "Sibling" },
                  { valueString: "other" },
                ]}
                required
              />
              {questionnaireData.careGiverRelationship === "other" && (
                <TextField
                  name="questionnaire.sama-careGiver-relationship"
                  label="Relationship"
                  fullWidth
                  required
                  disabled={isSubmitted}
                />
              )}
            </Stack>
          </EnrollmentSection>
        </Stack>
      )}
    </EnrollmentSection>
  );
};
