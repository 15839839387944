import SegmentSelector from "Segment/SegmentSelector";
import _ from "lodash";
import { useState, useEffect } from "react";

interface InsuranceStateProps {
  title: React.ReactNode;
  id: string;
  help: string;
  value: string | null;
  onChange: (value: string | null) => void;
  highlightRequiredFields: boolean;
  supportedStates?: string[];
}

interface Option {
  value: string;
  label: string;
}

const stateOptions = _.map(window.CONFIG.CONSTANTS.STATES, (value, key) => ({
  value: key,
  label: value,
}));

const filterStateOptions = (options: Option[], supportedStates?: string[]) => {
  if (!supportedStates || !supportedStates.length) return options;

  return options.filter(({ value }) => supportedStates.includes(value));
};

const InsuranceState: React.FC<InsuranceStateProps> = ({
  id,
  title,
  help,
  value,
  onChange,
  highlightRequiredFields,
  supportedStates,
}) => {
  const [selectedValue, setSelectedValue] = useState<Option | null>(null);

  const filteredStateOptions = filterStateOptions(
    stateOptions,
    supportedStates
  );

  const onlyOneOption = supportedStates?.length === 1;

  useEffect(() => {
    setSelectedValue(
      filteredStateOptions.find((o: Option) => o.value === value) || null
    );
  }, [value, filteredStateOptions, supportedStates]);

  return (
    <SegmentSelector
      id={id}
      title={title}
      help={help}
      required={true}
      allowInTagger={true}
      onChange={(o: Option) => onChange(o?.value)}
      highlight={highlightRequiredFields}
      options={filteredStateOptions}
      value={selectedValue}
      isNotClearable={onlyOneOption}
    />
  );
};

export default InsuranceState;
