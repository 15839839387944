import * as React from "react";
import { Box } from "@samacare/design/core";

interface ChromeWebStoreImageProps {
  sx?: React.CSSProperties;
}

export const ChromeWebStoreImage: React.FC<ChromeWebStoreImageProps> = ({
  sx,
}) => {
  return (
    <Box
      component="img"
      src="../../../../assets/chrome-web-store.png"
      alt="Available in the Chrome Web Store"
      sx={{
        width: 150,
        backgroundColor: "white",
        ...sx,
      }}
    />
  );
};
