import { Fragment, useEffect, useState } from "react";
import { PatientColumn, patientColumnDetails } from "./PatientColumns";
import {
  Box,
  Button,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@samacare/design";
import ChevronRight from "@samacare/design/core/icons/ChevronRight";

import { SimpleSeparator } from "./SimpleSeparator";
import { UnusedColumns } from "./UnusedColumns";
import { LeftRightSpreadCenterV } from "@samacare/component";
import { NOT_IN_FILE } from "./UploadConstants";
import { ColumnErrorCode } from "./ColumnErrorCode";
import { ColumnErrorIcon } from "./ColumnErrorIcon";
import {
  generateAutoMappings,
  generateErrorMappings,
  resetAllMatches,
} from "./columnMappingUtils";
import { useConfirmDialog } from "../../../hooks/confirmDialogHook";
import { useCurrentAccount } from "../../../graphql/Account";
import { TopBottom } from "../../../components/TopBottomV2";

interface ColumnMapperProps {
  fileColumnNames: string[];
  onDone: (fromByToMappings: Map<string, string>) => Promise<void>;
  onBack: () => void;
  rows: any[];
}
export const ColumnMapper: React.VFC<ColumnMapperProps> = ({
  fileColumnNames,
  onDone,
  onBack,
  rows,
}) => {
  const [fromByToMappings, setFromByToMappings] = useState<Map<string, string>>(
    new Map()
  );
  const [errorMappings, setErrorMappings] = useState<
    Map<string, ColumnErrorCode>
  >(new Map());

  const [currentAccount] = useCurrentAccount();
  const institutionName = currentAccount?.institution?.name;

  const updateMapping = (patientColumnKey: string, fileField: string) => {
    const newFromByToMappings = new Map(fromByToMappings);
    resetAllMatches(newFromByToMappings, fileField);
    newFromByToMappings.set(patientColumnKey, fileField);
    setFromByToMappings(newFromByToMappings);
  };

  useEffect(() => {
    const autoFromByToMappings = generateAutoMappings(fileColumnNames);
    setFromByToMappings(autoFromByToMappings);
  }, [fileColumnNames]);

  useEffect(() => {
    const newErrorMappings = generateErrorMappings(fromByToMappings, rows);
    setErrorMappings(newErrorMappings);
  }, [fromByToMappings, rows]);

  const unusedHeaders = fileColumnNames.filter(
    (cn) => ![...fromByToMappings.values()].includes(cn)
  );

  const doNext = async () => {
    await onDone(fromByToMappings);
  };

  const [ConfirmDialog, confirmDialogProps, setConfirmDialogOpen] =
    useConfirmDialog({
      message: (
        <TopBottom sx={{ marginBottom: 1, width: "500px" }}>
          <Typography>
            {`Verify that the data you are uploading is for this institution.  Enter the institution's name to proceed.`}
          </Typography>
          <Typography fontWeight={600} sx={{ marginTop: 2 }}>
            {institutionName}
          </Typography>
        </TopBottom>
      ),
      confirm: doNext,
      zIndexOffset: 1,
      confirmText: institutionName,
      submitText: "Continue",
      backText: "Cancel",
      title: "Verify Institution",
    });

  const handleNextClick = async () => {
    if (currentAccount?.isSamaUser) {
      setConfirmDialogOpen(true);
    } else {
      await doNext();
    }
  };

  return (
    <div>
      <UnusedColumns unusedHeaders={unusedHeaders} />
      <SimpleSeparator />
      <Box
        justifyContent="center"
        display="flex"
        marginTop={({ spacing }) => spacing(2)}
      >
        <Grid container spacing={2} width="600px">
          {[...fromByToMappings.keys()].map((patientColumnKey: string) => (
            <Fragment key={patientColumnKey}>
              {/* Left Cell */}
              <Grid item xs={6}>
                <LeftRightSpreadCenterV sx={{ height: "100%" }}>
                  <Box>
                    {
                      patientColumnDetails[patientColumnKey as PatientColumn]
                        .label
                    }
                  </Box>
                  <ChevronRight />
                </LeftRightSpreadCenterV>
              </Grid>

              {/* Right Cell */}
              <Grid
                item
                xs={6}
                display="flex"
                position="relative"
                justifyContent="flex-end"
              >
                <Select
                  required={true}
                  size="small"
                  value={fromByToMappings.get(patientColumnKey)}
                  sx={{ width: "250px" }}
                  onChange={(event) =>
                    updateMapping(
                      patientColumnKey,
                      event.target.value as string
                    )
                  }
                >
                  <MenuItem key={NOT_IN_FILE} value={NOT_IN_FILE}>
                    -- Not in File --
                  </MenuItem>
                  {fileColumnNames.map((field) => (
                    <MenuItem key={field} value={field}>
                      {field}
                    </MenuItem>
                  ))}
                </Select>

                {/* Error Indicator */}
                {errorMappings.has(patientColumnKey) && (
                  <ColumnErrorIcon
                    errorCode={errorMappings.get(patientColumnKey)!}
                  />
                )}
              </Grid>
            </Fragment>
          ))}
        </Grid>
      </Box>

      {/* Footer */}
      <LeftRightSpreadCenterV
        sx={{
          marginTop: ({ spacing }) => spacing(6),
          marginBottom: ({ spacing }) => spacing(6),
        }}
      >
        <Button variant="contained" color="neutral" onClick={onBack}>
          Back
        </Button>
        <Button
          variant="contained"
          disabled={errorMappings.size > 0}
          onClick={handleNextClick}
        >
          Start Upload
        </Button>
      </LeftRightSpreadCenterV>
      <ConfirmDialog {...confirmDialogProps} />
    </div>
  );
};
