import useStateOption from "../hooks/useStateOption";
import { StateOption } from "../hooks/useStateOptions";
import {
  useFormContext,
  AutocompleteField,
  AutocompleteFieldProps,
} from "@samacare/form";

export type StateFieldProps = Omit<
  AutocompleteFieldProps<StateOption, false, true, false>,
  "options"
>;

export const StateField: React.FC<StateFieldProps> = ({ name, ...props }) => {
  const { watch } = useFormContext();

  const selected: string | undefined = watch(name);
  const { states, selectedState } = useStateOption(selected ?? "");

  return (
    <AutocompleteField<StateOption, false, true, false>
      name={name}
      disableClearable
      value={selectedState ?? null}
      options={states}
      filterSelectedOptions
      setValueAs={(v: StateOption | string) => {
        if (typeof v === "string") {
          return v;
        }
        return v?.id;
      }}
      {...props}
    />
  );
};
