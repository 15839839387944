import _ from "lodash";
import { useState } from "react";
import { useAlert } from "react-alert";
import moment from "moment";
import Modal from "./Modal";
import CorrespondenceViewer from "./CorrespondenceViewer";
import {
  updateAuthorizationMutation,
  AUTHORIZATION_PAGINATION_QUERY_NAME,
  ALL_AUTHORIZATIONS_QUERY_NAME,
  disassociateCorrespondenceMutation,
  GET_AUTHORIZATION_QUERY_NAME,
} from "../graphql/Authorization";
import {
  unsetCorrespondenceFromAuthorizationMutation,
  updateLatestAuthorizationCorrespondenceMutation,
} from "../graphql/AuthorizationCorrespondence";
import { CorrespondenceFieldUpdateContainer } from "./CorrespondenceFieldUpdateContainer";
import { Authorization, AuthorizationCorrespondence } from "@samacare/graphql";

import {
  DisassociateCorrespondenceMutation,
  UpdateAuthorizationMutation,
  UnsetCorrespondenceFromAuthorizationMutation,
  UpdateLatestAuthorizationCorrespondenceMutation,
} from "@@generated/graphql";

import { useMutation } from "@apollo/client";
import {
  Box,
  Button,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  LoadingButton,
  Radio,
  Alert,
  Typography,
  Stack,
} from "@samacare/design/core";

import AlertTitle from "@samacare/design/core/AlertTitle";
import { FormProvider, useForm, RadioGroupField } from "@samacare/form";
import DialogActions from "@samacare/design/core/DialogActions";
import DownloadIcon from "@samacare/design/core/icons/FileUploadOutlined";
import { AwsDocument, getDateValidationMessages } from "../util/authUtils";
import { useConfig, useSamaCareBrandName } from "@@hooks/config";
import { useUploadDocumentsToEmr } from "../graphql/Emr";
import { ConfirmationDialog } from "./ConfirmationDialog";
import DetailsContainer from "./DetailsContainer";
import { GET_AUTHORIZATION_LOGS_QUERY_NAME } from "@@hooks/useAuthorizationLogs";
import { Tooltip } from "@samacare/design";
import { NextGenDebugModal } from "./AuthorizationSharedComponents/ContextPane/NextGenDebugModal";
import { useHasEmrIntegration } from "@@hooks/useHasEmrIntegrations";

type FormInputs = {
  status: string;
  authApprovalNumber: string;
  startDate: string;
  endDate: string;
  disassociationReason: string;
};

export const STATUS = "status";
export const AUTH_APPROVAL_NUMBER = "authApprovalNumber";
export const START_DATE = "startDate";
export const END_DATE = "endDate";
const DISASSOCIATION_REASON = "disassociationReason";
const DISASSOCIATION_REASONS = [
  "incorrectPatient",
  "incorrectAuthorization",
  "other",
];

export const AssociateCorrespondenceModal: React.VoidFunctionComponent<{
  authorization: Authorization;
  closeModal: () => void;
  allowChanges: boolean;
  allowDisassociation: boolean;
  allowRemoval?: boolean;
  correspondence?: AuthorizationCorrespondence;
  openConfirmationModal?: () => void;
}> = ({
  authorization,
  closeModal,
  allowChanges,
  allowDisassociation,
  openConfirmationModal,
  correspondence,
  allowRemoval,
}) => {
  const [emrUploadLoading, setEmrUploadLoading] = useState(false);
  const [isDebugModalOpen, setIsDebugModalOpen] = useState(false);
  const [errorContext, setErrorContext] = useState("");
  const [showUnsavedChangesDialog, setShowUnsavedChangesDialog] =
    useState(false);
  const [showRemoveAuthDialog, setShowRemoveAuthDialog] = useState(false);

  const [validationError, setValidationError] = useState("");
  const alert = useAlert();
  const { CONSTANTS } = useConfig();
  const brandName = useSamaCareBrandName();
  const uploadDocumentsToEmr = useUploadDocumentsToEmr(
    authorization.InstitutionId!
  );

  const patientMRN = _.get(authorization, "patient.institutionPatientId");

  const selectedCorrespondence =
    correspondence || authorization.latestCorrespondence;

  const {
    hasUploadToModernizingMedicine,
    hasUploadToNextGen,
    hasUploadToOncoEmr,
    hasUploadToRedox,
  } = useHasEmrIntegration(authorization.InstitutionId!);

  const refetchQueries = [
    ALL_AUTHORIZATIONS_QUERY_NAME,
    AUTHORIZATION_PAGINATION_QUERY_NAME,
    GET_AUTHORIZATION_QUERY_NAME,
    GET_AUTHORIZATION_LOGS_QUERY_NAME,
  ];

  const [updateLatestAuthorizationCorrespondence] =
    useMutation<UpdateLatestAuthorizationCorrespondenceMutation>(
      updateLatestAuthorizationCorrespondenceMutation,
      {
        refetchQueries,
      }
    );

  const [updateAuthorization] = useMutation<UpdateAuthorizationMutation>(
    updateAuthorizationMutation,
    {
      refetchQueries,
    }
  );

  const [disassociateCorrespondence] =
    useMutation<DisassociateCorrespondenceMutation>(
      disassociateCorrespondenceMutation,
      {
        refetchQueries,
      }
    );

  const [unsetCorrespondenceFromAuthorization] =
    useMutation<UnsetCorrespondenceFromAuthorizationMutation>(
      unsetCorrespondenceFromAuthorizationMutation,
      {
        refetchQueries,
      }
    );

  const handleDelete = async () => {
    try {
      await unsetCorrespondenceFromAuthorization({
        variables: {
          authorizationId: authorization.id,
          correspondenceId: selectedCorrespondence?.id,
        },
      });
      alert.success("File removed.");
      closeModal();
      if (openConfirmationModal) {
        openConfirmationModal();
      }
    } catch {
      alert.error("There was an error removing the file.");
    }
  };

  const handleSave = async (auth: Authorization, data: FormInputs) => {
    const { status, authApprovalNumber, startDate, endDate } = data;

    const isStartDateDif =
      startDate &&
      moment(startDate) !== moment(auth.latestCorrespondence?.startDate);
    const isEndDateDif =
      endDate && moment(endDate) !== moment(auth.latestCorrespondence?.endDate);
    const isStatusDif = status !== auth.status.toString();
    const isAuthApprovalNumberDif =
      authApprovalNumber !== auth.latestCorrespondence?.code;
    try {
      // Add fields to update auth if user updates the fields & auth is APPROVED
      if (status === CONSTANTS.AUTHORIZATION_STATUSES.APPROVED) {
        const { errorMessages } = getDateValidationMessages({
          correspondenceStartDate: startDate
            ? moment(startDate).format(CONSTANTS.DATE_FORMAT)
            : undefined,
          correspondenceEndDate: endDate
            ? moment(endDate).format(CONSTANTS.DATE_FORMAT)
            : undefined,
        });

        if (!_.isEmpty(errorMessages)) {
          setValidationError(errorMessages.join(", "));
          throw new Error();
        }

        await updateLatestAuthorizationCorrespondence({
          variables: {
            authorizationId: auth.id,
            ...(isStartDateDif
              ? {
                  startDate: moment(startDate).toDate(),
                }
              : {}),
            ...(isEndDateDif
              ? {
                  endDate: moment(endDate).toDate(),
                }
              : {}),
            ...(isAuthApprovalNumberDif ? { code: authApprovalNumber } : {}),
          },
        });
      }

      await updateAuthorization({
        variables: {
          id: auth.id,
          patch: {
            requiresAssociationReview: false,
            // Update fields if changed by user
            ...(isStatusDif ? { status } : {}),
          },
        },
      });

      closeModal();
      alert.success("Auth updated.");
    } catch (e) {
      alert.error("There was an error updating the auth.");
    }
  };

  const handleIncorrectAutoAssociation = async (
    auth: Authorization,
    reason: string
  ) => {
    if (!reason) {
      alert.error("Reason is required");
      return;
    }

    try {
      await disassociateCorrespondence({
        variables: {
          authorizationId: auth.id,
          correspondenceId: selectedCorrespondence?.id,
          ...(reason ? { reason } : {}),
        },
      });
      alert.success("File removed from auth.");
      closeModal();
      if (openConfirmationModal) {
        openConfirmationModal();
      }
    } catch {
      alert.error("There was an error removing the file from auth.");
    }
  };

  const methods = useForm<FormInputs>({
    defaultValues: {
      [STATUS]: authorization.status,
      [START_DATE]: authorization.latestCorrespondence?.startDate ?? "",

      [END_DATE]: authorization.latestCorrespondence?.endDate ?? "",
      [AUTH_APPROVAL_NUMBER]: authorization.latestCorrespondence?.code ?? "",
      [DISASSOCIATION_REASON]: "",
    },
  });

  const handleUpload = async (
    document: AuthorizationCorrespondence
  ): Promise<void> => {
    if (
      hasUploadToNextGen ||
      hasUploadToOncoEmr ||
      hasUploadToModernizingMedicine ||
      hasUploadToRedox
    ) {
      setEmrUploadLoading(true);
      alert.info("Documents are being uploaded!");
    }

    const documents: AwsDocument[] = [
      {
        ...document,
        fileURL: document.fileURL as string,
        name: document.fileAWSKey as string,
        awsKey: document.fileAWSKey as string,
        title: `Authorization Correspondence - ${moment(
          document.createdAt
        ).format(CONSTANTS.DATE_FORMAT)}`,
      },
    ];

    const { error, requiresDebug } = await uploadDocumentsToEmr({
      patientMRN,
      documents,
      authorizationId: authorization.id,
    });

    if (error) {
      if (requiresDebug) {
        setErrorContext(error.message);
        setIsDebugModalOpen(true);
      } else {
        alert.error(error.message);
      }
    } else {
      alert.info("Successfully uploaded files to EMR!");
      setEmrUploadLoading(false);
    }
  };

  const data = methods.watch();
  return (
    <div>
      <FormProvider {...methods}>
        <Modal
          open={authorization != null}
          header="View response"
          onClick={() => {
            allowChanges ? setShowUnsavedChangesDialog(true) : closeModal();
          }}
          styleOverride={{ padding: "32px" }}
          headerStyleOverride={{ textAlign: "left" }}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            maxHeight={({ spacing }) => spacing(80)}
          >
            <Stack
              direction="column"
              spacing={2}
              sx={{ marginTop: ({ spacing }) => spacing(2) }}
            >
              {allowChanges && (
                <Alert variant="filled" severity="info">
                  <AlertTitle>
                    {`This response is attached by ${brandName}, confirm the
                    attachment and status are correct`}
                  </AlertTitle>
                </Alert>
              )}
              <Stack
                margin={({ spacing }) => spacing(1)}
                width={({ spacing }) => spacing(150)}
                spacing={2}
                direction="row"
              >
                <CorrespondenceViewer
                  correspondence={
                    selectedCorrespondence as AuthorizationCorrespondence
                  }
                  width="620px"
                  height="480px"
                />
                <Stack direction="column" spacing={2}>
                  <Typography color="primary" variant="h6">
                    Latest Auth Detail
                  </Typography>
                  <DetailsContainer authorization={authorization} />
                  {allowChanges && (
                    <CorrespondenceFieldUpdateContainer
                      validationError={validationError}
                      status={data.status}
                    />
                  )}
                </Stack>
              </Stack>
              <Box>
                {(hasUploadToModernizingMedicine ||
                  hasUploadToNextGen ||
                  hasUploadToOncoEmr) &&
                  patientMRN && (
                    <LoadingButton
                      loading={emrUploadLoading}
                      onClick={async () => {
                        await handleUpload(
                          selectedCorrespondence as AuthorizationCorrespondence
                        );
                      }}
                      startIcon={<DownloadIcon />}
                      variant="outlined"
                    >
                      Upload File to EMR
                    </LoadingButton>
                  )}
              </Box>
            </Stack>
          </Box>
          <DialogActions
            sx={{ justifyContent: allowChanges ? "space-between" : "flex-end" }}
          >
            {allowChanges && (
              <Button
                onClick={() => {
                  setShowUnsavedChangesDialog(true);
                }}
              >
                Cancel
              </Button>
            )}
            <Stack direction="row" spacing={2}>
              {allowRemoval && (
                <Tooltip
                  placement="top"
                  title={`Remove file from ${brandName}`}
                >
                  <Button onClick={handleDelete} variant="outlined">
                    Delete
                  </Button>
                </Tooltip>
              )}
              {allowDisassociation && (
                <Tooltip
                  placement="top"
                  title={`Send file back to ${brandName} to attach to the correct authorization`}
                >
                  <Button
                    onClick={async () => {
                      setShowRemoveAuthDialog(true);
                    }}
                    sx={{ paddingRight: ({ spacing }) => spacing(1) }}
                    variant="outlined"
                  >
                    Wrong File?
                  </Button>
                </Tooltip>
              )}
              {allowChanges ? (
                <Button
                  onClick={async () => {
                    await handleSave(authorization, data);
                  }}
                  variant="contained"
                  data-cy="controlSaveCorrespondenceAssociation"
                >
                  Save
                </Button>
              ) : (
                <Button
                  onClick={async () => {
                    closeModal();
                  }}
                  variant="contained"
                >
                  Done
                </Button>
              )}
            </Stack>
          </DialogActions>
        </Modal>
        <NextGenDebugModal
          errorContext={errorContext}
          onClose={() => setIsDebugModalOpen(false)}
          open={isDebugModalOpen}
        />
        <ConfirmationDialog
          onClose={() => {
            setShowRemoveAuthDialog(false);
          }}
          onConfirm={async () => {
            await handleIncorrectAutoAssociation(
              authorization,
              data.disassociationReason
            );
          }}
          open={showRemoveAuthDialog}
          data-cy="controlRemoveFileFromAuth"
        >
          <Typography sx={{ my: 2 }} variant="h6" color="primary.main">
            Wrong File?
          </Typography>
          <Stack spacing={2}>
            <Typography variant="body1">
              {`Send file back to ${brandName} to attach to the correct authorization.
              This file will also be removed from this authorization.`}
            </Typography>
            <FormLabel required>Reason to remove file</FormLabel>
            <RadioGroupField name={DISASSOCIATION_REASON}>
              {DISASSOCIATION_REASONS.map((reason) => (
                <FormControlLabel
                  key={reason}
                  control={<Radio />}
                  label={_.startCase(reason)}
                  value={reason}
                />
              ))}
            </RadioGroupField>
            <FormHelperText>
              This will help improve our system. Thank you!
            </FormHelperText>
          </Stack>
        </ConfirmationDialog>
        <ConfirmationDialog
          onClose={() => {
            setShowUnsavedChangesDialog(false);
          }}
          onConfirm={closeModal}
          open={showUnsavedChangesDialog}
          confirmButtonText="Yes"
        >
          <Typography sx={{ my: 2 }} variant="h6" color="primary.main">
            Unsaved changes
          </Typography>
          <div>You have unsaved changes, are you sure you want to close?</div>
        </ConfirmationDialog>
      </FormProvider>
    </div>
  );
};

export default AssociateCorrespondenceModal;
