import { Stack } from "@samacare/design/core";
import _ from "lodash";

import InsuranceCompanyField from "../InsuranceCompanyField";
import { SubscriberRelationshipCode } from "@samacare/graphql";
import { useFormContext, AutocompleteField, TextField } from "@samacare/form";

const SubscriberRelationshipCodeLabels: Record<
  SubscriberRelationshipCode,
  string
> = {
  [SubscriberRelationshipCode.Self]: "Self",
  [SubscriberRelationshipCode.Spouse]: "Spouse",
  [SubscriberRelationshipCode.Child]: "Child",
  [SubscriberRelationshipCode.Other]: "Other relationship",
};

const SubscriberRelationshipCodeOrder: SubscriberRelationshipCode[] = [
  SubscriberRelationshipCode.Self,
  SubscriberRelationshipCode.Spouse,
  SubscriberRelationshipCode.Child,
  SubscriberRelationshipCode.Other,
];

export type EdiInsuranceBlockProps = {
  required?: boolean;
  disabled?: boolean;
};

const defaultProps: EdiInsuranceBlockProps = {
  required: false,
  disabled: false,
};

export const EdiInsuranceBlock: React.FC<EdiInsuranceBlockProps> = (
  rawProps
) => {
  const { required, disabled } = _.merge(
    _.cloneDeep(defaultProps),
    rawProps
  ) as Required<EdiInsuranceBlockProps>;

  const rtsTag = "patient.primaryInsurance.relationshipToSubscriber";

  const { watch } = useFormContext();
  return (
    <Stack spacing={2} maxWidth={600}>
      <InsuranceCompanyField
        size="small"
        name="patient.primaryInsurance.InsuranceCompanyId"
        label="Payer"
        fullWidth
        required={required}
        disabled={disabled}
      />
      <TextField
        size="small"
        name="patient.primaryInsurance.memberId"
        label="Member ID"
        fullWidth
        required={required}
        disabled={disabled}
      />
      <AutocompleteField<string, false, true, false>
        disableClearable
        getOptionLabel={(val) =>
          SubscriberRelationshipCodeLabels[val as SubscriberRelationshipCode] ??
          ""
        }
        options={SubscriberRelationshipCodeOrder}
        label="Relationship to subscriber"
        name={rtsTag}
        required={required}
        fullWidth
        disabled={disabled}
        value={watch(rtsTag) ?? ""}
      />
    </Stack>
  );
};
