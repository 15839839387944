import { Stack } from "@samacare/design/core";
import _ from "lodash";

import StateField, { StateFieldProps } from "../StateField";
import { TextField, TextFieldProps } from "@samacare/form";

import InsuranceTypeField, {
  InsuranceTypeFieldProps,
} from "../InsuranceTypeField";

import InsuranceCompanyField, {
  InsuranceCompanyFieldProps,
} from "../InsuranceCompanyField";
import PhoneField, { PhoneFieldProps } from "../PhoneField";

export type InsuranceBlockProps = {
  required?: boolean;
  disabled?: boolean;
  usePhone?: boolean;
  useGroupNumber?: boolean;
  InsuranceTypeInputProps?: InsuranceTypeFieldProps;
  InsuranceMemberIdInputProps?: TextFieldProps;
  InsuranceStateInputProps?: StateFieldProps;
  InsuranceCompanyInputProps?: InsuranceCompanyFieldProps;
  PhoneInputProps?: PhoneFieldProps;
  InsuranceGroupNumberInputProps?: TextFieldProps;
};

const defaultProps: InsuranceBlockProps = {
  required: false,
  disabled: false,
  usePhone: false,
  useGroupNumber: false,
  InsuranceTypeInputProps: {
    name: "patient.primaryInsurance.planType",
    label: "Insurance Type",
  },
  InsuranceMemberIdInputProps: {
    name: "patient.primaryInsurance.memberId",
    label: "Member ID",
  },
  InsuranceStateInputProps: {
    name: "patient.primaryInsurance.insuranceState",
    label: "Insurance Issuing State",
  },
  InsuranceCompanyInputProps: {
    name: "patient.primaryInsurance.InsuranceCompanyId",
    label: "Insurance Organization",
  },
  PhoneInputProps: {
    name: "patient.insuranceCompanyPhone",
    label: "Insurance Organization Phone",
  },
  InsuranceGroupNumberInputProps: {
    name: "patient.primaryInsurance.groupNumber",
    label: "Group Number",
  },
};

export const InsuranceBlock: React.FC<InsuranceBlockProps> = (rawProps) => {
  const {
    required,
    disabled,
    usePhone,
    InsuranceTypeInputProps,
    InsuranceMemberIdInputProps,
    InsuranceStateInputProps,
    InsuranceCompanyInputProps,
    PhoneInputProps,
    useGroupNumber,
    InsuranceGroupNumberInputProps,
  } = _.merge(
    _.cloneDeep(defaultProps),
    rawProps
  ) as Required<InsuranceBlockProps>;
  return (
    <Stack spacing={2} maxWidth={600}>
      <InsuranceTypeField
        size="small"
        {...InsuranceTypeInputProps}
        fullWidth
        required={required}
        disabled={disabled}
      />
      <TextField
        size="small"
        {...InsuranceMemberIdInputProps}
        fullWidth
        required={required}
        disabled={disabled}
      />
      <StateField
        size="small"
        {...InsuranceStateInputProps}
        fullWidth
        required={required}
        disabled={disabled}
      />
      <InsuranceCompanyField
        size="small"
        {...InsuranceCompanyInputProps}
        fullWidth
        required={required}
        disabled={disabled}
      />
      {useGroupNumber && (
        <TextField
          size="small"
          {...InsuranceGroupNumberInputProps}
          fullWidth
          required={required}
          disabled={disabled}
        />
      )}
      {usePhone && (
        <PhoneField
          size="small"
          required={required}
          disabled={disabled}
          {...PhoneInputProps}
          fullWidth
        />
      )}
    </Stack>
  );
};
