import styled from "styled-components";

export const IntegrationFileAttachButtonContainer = styled.div`
  border: 1px dashed ${(props) => props.theme.purple};
  padding: 10px;
  margin: 10px 0;
  width: 400px;
  color: ${(props) => props.theme.purple};
  text-align: center;
`;

export const IntegrationFileAttachButtonContainerSimplified = styled.div`
  border-radius: 4px;
  border: 1px dashed rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  padding: 24px 16px;
`;
