import { PureComponent } from "react";
import Button from "@samacare/design/core/Button";
import LoadingButton from "@samacare/design/core/LoadingButton";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;
  gap: 10px;
`;

const BackButton = styled(Button)`
  color: #6363f2;
  text-decoration: underline;
`;

const NextButton = styled(LoadingButton)`
  color: white;
  background-color: #6363f2;
`;

export class SimplifiedFormSubmitButtons extends PureComponent {
  render() {
    const {
      back,
      backText,
      submit,
      submitText,
      loading = false,
      disabled,
      submitDisabled,
    } = this.props;
    return (
      <Container>
        {back ? (
          <BackButton
            variant="text"
            data-cy="actionBack"
            disabled={disabled}
            onClick={back}
          >
            {backText ?? "Back"}
          </BackButton>
        ) : (
          <div />
        )}
        {submit && (
          <NextButton
            data-cy="actionSubmit"
            variant="contained"
            disabled={disabled || submitDisabled}
            loading={loading}
            onClick={submit}
          >
            {submitText || "Next"}
          </NextButton>
        )}
      </Container>
    );
  }
}

export default SimplifiedFormSubmitButtons;
