import { Stack } from "@samacare/design/core";
import { TextField, TextFieldProps } from "@samacare/form";

export type MedicalRequestBlockProps = {
  required?: boolean;
  disabled?: boolean;
  NameInputProps?: TextFieldProps;
  CodeInputProps?: TextFieldProps;
};

const MedicalRequestBlock: React.FC<MedicalRequestBlockProps> = ({
  required = false,
  disabled = false,
  NameInputProps = {
    name: "medications[0].name",
    label: "Service name",
  },
  CodeInputProps = {
    name: "medications[0].code",
    label: "HCPCS code",
  },
}) => {
  return (
    <Stack spacing={2} maxWidth={300}>
      <TextField {...NameInputProps} required={required} disabled={disabled} />
      <TextField {...CodeInputProps} required={required} disabled={disabled} />
    </Stack>
  );
};

export default MedicalRequestBlock;
