import { yupResolver } from "@hookform/resolvers/yup";
import _ from "lodash";
import { boolean, object, string } from "yup";
import { FormProvider, useForm, TextField, Resolver } from "@samacare/form";
import {
  Button,
  Stack,
  DialogContent,
  Select,
  SelectChangeEvent,
} from "@samacare/design/core";
import Dialog from "@samacare/design/core/Dialog";
import DialogActions from "@samacare/design/core/DialogActions";
import DialogTitle from "@samacare/design/core/DialogTitle";
import { useAlert } from "react-alert";
import { UpsertPatientIntegrationCredentialMutationPatch } from "@samacare/graphql";
import {
  PatientIntegrationTitlesFindAllQuery,
  PatientIntegrationTitlesFindAllQueryVariables,
  UpsertPatientIntegrationCredentialMutation,
  UpsertPatientIntegrationCredentialMutationVariables,
} from "@@generated/graphql";
import { useEffect, useState } from "react";
import { PatientSyncersGridRow } from "./PatientSyncersGrid";
import { FormControl, InputLabel, MenuItem } from "@samacare/design";
import {
  PATIENT_INTEGRATION_FIND_ALL_QUERY_NAME,
  patientIntegrationTitlesQuery,
  upsertPatientIntegrationCredentialMutation,
} from "../../../graphql/PatientIntegrationCredential";
import { useMutation, useQuery } from "@apollo/client";
import { useAllInstitutions } from "@@hooks/useAllInstitutions";

const SELECT_INTEGRATION = "Select Integration";
const SELECT_INSTITUTION = "Select Institution";

const credentialSchema =
  object<UpsertPatientIntegrationCredentialMutationPatch>({
    username: string(),
    password: string(),
    loginURL: string(),
    officeKey: string(),
    isDisabled: boolean(),
  });

interface EditSyncerDialogProps {
  syncer: PatientSyncersGridRow | null;
  onClose: () => void;
}
export const EditSyncerDialog: React.FC<EditSyncerDialogProps> = ({
  syncer,
  onClose,
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const alert = useAlert();
  const methods = useForm<UpsertPatientIntegrationCredentialMutationPatch>({
    resolver: yupResolver(credentialSchema) as Resolver<
      UpsertPatientIntegrationCredentialMutationPatch,
      any
    >,
  });
  const [selectedInstitutionId, setSelectedInstitutionId] = useState("");
  const [selectedIntegrationTitle, setSelectedIntegrationTitle] = useState("");

  const { data: integrationTitlesData } = useQuery<
    PatientIntegrationTitlesFindAllQuery,
    PatientIntegrationTitlesFindAllQueryVariables
  >(patientIntegrationTitlesQuery);

  const [upsertPatientIntegrationCredential] = useMutation<
    UpsertPatientIntegrationCredentialMutation,
    UpsertPatientIntegrationCredentialMutationVariables
  >(upsertPatientIntegrationCredentialMutation, {
    refetchQueries: [PATIENT_INTEGRATION_FIND_ALL_QUERY_NAME],
  });

  const institutions = useAllInstitutions();

  // ENG-220: Remove sftp manually for now.  To be fully cleaned up in ENG-267
  const adjustedTitles = integrationTitlesData?.patientIntegrationTitlesFindAll
    ?.map((title) => (title !== "sftp" ? title : null))
    .filter((title) => title !== null) as string[];

  const onSave = async (
    credential: UpsertPatientIntegrationCredentialMutationPatch
  ) => {
    try {
      await upsertPatientIntegrationCredential({
        variables: {
          id: syncer?.id ? parseInt(syncer?.id) : undefined,
          institutionId: parseInt(selectedInstitutionId),
          patch: {
            ...credential,
            title: selectedIntegrationTitle,
          },
        },
      });
      alert.success("Success");
      onClose();
    } catch (err: unknown) {
      const e = err as Error;
      alert.error(`There was an error creating this syncer, ${e.message}`);
    }
  };

  const mapSyncerToCredentialPatch = (
    syncerData: PatientSyncersGridRow | null
  ): UpsertPatientIntegrationCredentialMutationPatch | null => {
    if (syncerData) {
      return {
        title: syncerData.integrationTitle,
        username: syncerData.username,
        password: syncerData.password,
        loginURL: syncerData.loginUrl,
        officeKey: syncerData.officeKey,
        isDisabled: syncerData.isDisabled,
      } as UpsertPatientIntegrationCredentialMutationPatch;
    }
    return null;
  };

  useEffect(() => {
    methods.reset(mapSyncerToCredentialPatch(syncer) ?? {});
    if (syncer) {
      setIsEdit(!!syncer.id);
      setSelectedInstitutionId(syncer.institutionId);
      setSelectedIntegrationTitle(syncer.integrationTitle);
    }
  }, [syncer, methods, setIsEdit]);

  const title = isEdit ? "Edit Syncer" : "Create Syncer";

  return (
    <Dialog open={!!syncer} onClose={onClose} scroll="paper" maxWidth="lg">
      <DialogTitle>{title}</DialogTitle>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSave)}>
          <DialogContent sx={{ width: 600 }}>
            <Stack direction="column" spacing={2}>
              <FormControl
                id={SELECT_INSTITUTION}
                fullWidth
                disabled={syncer?.institutionId != null}
                required
              >
                <InputLabel>{SELECT_INSTITUTION}</InputLabel>
                <Select
                  label={SELECT_INSTITUTION}
                  onChange={(e: SelectChangeEvent<unknown>) =>
                    setSelectedInstitutionId(e.target.value as string)
                  }
                  value={selectedInstitutionId}
                >
                  {institutions?.map(({ id, name }) => (
                    <MenuItem key={name} value={id}>
                      {_.startCase(name)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl
                fullWidth
                disabled={syncer?.integrationTitle != null}
                required
              >
                <InputLabel>{SELECT_INTEGRATION}</InputLabel>
                <Select
                  id={SELECT_INTEGRATION}
                  label={SELECT_INTEGRATION}
                  onChange={(e: SelectChangeEvent<unknown>) =>
                    setSelectedIntegrationTitle(e.target.value as string)
                  }
                  value={selectedIntegrationTitle}
                >
                  {adjustedTitles?.map((field) => (
                    <MenuItem key={field} value={field}>
                      {_.startCase(field)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Stack direction="row" spacing={2}>
                <TextField fullWidth label="Username" name="username" />
                <TextField fullWidth label="Password" name="password" />
              </Stack>
              <Stack direction="row" spacing={2}>
                <TextField fullWidth label="Login URL" name="loginURL" />
                <TextField fullWidth label="Office Key" name="officeKey" />
              </Stack>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button
              data-cy="actionCloseEditPrescriberDialog"
              variant="outlined"
              onClick={() => {
                methods.reset();
                onClose();
              }}
            >
              Close
            </Button>
            <Button
              data-cy="actionSavePrescriber"
              type="submit"
              variant="contained"
              color="primary"
            >
              {isEdit ? "Save" : "Create"}
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};
