import _ from "lodash";

import { useMutation } from "@apollo/client";
import { useCallback, useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom";

import {
  Box,
  Breadcrumbs,
  CircularProgress,
  Dialog,
  DialogContent,
  Link,
  Stack,
  Typography,
} from "@samacare/design";
import {
  EnrollmentStatus,
  SubmitCareMetxBenefitsVerificationStatus,
} from "@samacare/graphql";

import {
  SubmitCareMetxBenefitsVerificationMutation,
  UpdateCareMetxEnrollmentStatusMutation,
} from "@@generated/graphql";
import ROUTE_PATHS from "../../ROUTE_PATHS";
import SubmitCareMetxBenefitsVerificationGql from "../graphql/SubmitCareMetxBenefitsVerification.gql";
import UpdateCareMetxEnrollmentStatusGql from "../graphql/UpdateCareMetxEnrollmentStatus.gql";
import {
  SearchUnifiedBenefitsCheckByIdResultItemDrug,
  SearchUnifiedBenefitsCheckByIdResultItemFullData,
} from "../UnifiedBenefitsCheckProvider";
import { PatientDetails } from "./PatientDetails";
import { RightSideMenu } from "./RightSideMenu";

interface CareMetxFormEventData {
  eventName:
    | "Cancel"
    | "Submit-Initiated"
    | "Submit-Successful"
    | "Submit-Failed";
  drugName: string;
  patientExternalID: string;
  praticeExternalID: string;
  service: string;
  status: string;
  transactionId: string;
  transactionType: string;
}

export const CareMetxPavbluRequest: React.FC<{
  drug: SearchUnifiedBenefitsCheckByIdResultItemDrug;
  caremetxBv: SearchUnifiedBenefitsCheckByIdResultItemFullData;
}> = ({ drug, caremetxBv }) => {
  const { id } = caremetxBv;

  const alert = useAlert();
  const history = useHistory();

  const [enrollmentId, setEnrollmentId] = useState<number | null>(null);
  const [html, setHtml] = useState<string | null>(null);

  const [isSubmittingCareMetxEnrollment, setIsSubmittingCareMetxEnrollment] =
    useState(false);

  const [submitCareMetxBenefitsVerification, { loading: isSubmitting }] =
    useMutation<SubmitCareMetxBenefitsVerificationMutation>(
      SubmitCareMetxBenefitsVerificationGql,
      { refetchQueries: ["SearchUnifiedBenefitsCheckById"] }
    );

  const [updateEnrollmentStatus, { loading: isUpdatingEnrollment }] =
    useMutation<UpdateCareMetxEnrollmentStatusMutation>(
      UpdateCareMetxEnrollmentStatusGql
    );

  // handles the caremetx enrollment submission success:
  // - updates the enrollment status to `Submitted`
  // - triggers another benefits verification submission to get the medebv data from caremetx
  // - finally, redirects to the response page
  const handleEnrollmentSubmissionSuccess = useCallback(async () => {
    setIsSubmittingCareMetxEnrollment(false);
    await updateEnrollmentStatus({
      variables: {
        id: enrollmentId,
        status: EnrollmentStatus.Submitted,
      },
    });

    // resubmit the benefits verification which triggers another caremetx workflow invoke call
    // this 2nd call will return a medebv data, which we will save in the DB
    await submitCareMetxBenefitsVerification({ variables: { id } });
    history.push(`${ROUTE_PATHS.BENEFITS_VERIFICATIONS.path}/view/${id}`);
  }, [
    history,
    id,
    enrollmentId,
    submitCareMetxBenefitsVerification,
    updateEnrollmentStatus,
  ]);

  // handles the caremetx enrollment submission error:
  // - updates the enrollment status to `SubmitError`
  // - shows an alert to the user, and log the error
  const handleEnrollmentSubmissionError = useCallback(async () => {
    setIsSubmittingCareMetxEnrollment(false);
    await updateEnrollmentStatus({
      variables: {
        id: enrollmentId,
        status: EnrollmentStatus.SubmitError,
      },
    });
    alert.error("Unable to submit CareMetx enrollment. Please try again");
    // eslint-disable-next-line no-console
    console.error(
      `CareMetx enrollment submission failed id: ${id} enrollment id: ${enrollmentId}`
    );
  }, [alert, id, enrollmentId, updateEnrollmentStatus]);

  // handle caremetx enrollment form events
  // caremetx form events are fired through the window.postMessage API
  const handleEnrollmentFormEvent = useCallback(
    async (e: MessageEvent<CareMetxFormEventData>) => {
      const { data } = e;
      if (data == null) {
        return;
      }

      const { eventName, transactionId, transactionType, drugName } = e.data;
      if (
        eventName == null ||
        transactionId == null ||
        drugName !== "Pavblu" ||
        transactionType !== "Enrollment"
      ) {
        // ignore any non caremetx pavblu enrollment events
        return;
      }

      switch (eventName) {
        case "Submit-Initiated":
          setIsSubmittingCareMetxEnrollment(true);
          break;
        case "Submit-Successful":
          await handleEnrollmentSubmissionSuccess();
          break;
        case "Submit-Failed":
          await handleEnrollmentSubmissionError();
          break;
        case "Cancel":
          history.push(`${ROUTE_PATHS.BENEFITS_VERIFICATIONS.path}`);
          break;
        default:
          break;
      }
    },
    [
      history,
      handleEnrollmentSubmissionError,
      handleEnrollmentSubmissionSuccess,
    ]
  );

  // subscribe to caremetx enrollment form events
  useEffect(() => {
    window.addEventListener("message", handleEnrollmentFormEvent);
    return () => {
      window.removeEventListener("message", handleEnrollmentFormEvent);
    };
  }, [handleEnrollmentFormEvent]);

  const initCareMetxBv = useCallback(async () => {
    const { data } = await submitCareMetxBenefitsVerification({
      variables: { id },
    });
    const status = data?.submitCareMetxBenefitsVerification?.status;

    // if the submitCareMetxBenefitsVerification returns `success` status
    // it means we have the caremetx medebv data, so we can redirect to the response page
    if (status === SubmitCareMetxBenefitsVerificationStatus.Success) {
      history.push(`${ROUTE_PATHS.BENEFITS_VERIFICATIONS.path}/view/${id}`);
      return;
    }

    // otherwise, we will show the caremetx enrollment form to the user
    if (
      status === SubmitCareMetxBenefitsVerificationStatus.RequiresEnrollment
    ) {
      setHtml(data?.submitCareMetxBenefitsVerification?.html ?? null);
      setEnrollmentId(
        data?.submitCareMetxBenefitsVerification?.enrollmentId ?? null
      );
      return;
    }
    throw new Error(
      `Unexpected submitCareMetxBenefitsVerification status: ${status}`
    );
  }, [history, id, submitCareMetxBenefitsVerification]);

  // initialize caremetx bv
  useEffect(() => {
    initCareMetxBv().catch((e) => {
      throw e;
    });
  }, [initCareMetxBv]);

  const drugLabel = _.compact([drug.code, drug.drugName]).join(" - ");
  const isLoading =
    isSubmitting || isUpdatingEnrollment || isSubmittingCareMetxEnrollment;
  return (
    <Stack
      padding={2}
      sx={{ display: "flex", height: "100vh" }}
      data-cy="componentCareMetxBenefitsVerificationSubmitPage"
    >
      <Breadcrumbs>
        <Link underline="hover" href="#/benefits-verification">
          <Typography variant="h6">Benefits Verification</Typography>
        </Link>
        <Typography variant="h6">#{id.slice(-5)}</Typography>
      </Breadcrumbs>
      <Box marginTop={({ spacing }) => spacing(4)}>
        <PatientDetails />
      </Box>
      <Typography marginTop={3} variant="h6">
        {drugLabel}
      </Typography>
      <Stack direction="row" marginTop={3} flexGrow={1}>
        <Box sx={{ flexGrow: 1 }}>
          {html != null && (
            <iframe
              title="CareMetx Enrollment Module"
              style={{ width: "100%", height: "100%" }}
              srcDoc={html}
            />
          )}
        </Box>
        <Box minWidth="230px" marginLeft={3}>
          <RightSideMenu activeStep={1} />
        </Box>
      </Stack>
      <Dialog open={isLoading}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    </Stack>
  );
};
