import _ from "lodash";
import { useCallback, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { ICD10SearchQuery } from "../../app/graphql/ICD10";
import {
  useFormContext,
  AutocompleteField,
  type AutocompleteFieldProps,
} from "@samacare/form";
import { useAlert } from "react-alert";

type formattedIcdData = {
  code: string;
  description: string;
};

export type IcdFieldProps = {
  required?: boolean;
  disabled?: boolean;
  displayLimitInLabel?: boolean;
  override?: {
    options?: string[];
    limit?: number;
  };
};
export type IcdBlockProps = Omit<
  AutocompleteFieldProps<formattedIcdData, true, true, false>,
  "options" | "TextFieldInputProps"
> &
  IcdFieldProps;

type IcdData = { icd10: string[][] };

export const IcdField: React.FC<IcdBlockProps> = ({
  name,
  override,
  label,
  displayLimitInLabel,
  ...props
}) => {
  const alert = useAlert();
  const { data, loading, refetch } = useQuery(ICD10SearchQuery, {
    variables: {
      searchTerm: "",
      query: override?.options,
    },
  });

  const { watch, setValue } = useFormContext();
  const selectedCodes = watch(name);

  useEffect(() => {
    if (selectedCodes.length > 0 && typeof selectedCodes[0] == "string") {
      const iCDPromiseArray = [];
      for (let i = 0; i < selectedCodes.length; i++) {
        const promise = refetch({
          searchTerm: selectedCodes[i],
        });
        iCDPromiseArray.push(promise);
      }

      Promise.all(iCDPromiseArray)
        .then((result) => {
          const reformatedCodes = result.map((icd) => {
            return {
              code: icd.data.icd10[0][0],
              description: `${icd.data.icd10[0][0]}: ${icd.data.icd10[0][1]}`,
            };
          });
          setValue(name, reformatedCodes);
        })
        .catch((err) => {
          alert.error(err instanceof Error ? err.message : "Unknown error");
        });
    }
  }, [selectedCodes, refetch, setValue]);

  const icdData = data as unknown as IcdData;
  const search = useCallback(
    (searchTerm: string) => {
      if (searchTerm != null && searchTerm !== "") {
        void refetch({ searchTerm, query: override?.options });
      }
    },
    [refetch]
  );

  const throttledSearch = useCallback(
    _.debounce(search, 500, {
      leading: false,
      trailing: true,
    }),
    [search]
  );

  return (
    <AutocompleteField<formattedIcdData, true, true, false>
      multiple
      sx={{ width: 600 }}
      placeholder="Type to search ICD codes..."
      label={
        label ??
        `ICD-10 codes${
          displayLimitInLabel && override?.limit
            ? ` (Max ${override.limit})`
            : ""
        }`
      }
      name={name}
      loading={loading}
      value={selectedCodes}
      options={(icdData?.icd10 ?? []).map((icd) => {
        return {
          code: icd[0],
          description: icd.join(":"),
        };
      })}
      filterSelectedOptions
      getOptionLabel={(option) => option.description}
      onInputChange={(e, value) => {
        throttledSearch(value);
      }}
      getOptionDisabled={() => {
        if (override?.limit) {
          return (selectedCodes ?? []).length >= override.limit;
        }
        return false;
      }}
      TextFieldInputProps={{ sx: { minHeight: 50 } }}
      {...props}
    />
  );
};
