import { Alert } from "@samacare/design/core";
import { renderHtml } from "../AuthorizationSharedSteps/LomnSection/LomnShared";
import _ from "lodash";
import { useFormConfig } from "../FormConfigContext/FormConfigContext";

export const SegmentNote: React.VFC<{ field: string }> = ({ field }) => {
  const noteKey = `${_.camelCase(`field_${field}`)}_note`;
  const noteValue = useFormConfig(noteKey) as string | undefined;
  return noteValue ? (
    <Alert sx={{ my: "4px", width: "600px" }} severity="info">
      {renderHtml(noteValue)}
    </Alert>
  ) : null;
};
