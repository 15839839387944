import * as React from "react";
import styled from "styled-components";
import { BaseText } from "@@components/Segment/StyledComponents";
import { Button } from "@samacare/design/core";
import { Account } from "@samacare/graphql";
import { LeftRightCenterV } from "@samacare/component";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  gap: 8px;
`;

const PatientSearchText = styled.div`
  min-width: 52px;
`;

const IconButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export type PatientSearchFieldsProps = React.ComponentProps<
  typeof PatientSearchFields
>;

export const PatientSearchFields: React.VoidFunctionComponent<{
  currentAccount: Account;
  firstName: string;
  lastName: string;
  AuthorizationId: string;
  MRN: string;
  setFilterState: (
    type:
      | "firstName"
      | "lastName"
      | "AuthorizationId"
      | "MRN"
      | "jCode"
      | "drugName",
    value: string
  ) => void;
  clearName: () => void;
  jCode: string;
  drugName: string;
}> = ({
  firstName,
  lastName,
  MRN,
  setFilterState,
  clearName,
  AuthorizationId,
  jCode,
  drugName,
}) => (
  <Wrapper>
    {firstName || lastName || AuthorizationId || MRN || jCode || drugName ? (
      <IconButtonWrapper>
        <Button variant="outlined" onClick={clearName}>
          Clear
        </Button>
      </IconButtonWrapper>
    ) : (
      <PatientSearchText>Search</PatientSearchText>
    )}
    <LeftRightCenterV sx={{ gap: 1, flexWrap: "wrap" }}>
      <BaseText
        style={{
          width: "150px",
          margin: "0",
          borderRadius: "4px",
          height: "38px",
          borderColor: "hsl(0, 0%, 80%)",
        }}
        value={firstName}
        onChange={(e) => setFilterState("firstName", e.target.value)}
        data-cy="fieldSearchFirstName"
        placeholder="First Name"
      />
      <BaseText
        style={{
          width: "150px",
          borderRadius: "4px",
          height: "38px",
          borderColor: "hsl(0, 0%, 80%)",
        }}
        value={lastName}
        onChange={(e) => setFilterState("lastName", e.target.value)}
        data-cy="fieldSearchLastName"
        placeholder="Last Name"
      />
      <BaseText
        style={{
          width: "130px",
          borderRadius: "4px",
          height: "38px",
          borderColor: "hsl(0, 0%, 80%)",
        }}
        value={MRN}
        onChange={(e) => setFilterState("MRN", e.target.value)}
        data-cy="fieldSearchMRN"
        placeholder="Patient MRN#"
      />
      <BaseText
        style={{
          width: "90px",
          borderRadius: "4px",
          height: "38px",
          borderColor: "hsl(0, 0%, 80%)",
        }}
        value={AuthorizationId}
        onChange={(e) => setFilterState("AuthorizationId", e.target.value)}
        data-cy="fieldSearchID"
        placeholder="Auth ID"
      />
      <BaseText
        style={{
          width: "130px",
          borderRadius: "4px",
          height: "38px",
          borderColor: "hsl(0, 0%, 80%)",
        }}
        value={jCode}
        onChange={(e) => setFilterState("jCode", e.target.value)}
        data-cy="fieldSearchJcode"
        placeholder="Jcode"
      />
      <BaseText
        style={{
          width: "130px",
          borderRadius: "4px",
          height: "38px",
          borderColor: "hsl(0, 0%, 80%)",
        }}
        value={drugName}
        onChange={(e) => setFilterState("drugName", e.target.value)}
        data-cy="fieldSearchDrugName"
        placeholder="DrugName"
      />
    </LeftRightCenterV>
  </Wrapper>
);

/**
 * @deprecated Reorganize me?
 */
export default PatientSearchFields;
