import { useMutation } from "@apollo/client";
import DeletePortal from "../graphql/DeletePortalMutation.gql";

export const useDeletePortal = () => {
  const [deletePortal] = useMutation(DeletePortal, {
    refetchQueries: ["GetPortals"],
  });

  return async (portalId: number) => {
    await deletePortal({
      variables: {
        id: portalId,
      },
    });
  };
};
