import Papa from "papaparse";
import axios from "axios";
import { useAlert } from "react-alert";
import {
  Button,
  CircularProgress,
  Dialog,
  Grid,
  IconButton,
  Typography,
} from "@samacare/design";
import ChevronRight from "@samacare/design/core/icons/ChevronRight";
import ChevronLeft from "@samacare/design/core/icons/ChevronLeft";
import { useAwsKeysForSkippedRows } from "../../../hooks/useAwsKeysForSkippedRows";
import { Fragment, useEffect, useRef, useState } from "react";
import { useLazySignedUrl } from "../../../hooks/useLazySignedUrl";
import { useConfig } from "../../../hooks";
import {
  TopBottomCenterH,
  TopBottomSpreadCenterH,
} from "../../../components/TopBottomV2";
import {
  LeftRightCenterAll,
  LeftRightCenterV,
  LeftRightSpreadCenterV,
} from "@samacare/component";
import { IngestJob } from "@samacare/graphql";
import { PatientColumn, patientColumnDetails } from "./PatientColumns";
import { ErrorChip } from "./ErrorChip";
import { extractErrors, getFailureCount } from "./errorUtils";

interface SkippedDialogProps {
  ingestJob: IngestJob;
  closeDialog: () => void;
}
export const SkippedDialog: React.FC<SkippedDialogProps> = ({
  ingestJob,
  closeDialog,
}) => {
  const config = useConfig();
  const alert = useAlert();
  const pipelineBucket = config.INGEST_PIPELINE_BUCKET;

  const isStartAtEnd = useRef(false);

  // The file index (errors are broken out by chunk)
  const [fileIndex, setFileIndex] = useState<number>(0);
  // The index across all files
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  // The index within the current file
  const [rowIndex, setRowIndex] = useState<number>(0);
  // The rows within the current file
  const [rows, setRows] = useState<Record<string, string>[]>([]);

  const { awsKeys } = useAwsKeysForSkippedRows(+ingestJob.id);
  const { getSignedUrl, signedUrl } = useLazySignedUrl();

  const skippedCount = getFailureCount(ingestJob);

  useEffect(() => {
    if (awsKeys && awsKeys.length > 0 && getSignedUrl) {
      const key = awsKeys[fileIndex];
      getSignedUrl(key, pipelineBucket);
    }
  }, [awsKeys, getSignedUrl, fileIndex, pipelineBucket]);

  useEffect(() => {
    if (signedUrl) {
      const fetchData = async () => {
        try {
          const response = await axios({
            method: "GET",
            url: signedUrl,
            responseType: "blob",
          });

          const file = new Blob([response.data], { type: "text/csv" }) as File;

          Papa.parse(file, {
            complete: (result) => {
              setRows(result.data as Record<string, string>[]);
              setRowIndex(isStartAtEnd.current ? result.data.length - 1 : 0);
            },
            header: true,
          });
        } catch (error) {
          alert.error("Error fetching skipped details");
        }
      };

      void fetchData();
    }
  }, [signedUrl, alert]);

  const handleNext = () => {
    if (currentIndex < skippedCount - 1 && awsKeys) {
      setCurrentIndex(currentIndex + 1);
      if (rowIndex === rows.length - 1) {
        setRows([]);
        isStartAtEnd.current = false;
        if (fileIndex < awsKeys.length - 1) {
          setFileIndex(fileIndex + 1);
        } else {
          alert.error("Error fetching skipped details");
        }
      } else {
        setRowIndex(rowIndex + 1);
      }
    }
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
      if (rowIndex === 0) {
        setRows([]);
        isStartAtEnd.current = true;
        if (fileIndex > 0) {
          setFileIndex(fileIndex - 1);
        } else {
          alert.error("Error fetching skipped details");
        }
      } else {
        setRowIndex(rowIndex - 1);
      }
    }
  };

  const row = rows.length > 0 ? rows[rowIndex] : null;
  const isLoading = !row;

  const { errorByColumn, tooltipTextByColumn, restOfRow, isGenericError } =
    extractErrors(row);

  return (
    <Dialog open onClose={closeDialog}>
      <TopBottomCenterH sx={{ height: "600px", width: "600px" }}>
        <TopBottomSpreadCenterH sx={{ width: "100%", flexGrow: 1 }}>
          <TopBottomCenterH sx={{ width: "100%", flexGrow: 1 }}>
            <LeftRightSpreadCenterV
              sx={{
                width: ({ spacing }) => `calc(100% - ${spacing(4)})`,
                height: ({ spacing }) => spacing(8),
                marginLeft: ({ spacing }) => spacing(2),
                marginRight: ({ spacing }) => spacing(2),
              }}
            >
              <Typography variant="h4">Skipped Rows</Typography>
              <LeftRightCenterV>
                <Typography
                  variant="subtitle1"
                  sx={{ marginRight: ({ spacing }) => spacing(1) }}
                >
                  {`${currentIndex + 1} of ${skippedCount}`}
                </Typography>
                <IconButton
                  disabled={currentIndex === 0 || isLoading}
                  onClick={handlePrev}
                >
                  <ChevronLeft />
                </IconButton>
                <IconButton
                  disabled={currentIndex === skippedCount - 1 || isLoading}
                  onClick={handleNext}
                >
                  <ChevronRight />
                </IconButton>
              </LeftRightCenterV>
            </LeftRightSpreadCenterV>

            {isGenericError && (
              <LeftRightCenterAll
                sx={{ width: "100%", paddingTop: ({ spacing }) => spacing(1) }}
              >
                <ErrorChip label="Unknown Error" />
              </LeftRightCenterAll>
            )}

            {isLoading ? (
              <LeftRightCenterAll sx={{ width: "100%", flexGrow: 1 }}>
                <CircularProgress />
              </LeftRightCenterAll>
            ) : (
              <Grid
                container
                columns={16}
                spacing={2}
                margin={2}
                width="100%"
                sx={{ overflowY: "scroll" }}
              >
                {[...Object.keys(restOfRow)].map((patientColumnKey: string) => (
                  <Fragment key={patientColumnKey}>
                    <Grid
                      item
                      xs={6}
                      display="flex"
                      justifyContent="flex-end"
                      fontWeight="bold"
                    >
                      {patientColumnDetails[patientColumnKey as PatientColumn]
                        ?.label ?? patientColumnKey}
                    </Grid>

                    <Grid item xs={6}>
                      {row[patientColumnKey]}
                    </Grid>

                    <Grid item xs={4} color="error">
                      {errorByColumn[patientColumnKey] && (
                        <ErrorChip
                          tooltipText={tooltipTextByColumn[patientColumnKey]}
                          label={errorByColumn[patientColumnKey]}
                        />
                      )}
                    </Grid>
                  </Fragment>
                ))}
              </Grid>
            )}
          </TopBottomCenterH>

          <Button
            size="small"
            onClick={closeDialog}
            sx={{
              marginTop: ({ spacing }) => spacing(2),
              marginBottom: ({ spacing }) => spacing(2),
            }}
          >
            Close
          </Button>
        </TopBottomSpreadCenterH>
      </TopBottomCenterH>
    </Dialog>
  );
};
