import _ from "lodash";
import {
  DataGridPro,
  GridColDef,
  Stack,
  Autocomplete,
  TextField,
  Link,
  GridFilterListIcon,
} from "@samacare/design/core";
import Chip from "@samacare/design/core/Chip";
import GridWrapper from "../../components/DataGrid/GridWrapper";
import { PatientTreatmentDetailPage } from "./PatientTreatmentDetailPage";
import GridQuickFilter from "../../components/DataGrid/GridQuickFilter";
import { useFeatureFlag } from "../../hooks/useFeatureFlag";
import { useConfig } from "../../hooks/config";

import {
  PatientTreatmentsGridRow,
  usePatientTreatmentsContext,
} from "./PatientTreatmentsProvider";
import { PatientPresetFilter } from "@samacare/graphql";

const PatientTreatmentToolBar = (quickFilterText?: string) => {
  const { setDuration, duration, setPresetFilter, presetFilter } =
    usePatientTreatmentsContext();

  const options = [
    { title: "Last 24 hours", value: "1" },
    { title: "Last 30 days", value: "30" },
    { title: "Last 60 days", value: "60" },
    { title: "Last 90 days", value: "90" },
    { title: "Last 13 months", value: "400" },
  ];

  return (
    <Stack direction="row" alignItems="center" spacing={1} p={2}>
      <GridQuickFilter
        inputHeightOverride={40}
        inputWidthOverride={300}
        searchWord={quickFilterText}
        data-cy="patient-treatment-quick-filter"
      />
      <Autocomplete
        options={options}
        sx={{ width: 300 }}
        value={options.find((option) => option.value === duration)}
        data-cy="patientTreatmentDurationFilter"
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              label="Duration"
              InputLabelProps={{ shrink: true }}
              data-cy={`patientTreatmentDurationFilter${_.camelCase(
                params.inputProps.value?.toString()
              )}`}
            />
          );
        }}
        getOptionLabel={(option) => option.title}
        onChange={(event, value) => {
          setDuration(value?.value);
        }}
      />
      <Chip
        label="Expiring PAs"
        icon={<GridFilterListIcon />}
        variant={presetFilter ? "filled" : "outlined"}
        color="primary"
        onClick={() => {
          const newFilterValue = presetFilter
            ? undefined
            : PatientPresetFilter.ExpiringAuths;
          setPresetFilter(newFilterValue);
        }}
      />
    </Stack>
  );
};

const RowAction: React.FC<{ label: string }> = ({ label }) => (
  <Link href=" " component="button">
    {label}
  </Link>
);

export const PatientTreatmentsGrid: React.FC = () => {
  const {
    toggleDrawer,
    setActiveRow,
    setPaginationModel,
    paginationModel,
    patientTreatmentCount,
    rows,
    filterModel,
    setFilterModel,
    loading,
  } = usePatientTreatmentsContext();
  const config = useConfig();
  const isEnabled = useFeatureFlag<boolean>(
    config.CONSTANTS.LAUNCH_DARKLY_FEATURE_FLAGS.EnableEligibilityCheck
  );

  const columns: GridColDef[] = [
    {
      field: "institutionPatientId",
      headerName: "MRN",
      minWidth: 100,
    },
    {
      field: "firstName",
      headerName: "First name",
      editable: false,
      minWidth: 180,
    },
    {
      field: "lastName",
      headerName: "Last name",
      editable: false,
      minWidth: 180,
    },
    {
      field: "dob",
      headerName: "Date of birth",
      editable: false,
      minWidth: 180,
    },
    {
      field: "payerName",
      headerName: "Payer",
      editable: false,
      minWidth: 180,
    },
    {
      field: "drugs",
      headerName: "Drugs / Treatment",
      editable: false,
      minWidth: 300,
      renderCell: (params: { row: PatientTreatmentsGridRow }) => {
        return params.row.drugs.map((drug, i) => (
          <Chip
            label={drug}
            size="small"
            sx={{
              marginLeft: ({ spacing }) => spacing(1),
            }}
            key={i}
          />
        ));
      },
    },
    {
      field: "actions",
      headerName: "Action",
      editable: false,
      minWidth: 180,
      renderCell: (params: { row: PatientTreatmentsGridRow }) => {
        return (
          <Stack direction="row" spacing={2}>
            {params.row.showBvSupportNote && <RowAction label="Start BV" />}
            {params.row.hasBvInDraft && <RowAction label="Complete BV" />}

            {params.row.primaryInsurance?.supportsCoverageCheck &&
              isEnabled &&
              !params.row.primaryInsurance.latestCoverageCheck && (
                <RowAction label="Run BV Lite" />
              )}

            {_.find(params.row.treatments, (tx) => tx.expiringAuth) && (
              <RowAction label="Renew PA" />
            )}
            {_.find(
              params.row.treatments,
              (tx) =>
                (tx.bvs ?? []).length > 0 &&
                (tx.authorizations ?? []).length === 0
            ) && <RowAction label="Start PA" />}
          </Stack>
        );
      },
    },
  ];

  const searchWords = filterModel?.quickFilterValues ?? [];
  return (
    <GridWrapper headerText="Patients">
      <DataGridPro
        loading={loading}
        disableColumnFilter
        slots={{
          toolbar: () =>
            PatientTreatmentToolBar(
              searchWords?.length > 0 ? searchWords[0] : null
            ),
        }}
        rows={rows ?? []}
        columns={columns}
        onRowClick={(params) => {
          toggleDrawer(true);
          setActiveRow(_.find(rows, { id: params.row.id }));
        }}
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        rowCount={patientTreatmentCount ?? 0}
        filterModel={filterModel}
        onFilterModelChange={(newFilterModel) => {
          setFilterModel(newFilterModel);
        }}
        localeText={{
          toolbarQuickFilterPlaceholder: "Search by Patient or Drug name",
        }}
        pageSizeOptions={[25, 50]}
        slotProps={{
          row: {
            "data-cy": "patientTreatmentRow",
          },
          pagination: {
            SelectProps: {
              inputProps: { "data-cy": "patientTreatmentPaginationSelect" },
            },
          },
        }}
      />
      <PatientTreatmentDetailPage />
    </GridWrapper>
  );
};
