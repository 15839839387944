import gql from "graphql-tag";
import { graphql } from "@apollo/client/react/hoc";
import _ from "lodash";
import drugOptionInfo from "./fragments/drugOptionInfo";

import { withDefaultProps } from "./utils";

const DRUG_OPTIONS_QUERY_NAME = "getDrugOptions";

export const getDrugOptionsQuery = gql`
  query getDrugOptions {
    getDrugOptions {
      ...drugOptionInfo
    }
  }
  ${drugOptionInfo}
`;

export const withDrugOptions = graphql(getDrugOptionsQuery, {
  props: withDefaultProps(({ data }) => ({
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    drugOptions: _.get(data, "getDrugOptions"),
  })),
});

export const withUpsertDrugOption = graphql(
  gql`
    mutation upsertDrugOption(
      $id: Int
      $code: String!
      $drugName: String!
      $sponsored: Boolean!
    ) {
      upsertDrugOption(
        id: $id
        code: $code
        drugName: $drugName
        sponsored: $sponsored
      ) {
        ...drugOptionInfo
      }
    }
    ${drugOptionInfo}
  `,
  {
    name: "upsertDrugOption",
    options: () => ({ refetchQueries: () => [DRUG_OPTIONS_QUERY_NAME] }),
  }
);

export const withDeleteDrugOption = graphql(
  gql`
    mutation deleteDrugOption($id: Int!) {
      deleteDrugOption(id: $id)
    }
  `,
  {
    name: "deleteDrugOption",
    options: () => ({ refetchQueries: () => [DRUG_OPTIONS_QUERY_NAME] }),
  }
);
