import gql from "graphql-tag";

export default gql`
  fragment drugOptionInfo on DrugOption {
    id
    code
    drugName
    sponsored
  }
`;
