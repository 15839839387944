import { Box } from "@samacare/design";
import { useTheme } from "@samacare/design/core/styles";
import { VFC } from "react";

interface RuleProps {
  sx?: React.CSSProperties;
}
export const Rule: VFC<RuleProps> = ({ sx }) => {
  const theme = useTheme();
  return (
    <Box pt={1} pb={1} pr={2} pl={2} width="100%" sx={sx}>
      <Box
        sx={{
          width: "100%",
          height: "1px",
          background: theme.palette.divider,
        }}
      />
    </Box>
  );
};
