import { TextField, type TextFieldProps } from "@samacare/form";

export type EmailFieldProps = TextFieldProps;

export const EmailField = ({ InputProps = {}, ...props }: EmailFieldProps) => (
  <TextField
    type="email"
    rules={{
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: "Invalid email address",
      },
    }}
    required
    InputProps={{
      autoComplete: "username",
      ...InputProps,
    }}
    {...props}
  />
);
