import { GetAllServiceCodesResult, Query } from "@samacare/graphql";
import { gql, useQuery } from "@apollo/client";
import serviceCodeInfo from "../graphql/fragments/serviceCodeInfo";

export const getAllServiceCodesQuery = gql`
  query getAllServiceCodes {
    getAllServiceCodes {
      drugOptions {
        ...serviceCodeInfo
      }
      hcpcsCodes {
        ...serviceCodeInfo
      }
      allServiceCodes {
        ...serviceCodeInfo
      }
    }
  }
  ${serviceCodeInfo}
`;

export const useAllServiceCodes = (): [
  serviceCodes: GetAllServiceCodesResult,
  loading: boolean,
] => {
  const { loading, data } = useQuery<{
    getAllServiceCodes: Query["getAllServiceCodes"];
  }>(getAllServiceCodesQuery);

  const defaultShape = {
    drugOptions: [],
    hcpcsCodes: [],
    allServiceCodes: [],
  };

  return [data?.getAllServiceCodes ?? defaultShape, loading];
};
