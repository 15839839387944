import { Mutation, MutationCreatePortalArgs, Portal } from "@samacare/graphql";

import { useMutation } from "@apollo/client";
import CreatePortal from "../graphql/CreatePortalMutation.gql";

const useCreatePortal = () => {
  const [createPortal] = useMutation<
    { createPortal: Mutation["createPortal"] },
    MutationCreatePortalArgs
  >(CreatePortal, {
    refetchQueries: ["GetPortals"],
  });

  const create = async (portal: Portal) => {
    const result = await createPortal({
      variables: { object: portal },
    });

    return result.data?.createPortal;
  };

  return create;
};

export { useCreatePortal };
