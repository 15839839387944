import _ from "lodash";
import uuid from "uuid/v4";
import { PureComponent } from "react";
import { compose } from "recompose";
import { withAlert } from "react-alert";
import styled from "styled-components";
import { PhoneCleave, ExtensionCleave } from "Segment/BaseComponents";
import { BaseText } from "Segment/StyledComponents";
import colors from "Resources/colors";
import ErrorOutlineOutlined from "@samacare/design/core/icons/ErrorOutlineOutlined";
import { Tooltip } from "@samacare/design/core";

import { MultiInstitutionSelector } from "./MultiInstitutionSelector";
import BaseButton from "../../../components/BaseButton";
import {
  withUpsertAccountForSamaUsers,
  withUnlockAccount,
  withGeneratePasswordToken,
} from "../../../graphql/Account";
import CustomCheckbox from "../../../components/CustomCheckbox";
import {
  TableCell,
  TableCellName,
  TableRow,
  TableRowEdit,
} from "../AdminStyles";
import TextLabel from "../TextLabel";

const Link = styled.a`
  display: block;
  font-weight: 700;
  color: ${(props) => props.theme.purple};
`;

const Email = styled.div`
  color: ${(props) => props.theme.darkGray};
`;

const EditButton = styled(BaseButton)`
  padding: 5px;
  width: 45%;
  margin-top: 8px;
  margin-left: 5px;
`;

const { CONSTANTS, SITE_DOMAIN, SITE_PROTOCOL, NODE_ENV } = CONFIG;
const ACCOUNT_REQUIRED_FIELDS = ["firstName", "lastName"];

export class AccountRow extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      account: _.omit(
        props.account,
        "allowedInstitutionIds",
        "__typename",
        "isSystemUser"
      ),
      link: null,
      isLockedOut: props.account.isLockedOut,
      editMode:
        props.account.firstName === CONSTANTS.DEFAULT_USER.firstName &&
        props.account.lastName === CONSTANTS.DEFAULT_USER.lastName,
      requiredFields: ACCOUNT_REQUIRED_FIELDS,
      showExpiredButton: false,
      showLockedButton: false,
      showMultiInstitutionSelect: false,
      isEmailChanged: false,
    };
  }

  setAllowedInstitutionIds = async (ids) => {
    const { upsertAccountForSamaUsers, alert } = this.props;
    const { account } = this.state;

    try {
      await upsertAccountForSamaUsers({
        variables: {
          patch: {
            id: account.id,
            allowedInstitutionIds: ids,
            InstitutionId: account.InstitutionId,
          },
        },
      });

      alert.success("Success");
    } catch (e) {
      alert.error(`There was an error updating this account, ${e.message}`);
    }
  };

  validateRequiredFields = () => {
    const { account, requiredFields } = this.state;

    // Try to provide meaningful validation and error messages to users so they know what to edit
    requiredFields.forEach((requiredField) => {
      if (account[requiredField] == null || account[requiredField] === "") {
        throw new Error(`${requiredField} is a required account field`);
      }
    });

    // We also want to ensure that the user replaces the default name values
    if (NODE_ENV !== "dev") {
      if (account.firstName === CONSTANTS.DEFAULT_USER.firstName) {
        throw new Error(
          `Replace default first name with the account holder's first name`
        );
      }
      if (account.lastName === CONSTANTS.DEFAULT_USER.lastName) {
        throw new Error(
          `Replace default last name with the account holder's last name`
        );
      }
    }
  };

  upsert = async () => {
    const { upsertAccountForSamaUsers, alert } = this.props;
    const { account } = this.state;

    try {
      if (!account.isDeactivated) {
        this.validateRequiredFields();
      }

      await upsertAccountForSamaUsers({
        variables: {
          patch: {
            ...account,
          },
        },
      });

      this.setState({
        editMode: false,
        isLockedOut: false,
        showMultiInstitutionSelect: false,
        isEmailChanged: false,
      });

      alert.success("Success");
    } catch (e) {
      alert.error(`There was an error updating this account, ${e.message}`);
    }
  };

  unlockAccount = async () => {
    const { unlockAccount, alert, account } = this.props;

    try {
      await unlockAccount({ variables: { id: account.id } });
      this.setState({ editMode: false, isLockedOut: false });
      alert.success("Success");
    } catch (e) {
      alert.error(`There was an error unlocking this account, ${e.message}`);
    }
  };

  generatePasswordLink = async () => {
    const { generatePasswordToken, alert } = this.props;
    const { account } = this.state;
    const urlRoot = `${SITE_PROTOCOL}${SITE_DOMAIN}${
      NODE_ENV === "dev" ? ":8080" : ""
    }`;
    try {
      const res = await generatePasswordToken({
        variables: { id: account.id },
      });
      const link = `${urlRoot}/#/reset-password/${res.data.generatePasswordToken}`;
      this.setState({ link, editMode: false });
      alert.success("Success");
    } catch (e) {
      alert.error(`There was an error updating this account, ${e.message}`);
    }
  };

  handleChange = (e) => {
    const { target } = e;
    const { account } = this.state;

    if (target.name === "email") {
      this.setState({
        isEmailChanged: true,
      });
    }

    this.setState({ account: { ...account, [target.name]: target.value } });
  };

  handleDeactivateAccount = async () => {
    const { account } = this.state;

    await this.setState({
      account: {
        ...account,
        isDeactivated: true,
        email: `DEACTIVATED@${uuid()}.com`,
      },
    });
    await this.upsert();
  };

  getEditRows = () => {
    const { isSamaUser, isAdmin } = this.props;
    const { account, requiredFields, isEmailChanged } = this.state;

    const nameHandler = (
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <TextLabel
            keyPrefix="account"
            labelKey="firstName"
            labelText="First Name"
            requiredFields={requiredFields}
          />
          <div>
            <BaseText
              data-cy="fieldAccountFirstName"
              placeholder="First"
              name="firstName"
              value={account.firstName || ""}
              onChange={this.handleChange}
            />
          </div>
          <div style={{ width: "8px", height: "8px" }} />
          <TextLabel
            keyPrefix="account"
            labelKey="lastName"
            labelText="Last Name"
            requiredFields={requiredFields}
          />
          <BaseText
            data-cy="fieldAccountLastName"
            name="lastName"
            value={account.lastName || ""}
            onChange={this.handleChange}
          />
        </div>
        <div>
          <div
            style={{ display: "flex", marginTop: "10px", alignItems: "center" }}
          >
            <TextLabel
              keyPrefix="account"
              labelKey="email"
              labelText="Email"
              requiredFields={requiredFields}
            />
            {isEmailChanged && (
              <Tooltip title={CONSTANTS.EDIT_EMAIL_WARNING} placement="right">
                <ErrorOutlineOutlined style={{ fontSize: "20px" }} />
              </Tooltip>
            )}
          </div>
          <BaseText
            title={CONSTANTS.EDIT_EMAIL_WARNING}
            data-cy="fieldAccountEmail"
            style={{ marginTop: "8px" }}
            name="email"
            value={account.email || ""}
            onChange={this.handleChange}
          />
        </div>
      </div>
    );

    const phoneHandler = (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignContent: "center",
        }}
      >
        <div>
          <TextLabel
            keyPrefix="account"
            labelKey="phone"
            labelText="Phone"
            requiredFields={requiredFields}
          />
          <PhoneCleave
            style={{ marginRight: "8px" }}
            name="phone"
            placeholder=""
            number={account.phone || ""}
            onChange={this.handleChange}
          />
        </div>
        <div style={{ fontSize: "16px", margin: "32px 8px 0 24px" }}>x</div>
        <div>
          <TextLabel
            keyPrefix="account"
            labelKey="extension"
            labelText="Extension"
            requiredFields={requiredFields}
          />
          <ExtensionCleave
            name="extension"
            placeholder=""
            number={account.extension || ""}
            onChange={this.handleChange}
          />
        </div>
      </div>
    );

    const adminHandler = (
      <div style={{ marginLeft: "20px" }}>
        <CustomCheckbox
          cypressTag="checkboxIsAdmin"
          checked={account.isAdmin}
          onChange={() => {
            this.handleChange({
              target: { name: "isAdmin", value: !account.isAdmin },
            });
          }}
        />
      </div>
    );

    const readOnlyHandler = (
      <div style={{ marginLeft: "20px" }}>
        <CustomCheckbox
          checked={account.isReadOnly}
          onChange={() => {
            this.handleChange({
              target: { name: "isReadOnly", value: !account.isReadOnly },
            });
          }}
        />
      </div>
    );

    return [nameHandler, phoneHandler].concat(
      isSamaUser || isAdmin ? [adminHandler, readOnlyHandler] : []
    );
  };

  render() {
    const { isSamaUser, isAdmin } = this.props;
    const {
      editMode,
      account,
      link,
      isLockedOut,
      showExpiredButton,
      showLockedButton,
      showMultiInstitutionSelect,
    } = this.state;
    const fullName = `${account.lastName}, ${account.firstName}`;

    const rows = this.getEditRows();

    if (isLockedOut) {
      const accountErrorMessage =
        "This account is locked out because the user has had too many invalid login " +
        "attempts or because the user has not logged in for too long.";

      return (
        <TableRowEdit>
          <td colSpan={`${rows.length}`}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                padding: "16px",
              }}
            >
              <BaseButton
                onClick={() => {
                  this.setState({
                    isLockedOut: null,
                    showExpiredButton: true,
                    showLockedButton: true,
                  });
                }}
                style={{
                  padding: "6px",
                  borderRadius: "5px",
                  marginBottom: "4px",
                }}
              >
                X
              </BaseButton>
            </div>
            <div
              style={{
                height: "125px",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              <div
                style={{ width: "100%", textAlign: "center" }}
              >{`${account.firstName} ${account.lastName} - ${account.email}`}</div>
              <div style={{ width: "100%", textAlign: "center" }}>
                {accountErrorMessage}
              </div>
              <BaseButton
                style={{ width: "22.5%", height: "40px", margin: "8px" }}
                onClick={this.unlockAccount}
              >
                Unlock This Account
              </BaseButton>
              <BaseButton
                style={{ width: "22.5%", height: "40px", margin: "8px" }}
                onClick={this.handleDeactivateAccount}
              >
                Deactivate This Account Permanently
              </BaseButton>
            </div>
          </td>
        </TableRowEdit>
      );
    }

    if (editMode) {
      return (
        <TableRowEdit>
          {_.map(rows.slice(0, -1), (elm, i) => (
            <TableCell key={`account-${account.id}-edit-cell-${i}`}>
              {elm}
            </TableCell>
          ))}
          <TableCell>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              {showLockedButton && (
                <BaseButton
                  onClick={() => {
                    this.setState({ isLockedOut: true });
                  }}
                  style={{
                    padding: "6px",
                    borderRadius: "5px",
                    marginBottom: "4px",
                    marginRight: "8px",
                  }}
                >
                  Account is Locked
                </BaseButton>
              )}
              {showExpiredButton && (
                <BaseButton
                  onClick={() => {
                    this.setState({ isLockedOut: true });
                  }}
                  style={{
                    padding: "6px",
                    borderRadius: "5px",
                    marginBottom: "4px",
                    marginRight: "8px",
                  }}
                >
                  Account is Expired
                </BaseButton>
              )}
            </div>
            {_.last(rows)}
          </TableCell>
          <TableCell>
            <BaseButton
              onClick={() => {
                this.setState({ editMode: false });
              }}
              style={{
                padding: "6px",
                borderRadius: "5px",
                marginBottom: "4px",
                marginLeft: "auto",
              }}
            >
              X
            </BaseButton>
            {showMultiInstitutionSelect && (
              <>
                <MultiInstitutionSelector
                  currentInstitutionId={account.InstitutionId}
                  allowedInstitutionIds={
                    this.props.account.allowedInstitutionIds
                  }
                  upsertAccountForSamaUsers={this.setAllowedInstitutionIds}
                />
                <EditButton
                  data-cy="actionSaveChanges"
                  style={{ backgroundColor: colors.purple, color: "white" }}
                  onClick={this.upsert}
                >
                  Save changes
                </EditButton>
              </>
            )}
            {!showMultiInstitutionSelect && (
              <div
                style={{ display: "flex", flexWrap: "wrap", marginTop: "8px" }}
              >
                {isSamaUser && (
                  <EditButton
                    onClick={() => {
                      this.setState({ showMultiInstitutionSelect: true });
                    }}
                  >
                    Edit user institutions
                  </EditButton>
                )}
                {isAdmin && (
                  <EditButton
                    data-cy="actionDeactivateAccount"
                    onClick={this.handleDeactivateAccount}
                  >
                    Deactivate Account
                  </EditButton>
                )}
                {isSamaUser && !window.CONFIG.COGNITO_ENABLED && (
                  <EditButton onClick={this.generatePasswordLink}>
                    Generate Password Link
                  </EditButton>
                )}
                <EditButton
                  data-cy="actionSaveChanges"
                  style={{ backgroundColor: colors.purple, color: "white" }}
                  onClick={this.upsert}
                >
                  Save changes
                </EditButton>
              </div>
            )}
          </TableCell>
        </TableRowEdit>
      );
    }

    if (link) {
      return (
        <TableRowEdit>
          <td colSpan={`${rows.length}`}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                padding: "16px",
              }}
            >
              <BaseButton
                onClick={() => {
                  this.setState({
                    link: null,
                    showMultiInstitutionSelect: false,
                  });
                }}
                style={{
                  padding: "6px",
                  borderRadius: "5px",
                  marginBottom: "4px",
                }}
              >
                X
              </BaseButton>
            </div>
            <div
              style={{
                height: "100px",
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <Link href={link}>{link}</Link>
            </div>
          </td>
        </TableRowEdit>
      );
    }

    return (
      <TableRow
        data-cy={`componentAccountRow${account.firstName}${account.lastName}`}
        onClick={() => {
          this.setState({ editMode: true });
        }}
      >
        <TableCell>
          <TableCellName>{fullName}</TableCellName>
          <Email>{account.email}</Email>
        </TableCell>
        <TableCell>
          {`${account.phone || ""}${
            account.extension ? `   x${account.extension}` : ""
          }`}
        </TableCell>
        {(isSamaUser || isAdmin) && (
          <TableCell>
            <CustomCheckbox
              cypressTag="componentIsAdminCheckbox"
              checked={account.isAdmin}
            />
          </TableCell>
        )}
        {(isSamaUser || isAdmin) && (
          <TableCell>
            <CustomCheckbox checked={account.isReadOnly} />
          </TableCell>
        )}
      </TableRow>
    );
  }
}

/**
 * @deprecated Use a functional component instead (non HOC)
 */
export default compose(
  withUpsertAccountForSamaUsers,
  withGeneratePasswordToken,
  withUnlockAccount
)(withAlert()(AccountRow));
