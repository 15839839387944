import { Stack, Box, Typography } from "@samacare/design/core";
import { QuestionnaireField } from "../../components/Questionnaire";
import { QuestionTypeEnumType } from "@samacare/graphql";
import { useWatch, TextField } from "@samacare/form";
import { useEnrollmentContext } from "../../routes/Enrollment/EnrollmentProvider";
import Link from "@samacare/design/core/Link";

export type PrescriptionInfoBlockProps = {
  isSubmitted?: boolean;
  isRequired?: boolean;
};

export const PrescriptionInfoBlock: React.FC<PrescriptionInfoBlockProps> = ({
  isSubmitted = false,
  isRequired = false,
}) => {
  const questionnaireData = useWatch({
    name: "questionnaire",
  });
  const { enrollmentProgram } = useEnrollmentContext();

  return (
    <Stack direction="column">
      <Typography variant="body1" color="text.primary">
        To view administrative instructions –{" "}
        <Link
          href={enrollmentProgram?.pdfUrl ?? ""}
          target="_blank"
          rel="noreferrer"
        >
          View form here
        </Link>
      </Typography>
      <Box>
        <strong> Dose:</strong> KRYSTEXXA® (pegloticase) injection, 8 mg/mL, for
        intravenous infusion every two weeks
      </Box>
      <Stack my={2} spacing={2} direction="row" maxWidth={600}>
        <TextField
          label="Vial quantity"
          name="questionnaire.sama-prescriptionInfo-vialQuantity"
          disabled={isSubmitted}
          type={QuestionTypeEnumType.Number}
          fullWidth
          required={isRequired}
        />
        <TextField
          label="Refills"
          name="questionnaire.sama-prescriptionInfo-Refills"
          type={QuestionTypeEnumType.Number}
          disabled={isSubmitted}
          fullWidth
          required={isRequired}
        />
      </Stack>
      <QuestionnaireField
        id="sama-hasAllergies"
        type={QuestionTypeEnumType.Choice}
        text="Does patient have allergies?"
        disabled={isSubmitted}
        answerOption={[{ valueString: "Yes" }, { valueString: "No" }]}
        required
      />
      {questionnaireData["sama-hasAllergies"] === "Yes" && (
        <Stack my={2} maxWidth={600}>
          <TextField
            label="Drug Allergies"
            name="questionnaire.sama-prescriptionInfo-allergies"
            disabled={isSubmitted}
            fullWidth
          />
        </Stack>
      )}
      <Stack spacing={2} direction="column">
        <QuestionnaireField
          id="sama-prescriptionInfo-authorizeAdministration"
          type={QuestionTypeEnumType.Boolean}
          text="Authorize administration supplies as needed"
          disabled={isSubmitted}
        />
      </Stack>
    </Stack>
  );
};
