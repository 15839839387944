import * as React from "react";
import { Typography, Button, Box } from "@samacare/design/core";
import { useTheme } from "styled-components";
import { CheckCircle } from "@@ui-kit/Icons";

import { DialogModal } from "@@components/DialogModal";
import { WidgetIcon } from "./WidgetIcon";
import { ChromeWebStoreImage } from "@@components/ChromeWebStoreImage";
import { DownloadCopilotButton } from "@@components/DownloadCopilotButton";

const OrangeContainer: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        border: `2px solid ${theme.orange}`,
        borderRadius: "8px",
        padding: 2.5,
        textAlign: "center",
        position: "relative",
        maxWidth: "400px",
        margin: "0 auto",
      }}
    >
      {children}
    </Box>
  );
};

const PurpleContainer: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        border: `1px solid ${theme.primary}`,
        borderRadius: "8px",
        padding: 7.5,
        textAlign: "center",
        position: "relative",
        my: 2.5,
        mx: "auto",
        maxWidth: "300px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {children}
    </Box>
  );
};

const PurpleLine: React.FC = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        height: "1px",
        width: "100%",
        backgroundColor: theme.primary,
        position: "absolute",
        top: "10px",
        left: 0,
      }}
    />
  );
};

const Dot: React.FC = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        height: "3px",
        width: "3px",
        borderRadius: "50%",
        backgroundColor: theme.primary,
        display: "inline-block",
        my: 0,
        mx: 0.25,
      }}
    />
  );
};

const DotsContainer: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        position: "absolute",
        top: "3px",
        left: "10px",
        marginBottom: 0.6,
      }}
    >
      {children}
    </Box>
  );
};

const WidgetIconContainer: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <Box
      sx={{
        position: "absolute",
        right: 0,
        bottom: "10px",
        display: "flex",
        alignItems: "center",
      }}
    >
      {children}
    </Box>
  );
};

const LearnMoreText: React.FC = () => {
  const theme = useTheme();
  const copilotFaqLink =
    "https://samacare-help.freshdesk.com/support/solutions/articles/72000630963-samacare-copilot";
  return (
    <Typography
      sx={{
        fontSize: "12px",
        color: theme.gray,
        textAlign: "left",
        marginTop: 2.5,
        marginLeft: 1.25,
      }}
    >
      Learn more{" "}
      <a
        href={copilotFaqLink}
        style={{ color: theme.darkGray, fontWeight: "bold" }}
        target="_blank"
        rel="noopener noreferrer"
      >
        CoPilot FAQ
      </a>
    </Typography>
  );
};

export const DownloadExtensionDialog: React.FC<{
  open: boolean;
  onClose: () => void;
  webExtensionUrl: string;
}> = ({ open, onClose, webExtensionUrl }) => {
  const theme = useTheme();

  const handleWebExtensionClick = () => {
    window.open(webExtensionUrl, "_blank", "noopener,noreferrer");
  };

  const content = (
    <Box>
      <OrangeContainer>
        <Typography gutterBottom align="center">
          Works in <b>All Portals</b>. Download today.
        </Typography>
        <PurpleContainer>
          <DotsContainer>
            <Dot />
            <Dot />
            <Dot />
          </DotsContainer>
          <PurpleLine />
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "500",
              whiteSpace: "nowrap",
              paddingBottom: 2.5,
              color: theme.primary,
            }}
          >
            <CheckCircle color={theme.green} size="20px" /> Prior Authorization
            Submitted
          </Typography>
          <WidgetIconContainer>
            <WidgetIcon />
          </WidgetIconContainer>
        </PurpleContainer>
        <Typography align="center" sx={{ marginTop: 2.5 }}>
          <ChromeWebStoreImage />
        </Typography>
      </OrangeContainer>
      <LearnMoreText />
    </Box>
  );

  const actions = (
    <>
      <Button
        onClick={onClose}
        sx={{ color: theme.orange, textDecoration: "underline" }}
      >
        Close
      </Button>
      <DownloadCopilotButton onClick={handleWebExtensionClick} />
    </>
  );

  return (
    <DialogModal
      open={open}
      onClose={onClose}
      title="Download CoPilot to Capture Portal PAs in SamaCare"
      content={content}
      actions={actions}
    />
  );
};
