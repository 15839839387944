import { Box, Button, Stack, Typography } from "@samacare/design";
import { useFormContext, AutocompleteField } from "@samacare/form";
import Add from "@samacare/design/core/icons/Add";
import { useEffect, useRef, useState } from "react";
import { PlaceOfServiceCode } from "@samacare/graphql";
import { Rule } from "./Rule";
import { ServiceCodeBlock } from "./ServiceCodeBlock";

const PlaceOfServiceLabels: Record<PlaceOfServiceCode, string> = {
  [PlaceOfServiceCode.School]: "03 - School",
  [PlaceOfServiceCode.Office]: "11 - Office",
  [PlaceOfServiceCode.Home]: "12 - Home",
  [PlaceOfServiceCode.AssistedLivingFacility]: "13 - Assisted living facility",
  [PlaceOfServiceCode.OffCampusOutPatientHospital]:
    "19 - Off campus-outpatient hospital",
  [PlaceOfServiceCode.OnCampusOutPatientHospital]:
    "22 - On campus-outpatient hospital",
  [PlaceOfServiceCode.AmbulatorySurgicalCenter]:
    "24 - Ambulatory surgical center",
  [PlaceOfServiceCode.AmbulanceLand]: "41 - Ambulance land",
  [PlaceOfServiceCode.AmbulanceAirOrWater]: "42 - Ambulance air or water",
  [PlaceOfServiceCode.PsychiatricFacilityPartialHospitalization]:
    "52 - Psychiatric facility-partial hospitalization",
  [PlaceOfServiceCode.NonResidentSubstanceAbuseTreatmentFacility]:
    "57 - Non-resident substance abuse treatment facility",
  [PlaceOfServiceCode.NonResidentialOpioidTreatmentFacility]:
    "58 - Non-residental opioid treatment facility",
  [PlaceOfServiceCode.EndStageRenalDiseaseTreatmentFacility]:
    "65 - End stage renal disease treatment facility",
};

const PlaceOfServiceCodeOrder: PlaceOfServiceCode[] = [
  PlaceOfServiceCode.School,
  PlaceOfServiceCode.Office,
  PlaceOfServiceCode.Home,
  PlaceOfServiceCode.AssistedLivingFacility,
  PlaceOfServiceCode.OffCampusOutPatientHospital,
  PlaceOfServiceCode.OnCampusOutPatientHospital,
  PlaceOfServiceCode.AmbulatorySurgicalCenter,
  PlaceOfServiceCode.AmbulanceLand,
  PlaceOfServiceCode.AmbulanceAirOrWater,
  PlaceOfServiceCode.PsychiatricFacilityPartialHospitalization,
  PlaceOfServiceCode.NonResidentSubstanceAbuseTreatmentFacility,
  PlaceOfServiceCode.NonResidentialOpioidTreatmentFacility,
  PlaceOfServiceCode.EndStageRenalDiseaseTreatmentFacility,
];

const HiddenBox = ({ sx }: { sx?: React.CSSProperties }) => (
  <Box sx={{ ...sx, visibility: "hidden", width: "100%" }} />
);

export type ServiceBlockProps = {
  required?: boolean;
  disabled?: boolean;
  limit?: number;
};

const ServiceBlock: React.FC<ServiceBlockProps> = (props) => {
  const { required = true, disabled = false, limit = 5 } = props;
  const { watch, setValue } = useFormContext();
  const [codeIds, setCodeIds] = useState<number[]>([]);
  const idGen = useRef<number>(1);

  useEffect(() => {
    setCodeIds([idGen.current++]);
  }, []);

  const handleAdd = () => {
    setCodeIds([...codeIds, idGen.current++]);
  };

  const handleDelete = (codeId: number) => {
    const newCodeIds = codeIds.filter((i) => i !== codeId);
    const newServices = newCodeIds.reduce((obj: Record<string, any>, id) => {
      const tag = `service${id}`;
      const newObj = { ...obj, [tag]: watch(`services.${tag}`) };
      return newObj;
    }, {});
    setCodeIds(newCodeIds);
    setValue(`services`, newServices);
  };

  const count = codeIds.length;

  return (
    <Stack spacing={2} maxWidth={600}>
      <Stack direction="row" spacing={1}>
        <AutocompleteField<string, false, true, false>
          disableClearable
          getOptionLabel={(val) =>
            PlaceOfServiceLabels[val as PlaceOfServiceCode] ?? ""
          }
          options={PlaceOfServiceCodeOrder}
          label="Place of service"
          name="placeOfServiceCode"
          required={required}
          disabled={disabled}
          value={watch("placeOfServiceCode") ?? ""}
          sx={{ flex: 2 }}
        />
        <HiddenBox sx={{ flex: 1 }} />
      </Stack>

      {codeIds.map((id) => (
        <ServiceCodeBlock
          key={id}
          codeId={id}
          required={required}
          disabled={disabled}
          handleDelete={codeIds.length > 1 ? handleDelete : undefined}
        />
      ))}
      <Rule sx={{ paddingBottom: 0 }} />
      <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
        {count === 5 ? (
          <Typography
            variant="caption"
            color="GrayText"
          >{`Maximum of ${limit} HCPCS Codes reached`}</Typography>
        ) : (
          <Button onClick={handleAdd} startIcon={<Add />}>
            Add HCPCS Code
          </Button>
        )}
      </Box>
    </Stack>
  );
};

export default ServiceBlock;
