import React, { useCallback, useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Button, CircularProgress, Typography } from "@samacare/design";
import SamaDropzone from "../../../components/SamaDropzone";
import useCreateIngestJob from "../../../hooks/useCreateIngestJob";
import { useConfig } from "../../../hooks";
import useAutoRefreshIngestJob from "../../../hooks/useAutoRefreshIngestJob";
import { PipelineStage } from "@samacare/graphql";
import papa from "papaparse";
import { ColumnMapper } from "./ColumnMapper";
import { requiredPatientColumns } from "./PatientColumns";
import { UploadResults } from "./UploadResults";
import { IngestProgress } from "./IngestProgress";
import { TopBottomCenterH } from "../../../components/TopBottomV2";
import { useIngestTransform } from "../../../hooks/useIngestTransform";
import { useLatestIngestJob } from "../../../hooks/useLatestIngestJob";
import { LeftRightCenterAll } from "@samacare/component";
import { Box } from "@samacare/design/core";

const MAX_FILE_SIZE = 100 * 1000000; // 100 MB
const SLICE_SIZE = 8 * 1024; // 8KB

enum UploadStep {
  PickFile,
  ChooseColumns,
  Progress,
}

const PatientsUpload: React.FC = () => {
  const alert = useAlert();
  const config = useConfig();

  const [file, setFile] = useState<File | null>(null);
  const [fileColumnNames, setFileColumnNames] = useState<string[]>([]);
  const [uploadStep, setUploadStep] = useState<UploadStep>(UploadStep.PickFile);
  const [parsedRows, setParsedRows] = useState<any[]>([]);

  const createIngestJob = useCreateIngestJob();
  const { ingestJob, setIngestJob } = useAutoRefreshIngestJob();
  const { ingestTransform, loading: isTransformLoading } = useIngestTransform();
  const { ingestJob: latestJob, loading: isLatestLoading } =
    useLatestIngestJob();

  useEffect(() => {
    if (uploadStep === UploadStep.PickFile) {
      setFile(null);
      setFileColumnNames([]);
      setIngestJob(null);
    }
  }, [uploadStep, setIngestJob]);

  useEffect(() => {
    if (latestJob) {
      setUploadStep(UploadStep.Progress);
      setIngestJob(latestJob);
    }
  }, [latestJob, setIngestJob]);

  const handleFileSlice = useCallback(
    (loadEvent: ProgressEvent<FileReader>) => {
      const content = loadEvent.target?.result;
      if (typeof content === "string") {
        const parsed = papa.parse(content, {
          skipEmptyLines: true,
          header: true,
        });
        if (parsed.data.length === 0) {
          alert.error("File contains header but no data");
        } else if (parsed.meta.fields) {
          if (parsed.meta.fields.length < requiredPatientColumns.length) {
            alert.error("File does not have enough columns");
          } else {
            setFileColumnNames(parsed.meta.fields);
            setParsedRows(parsed.data);
            setUploadStep(UploadStep.ChooseColumns);
          }
        } else {
          alert.error("Could not parse file");
        }
      }
    },
    [alert]
  );

  const createAndSetIngestJob = useCallback(
    async (uploadFile: File, fromByToMappings: Map<string, string> | null) => {
      setUploadStep(UploadStep.Progress);
      const ij = await createIngestJob(
        uploadFile,
        fromByToMappings,
        fileColumnNames
      );
      if (ij) {
        setIngestJob(ij);
      }
    },
    [createIngestJob, setIngestJob, fileColumnNames]
  );

  const handleDropSuccess = useCallback(
    async (acceptedFiles: File[]) => {
      if (isTransformLoading) {
        alert.error("Transform is still loading, please wait a moment.");
        return;
      }

      try {
        if (acceptedFiles && acceptedFiles.length > 0) {
          setFile(acceptedFiles[0]);

          // If there is more than one transform item, we don't handle the reformat_date and inject_header_row
          // items in the frontend yet, so we just use the existing transform items as is.
          if ((ingestTransform?.ingestTransformItems?.length ?? 0) > 1) {
            await createAndSetIngestJob(acceptedFiles[0], null);
            return;
          }

          // Use FileReader to read the slice
          const slice = acceptedFiles[0].slice(0, SLICE_SIZE);
          const reader = new FileReader();
          reader.onload = (loadEvent) => handleFileSlice(loadEvent);
          reader.readAsText(slice);
        }
      } catch (e) {
        alert.error("upload failed");
      }
    },
    [
      alert,
      createAndSetIngestJob,
      handleFileSlice,
      ingestTransform,
      isTransformLoading,
    ]
  );

  const handleDropRejected = useCallback(
    (fileRejections: { size: number; type: string }[]) => {
      if (fileRejections.length > 0) {
        const rejection = fileRejections[0];
        if (rejection.size === 0) {
          alert.error("File cannot be empty");
        } else if (rejection.type !== "text/csv") {
          alert.error("File must be in .csv format");
        } else {
          alert.error("File rejected");
        }
      }
    },
    [alert]
  );

  const onColumnMappingDone = useCallback(
    async (fromByToMappings: Map<string, string>) => {
      if (file) {
        await createAndSetIngestJob(file, fromByToMappings);
      }
    },
    [file, createAndSetIngestJob]
  );

  return (
    <div>
      <Typography color="primary" variant="h6" sx={{ marginTop: "20px" }}>
        Patient Demographic Import
      </Typography>

      <Box
        sx={{
          marginTop: ({ spacing }) => spacing(6),
          marginLeft: ({ spacing }) => spacing(3),
          width: "600px",
        }}
      >
        {isLatestLoading ? (
          <LeftRightCenterAll sx={{ marginTop: ({ spacing }) => spacing(16) }}>
            <CircularProgress />
          </LeftRightCenterAll>
        ) : (
          <TopBottomCenterH>
            {uploadStep === UploadStep.PickFile && (
              <SamaDropzone
                maxSize={MAX_FILE_SIZE}
                onDrop={handleDropSuccess}
                onDropRejected={handleDropRejected}
                accept={[config.CONSTANTS.CONTENT_TYPES.CSV]}
                className="dropzone"
                buttonText="Select a file"
                helperText="Files must be in .csv format"
                minSize={1}
              />
            )}
            {uploadStep === UploadStep.ChooseColumns && (
              <ColumnMapper
                fileColumnNames={fileColumnNames}
                onDone={onColumnMappingDone}
                onBack={() => setUploadStep(UploadStep.PickFile)}
                rows={parsedRows}
              />
            )}
            {uploadStep === UploadStep.Progress && (
              <>
                <IngestProgress ingestJob={ingestJob} />
                {ingestJob &&
                  (ingestJob.currentStage === PipelineStage.Finished ||
                    ingestJob.currentStage === PipelineStage.Failed) && (
                    <>
                      <UploadResults ingestJob={ingestJob} />
                      <Button
                        variant="contained"
                        onClick={() => setUploadStep(UploadStep.PickFile)}
                      >
                        Import Another File
                      </Button>
                    </>
                  )}
              </>
            )}
          </TopBottomCenterH>
        )}
      </Box>
    </div>
  );
};

export default PatientsUpload;
