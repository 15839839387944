import { Stack, Typography } from "@samacare/design/core";
import { EnrollmentSection } from "./EnrollmentSection";
import { QuestionnaireField } from "../../components/Questionnaire";
import { QuestionTypeEnumType } from "@samacare/graphql";
import {
  useWatch,
  MedicalRequestBlock,
  DateField,
  TextField,
} from "@samacare/form";
import { PrimaryAndSecondaryIcdCodeBlock } from "./PrimaryAndSecondaryIcdCodeBlock";

export type PrescriptionInfoBlockProps = {
  isSubmitted?: boolean;
};

export const PrescriptionInfoBlock: React.FC<PrescriptionInfoBlockProps> = ({
  isSubmitted = false,
}) => {
  const questionnaireData = useWatch({
    name: "questionnaire",
  });

  return (
    <>
      <Stack my={2} ml={2} spacing={2} direction="row">
        <QuestionnaireField
          id="prescriptionType"
          type={QuestionTypeEnumType.Choice}
          disabled={isSubmitted}
          required
          text="Please select a type of service below:"
          answerOption={[
            { valueString: "Buy and bill" },
            { valueString: "Specialty pharmacy" },
          ]}
        />
      </Stack>
      {questionnaireData.prescriptionType === "Buy and bill" && (
        <Stack ml={2} direction="column">
          <PrimaryAndSecondaryIcdCodeBlock isSubmitted={isSubmitted} />
        </Stack>
      )}
      {questionnaireData.prescriptionType === "Specialty pharmacy" && (
        <Stack ml={2} direction="column">
          <PrimaryAndSecondaryIcdCodeBlock isSubmitted={isSubmitted} />
          <EnrollmentSection title="Treatment Information">
            <Stack my={2} ml={2} spacing={2} direction="column">
              <QuestionnaireField
                id="startTreatment"
                type={QuestionTypeEnumType.Choice}
                disabled={isSubmitted}
                answerOption={[{ valueString: "Yes" }, { valueString: "No" }]}
                required
                text="Has patient started treatment?"
              />
              <DateField
                name="questionnaire.sama-prescriptionInfo-dateOfTreatment"
                disabled={isSubmitted}
                label={
                  questionnaireData.startTreatment === "No"
                    ? "Anticipated date of first treatment"
                    : questionnaireData.startTreatment === "Yes"
                    ? "Date of next treatment"
                    : "Date of treatment"
                }
                style={{ width: 300 }}
                required
              />
            </Stack>
          </EnrollmentSection>
          <EnrollmentSection title="Dispense & Refill Information">
            <Stack spacing={2} direction="column" maxWidth={300}>
              <MedicalRequestBlock disabled />
              <TextField
                name="questionnaire.sama-prescriptionInfo-dispenseQuantity"
                label="Dispense vial#"
                disabled={isSubmitted}
                type="number"
                required
              />
              <TextField
                name="questionnaire.sama-prescriptionInfo-refillQuantity"
                label="Refill #"
                disabled={isSubmitted}
                type="number"
                required
              />
            </Stack>
          </EnrollmentSection>
          <Stack direction="row" alignItems="flex-end">
            <QuestionnaireField
              id="sig"
              type={QuestionTypeEnumType.Boolean}
              text="SIG"
              disabled={isSubmitted}
              required
            />
            <Stack direction="row" spacing={2} alignItems="baseline">
              <Typography variant="body">
                Inject 15 mg (0.1 mL) intravitreally once every
              </Typography>
              <TextField
                name="questionnaire.sama-prescriptionInfo-sigQuantity"
                placeholder="(25 to 60)"
                type="number"
              />
              <Typography variant="body"> days</Typography>
            </Stack>
          </Stack>

          <Stack spacing={2} direction="row">
            <QuestionnaireField
              id="ancillarySupplies"
              type={QuestionTypeEnumType.Boolean}
              text="Ancillary supplies - Rx only: IVT Injection Kit (29G thin-wall injection needle and 5M filter needle)"
              disabled={isSubmitted}
            />
          </Stack>
        </Stack>
      )}
    </>
  );
};
