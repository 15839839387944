import { ReactNode } from "react";
import { Stack } from "@samacare/design";
import ProviderBlock from "../ProviderBlock";
import CheckboxField from "../CheckboxField";
import { useFormContext } from "@samacare/form";

export type RenderingProviderBlockProps = {
  required?: boolean;
  disabled?: boolean;
  allowNew?: boolean;
  chooseDisabled?: boolean;
  children?: ReactNode;
};

const RenderingProviderBlock: React.FC<RenderingProviderBlockProps> = (
  props
) => {
  const { watch } = useFormContext();
  const isRenderingSameAsRequesting = watch("isRenderingSameAsRequesting");

  return (
    <Stack spacing={2} maxWidth={600} justifyContent="center">
      <Stack direction="row" alignItems="center">
        <CheckboxField
          name="isRenderingSameAsRequesting"
          label="Same as Requesting Provider"
        />
      </Stack>
      {!isRenderingSameAsRequesting && (
        <ProviderBlock
          {...props}
          providerObjName="renderingProvider"
          hideProviderType
        />
      )}
    </Stack>
  );
};

export default RenderingProviderBlock;
