import {
  CreateBenefitsVerificationMutation,
  RefreshBenefitsCheckLiteMutationMutation,
  SubmitBenefitsVerificationMutation,
  UpdateBenefitsVerificationMutation,
  UpsertBenefitsCheckPatientMutationMutation,
} from "@@generated/graphql";
import { useMutation } from "@apollo/client";
import { Box } from "@samacare/design/core";
import Typography from "@samacare/design/core/Typography";
import {
  BenefitsVerificationTypeEnum,
  UnifiedBenefitsCheckType,
} from "@samacare/graphql";

import moment from "moment";
import * as React from "react";
import { useAlert } from "react-alert";
import { useHistory, useLocation } from "react-router-dom";

import { usePatient } from "@@hooks/usePatient";
import CircularProgress from "@samacare/design/core/CircularProgress";
import { segmentTypewriter } from "../../../../analytics/analytics";
import { getSegmentEnumInsuranceTypeFromSamacareInsuranceType } from "../../../../analytics/util";
import { ALL_BENEFITS_VERIFICATIONS_QUERY_NAME } from "../../../graphql/BenefitsVerificationOutcome";
import { useConfig, useFeatureFlag, useFeatureFlagNames } from "../../../hooks";
import { useAccount } from "../../../providers/AccountProvider";
import ROUTE_PATHS from "../../ROUTE_PATHS";
import {
  BenefitsVerificationRequestFormFullGeneric,
  BenefitsVerificationRequestFormLite,
  BenefitsVerificationRequestFormPavblu,
  RightSideMenu,
} from "../components";
import {
  transformRequestFormToPatch,
  transformToPatienPatch,
  transformToPrimaryInsurancePatch,
  transformUrlInfoToFormValues,
} from "../formUtils";
import CreateBenefitsVerificationMutationGql from "../graphql/CreateBenefitsVerification.gql";
import RefreshBenefitsCheckLiteMutationGql from "../graphql/RefreshBenefitsCheckLite.gql";
import SubmitBenefitsVerificationMutationGql from "../graphql/SubmitBenefitsVerification.gql";
import UpdateBenefitsVerificationMutationGql from "../graphql/UpdateBenefitsVerification.gql";
import UpsertBenefitsCheckPatientMutationGql from "../graphql/UpsertBenefitsCheckPatient.gql";
import { RequestBenefitVerificationFormDataType } from "../types";

export const CreateBenefitsVerificationRequest: React.VoidFunctionComponent =
  () => {
    const history = useHistory();
    const alert = useAlert();
    const account = useAccount();
    const config = useConfig();
    const [submitErrors, setSubmitErrors] = React.useState<string[]>([]);
    const location = useLocation();
    const bvType = new URLSearchParams(location.search).get(
      "type"
    ) as UnifiedBenefitsCheckType;
    const drugOptionId = new URLSearchParams(location.search).get(
      "drugOptionId"
    );
    const isLiteBv = bvType === UnifiedBenefitsCheckType.Lite;
    const isFullBv = bvType === UnifiedBenefitsCheckType.Full;
    const featureFlags = useFeatureFlagNames();
    const enabledDrugs =
      useFeatureFlag<{ drugName: string; drugOptionId: number }[]>(
        featureFlags.AvailableBenefitsVerificationsDrugsV2
      ) ?? [];

    const isPavblu =
      isFullBv &&
      !!drugOptionId &&
      enabledDrugs.find(
        (drug) =>
          Object.values(config.CONSTANTS.CAREMETX_BV_DRUGS).includes(
            drug.drugName
          ) && drug.drugOptionId === parseInt(drugOptionId, 10)
      ) != null;

    const isGeneric =
      !!drugOptionId &&
      isFullBv &&
      enabledDrugs.find(
        (drug) => drug.drugOptionId === parseInt(drugOptionId, 10)
      ) != null &&
      !isPavblu;

    const patientId = new URLSearchParams(location.search).get("patientId");
    const patientResult = usePatient(Number(patientId));

    const defaultValues = transformUrlInfoToFormValues({
      type: bvType,
      drugOptionId,
      patient: patientResult.patient,
      isPavblu,
    }) as RequestBenefitVerificationFormDataType;

    // init bv graphql mutations
    const [createBenefitsVerification] =
      useMutation<CreateBenefitsVerificationMutation>(
        CreateBenefitsVerificationMutationGql,
        { refetchQueries: [ALL_BENEFITS_VERIFICATIONS_QUERY_NAME] }
      );
    const [updateBenefitsVerification] =
      useMutation<UpdateBenefitsVerificationMutation>(
        UpdateBenefitsVerificationMutationGql,
        { refetchQueries: [ALL_BENEFITS_VERIFICATIONS_QUERY_NAME] }
      );
    const [submitBenefitsVerification] =
      useMutation<SubmitBenefitsVerificationMutation>(
        SubmitBenefitsVerificationMutationGql,
        { refetchQueries: [ALL_BENEFITS_VERIFICATIONS_QUERY_NAME] }
      );
    const [upsertPatient] =
      useMutation<UpsertBenefitsCheckPatientMutationMutation>(
        UpsertBenefitsCheckPatientMutationGql
      );
    const [refreshBenefitsCheckLite] =
      useMutation<RefreshBenefitsCheckLiteMutationMutation>(
        RefreshBenefitsCheckLiteMutationGql,
        { refetchQueries: ["SearchUnifiedBenefitsChecks"] }
      );

    const onBack = () => {
      history.push(`${ROUTE_PATHS.BENEFITS_VERIFICATIONS.path}/type`);
    };

    const onSubmit = React.useCallback(
      async (data: RequestBenefitVerificationFormDataType) => {
        const { benefitsCheckType, PatientId, patient } = data;

        if (benefitsCheckType === UnifiedBenefitsCheckType.Lite) {
          try {
            segmentTypewriter.initiateBvLite({
              accountId: +account!.id,
              email: account!.email!,
              isAdmin: account!.isAdmin,
              institutionIsTop: account!.institution!.isTop,
              institutionName: account!.institution!.name,
              institutionSpecialty:
                account!.institution!.practicingSpecialty ?? undefined,
              insuranceName: data?.insuranceCompanyName ?? "",
              insuranceState: data.primaryInsurance.insuranceState ?? undefined,
              insuranceType:
                getSegmentEnumInsuranceTypeFromSamacareInsuranceType(
                  data.primaryInsurance.planType ?? undefined
                ),
            });

            const patientResponse = await upsertPatient({
              variables: {
                InstitutionId: account?.institution?.id,
                id: PatientId,
                patientData: transformToPatienPatch(data),
                primaryInsuranceData: transformToPrimaryInsurancePatch(data),
              },
            });
            const bvLiteResponse = await refreshBenefitsCheckLite({
              variables: {
                PatientId: patientResponse.data?.upsertBenefitsCheckPatient?.id,
              },
            });

            if (
              bvLiteResponse.data?.refreshPrimaryInsuranceCoverageCheck
                ?.success === false
            ) {
              setSubmitErrors(
                (
                  bvLiteResponse.data?.refreshPrimaryInsuranceCoverageCheck
                    ?.errorMessage ?? " "
                ).split("\n")
              );
            } else {
              alert.success("Lite benefits checks requested");
              history.push(
                `${ROUTE_PATHS.BENEFITS_VERIFICATIONS.path}/view/${bvLiteResponse.data?.refreshPrimaryInsuranceCoverageCheck?.data?.id}`
              );
            }
          } catch (err) {
            const error = err as Error;
            setSubmitErrors(error.message.split("\n"));
          }
          return;
        }

        try {
          // The BV request creation view does 3 things under the hood when submitted
          // - creates a BV using patient data only
          // - updates the BV using the form data
          // - submits the request
          // The reason for this 3 step process is we re-designed the graphql mutations to support the PA -> BV flow
          // The separate mutations also allows us to iterate on the UI (ex: separating form section into steps) more quickly

          // create the bv using patient data only
          const { data: createData, errors } = await createBenefitsVerification(
            {
              variables: {
                drugOptionId,
                institutionId: account?.institution?.id,
                ...(PatientId != null && {
                  patientId: parseInt(PatientId, 10),
                }),
                ...(PatientId == null && {
                  patientData: {
                    firstName: patient.firstName,
                    lastName: patient.lastName,
                    dob: moment(patient.dob).format("YYYY-MM-DD"),
                    gender: patient.gender,
                    phone: patient.phone,
                    address: patient.address,
                    city: patient.city,
                    state: patient.state,
                    zip: patient.zip,
                    phoneType: patient.phoneType ?? undefined,
                  },
                }),
              },
            }
          );
          if (createData == null) {
            throw new Error(`Unable to create benefits verification ${errors}`);
          }

          // update the bv using the rest of the form
          const createId = createData.createBenefitsVerification!.id;
          const isPavbluRequest =
            createData.createBenefitsVerification!.type ===
            BenefitsVerificationTypeEnum.Caremetx;
          await updateBenefitsVerification({
            variables: {
              id: createId,
              patch: transformRequestFormToPatch(data),
            },
          });

          // finally submit the request and redirect user to the list page
          if (isPavbluRequest) {
            // TODO: put into modal!
            history.push(
              `${ROUTE_PATHS.BENEFITS_VERIFICATIONS.path}/caremetx-pavblu-request/${createId}`
            );
          } else {
            await submitBenefitsVerification({ variables: { id: createId } });
            alert.success("Full benefits check submitted");
            history.push(
              `${ROUTE_PATHS.BENEFITS_VERIFICATIONS.path}/view/${createId}`
            );
          }
        } catch (err) {
          alert.error(`Error: ${(err as Error).message}`);
        }
      },
      [
        history,
        alert,
        account,
        createBenefitsVerification,
        updateBenefitsVerification,
        submitBenefitsVerification,
        upsertPatient,
        refreshBenefitsCheckLite,
        drugOptionId,
      ]
    );

    if (patientResult.loading) {
      return <CircularProgress />;
    }

    return (
      <Box minWidth={600} data-cy="componentBenefitsVerificationCreatePage">
        <Box borderBottom="1px solid" borderColor="rgba(28, 28, 28, 0.1)">
          <Typography variant="h6" color="text/primary" padding={2}>
            New Benefits Verification
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Box padding={2}>
            {isLiteBv && (
              <BenefitsVerificationRequestFormLite
                defaultValues={defaultValues}
                onSubmit={onSubmit}
                onBack={onBack}
                submitErrors={submitErrors}
              />
            )}
            {isGeneric && (
              <BenefitsVerificationRequestFormFullGeneric
                defaultValues={defaultValues}
                onSubmit={onSubmit}
                onBack={onBack}
              />
            )}
            {isPavblu && (
              <BenefitsVerificationRequestFormPavblu
                defaultValues={defaultValues}
                onSubmit={onSubmit}
                onBack={onBack}
              />
            )}
          </Box>
          <Box width="230px" margin={7}>
            <RightSideMenu activeStep={1} />
          </Box>
        </Box>
      </Box>
    );
  };
