import { ApolloClient, ApolloProvider } from "@apollo/client";
import { useAllServiceCodes } from "@samacare/hooks-data";
import {
  ServiceCodeSelector,
  ServiceCodeSelectorFunctionalProps,
  ServiceCodeSelectorInternalFunctionalProps,
} from "./ServiceCodeSelector";

const ServiceCode: React.FC<ServiceCodeSelectorInternalFunctionalProps> = (
  props,
) => {
  const [{ drugOptions, hcpcsCodes, allServiceCodes }, loading] =
    useAllServiceCodes();

  return (
    <ServiceCodeSelector
      allServiceCodes={allServiceCodes}
      drugOptions={drugOptions}
      hcpcsCodes={hcpcsCodes}
      loading={loading}
      {...props}
    />
  );
};

const WrappedServiceCode: React.FC<ServiceCodeSelectorFunctionalProps> = (
  props,
) => {
  const { apolloClient, ...otherProps } = props;
  return (
    <ApolloProvider client={apolloClient as ApolloClient<object>}>
      <ServiceCode {...otherProps} />
    </ApolloProvider>
  );
};

export default WrappedServiceCode;
