import { Stack } from "@samacare/design";
import { DateField } from "@samacare/form2";

type DatesOfServiceProps = {
  required: boolean;
  disabled: boolean;
  name: string;
};

export const DatesOfService: React.FC<DatesOfServiceProps> = ({
  required,
  disabled,
  name,
}) => (
    <Stack direction="row" spacing={1}>
      <DateField
        name={`${name}.testStartDate`}
        required={required}
        disabled={disabled}
        label="Start date"
        sx={{ flex: 1 }}
        InputLabelProps={{ shrink: true }}
      />
      <DateField
        name={`${name}.testEndDate`}
        required={required}
        disabled={disabled}
        label="End date"
        sx={{ flex: 1 }}
        InputLabelProps={{ shrink: true }}
      />
    </Stack>
  );
