import Autocomplete from "@samacare/design/core/Autocomplete";
import TextField from "@samacare/design/core/TextField";
import useInsuranceTypeOptionsFull from "@samacare/form/hooks/useInsuranceTypeOptionsFull";

interface SupportedPlanTypesProps {
  supportedPlanTypes: string[];
  setSupportedPlanTypes: (states: string[]) => void;
}

export const SupportedPlanTypes: React.FC<SupportedPlanTypesProps> = ({
  supportedPlanTypes,
  setSupportedPlanTypes,
}) => {
  const typeOptions = useInsuranceTypeOptionsFull();

  const handleStateChange = (_: unknown, value: { value: string }[]) => {
    setSupportedPlanTypes(value.map((v) => v.value));
  };

  return (
    <Autocomplete
      disableClearable
      selectOnFocus
      handleHomeEndKeys
      autoHighlight
      onChange={handleStateChange}
      value={typeOptions.filter((s) => supportedPlanTypes.includes(s.value))}
      options={typeOptions}
      multiple
      renderInput={(params) => (
        <TextField
          {...params}
          InputLabelProps={{ shrink: true }}
          label="Plan types"
        />
      )}
    />
  );
};
