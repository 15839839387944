import moment from "moment";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  CircularProgress,
  Stack,
  FormLabel,
  FormHelperText,
} from "@samacare/design/core";
import {
  useForm,
  FormProvider,
  PatientBlock,
  AddressBlock,
  PatientInformationBlock,
  PrescriberBlock,
  OfficeBlock,
  MedicalRequestBlock,
  SignatureBlock,
  PrimaryAndSecondaryInsuranceBlock,
  SiteOfServiceBlockForAssistRx,
  ErrorMessage,
  OfficeContactInfoBlock,
  PhoneField,
  TextField,
} from "@samacare/form";
import Signature from "AuthorizationSharedSteps/SignatureSection";
import ROUTES from "../ROUTE_PATHS";
import {
  MedicationRequestPatient,
  MedicationRequestMedication,
  MedicationRequestLocation,
  MedicationRequestPrescriber,
  QuestionnaireResponseAnswerInputObjectType,
  MedicationRequestDiagnosis,
  QuestionTypeEnumType,
} from "@samacare/graphql";
import { EnrollmentActions } from "./EnrollmentActions";
import {
  useEnrollmentContext,
  useApellisQuestionnaire,
} from "./EnrollmentProvider";
import { EnrollmentSection } from "./EnrollmentSection";
import { LanguageField } from "@samacare/form/LanguageField";
import useUpdateNewEnrollmentById from "../../hooks/useUpdateNewEnrollmentById";
import useCreateNewEnrollment from "../../hooks/useCreateNewEnrollment";
import { EnrollmentRoutes } from "./Enrollment.types";
import {
  QuestionnaireResponseType,
  QuestionnaireField,
} from "../../components/Questionnaire";
import { EnrollmentServiceTypeBlock } from "./EnrollmentServiceTypeBlock";
import { CareGiverInfoBlock } from "./CareGiverInfoBlock";
import { PrescriptionInfoBlock } from "./PrescriptionInfoBlock";
import { EnrollmentConsent } from "./EnrollmentConsent";
import { convertBoolToYesNo, convertYesNoToBool } from "./utils";
import Link from "@samacare/design/core/Link";
import { EnrollmentFinancialInfoComponent } from "./EnrollmentFinancialInfoComponent";

type EnrollmentFormDataType = {
  root: { serverError: string };
  patient: MedicationRequestPatient;
  medications: MedicationRequestMedication[];
  PatientId?: string;
  prescriber: MedicationRequestPrescriber;
  location: MedicationRequestLocation;
  PrescriberId?: string;
  LocationId?: string;
  questionnaire: QuestionnaireResponseType;
  diagnoses: MedicationRequestDiagnosis[];
  selectedSignatureId?: string;
  patientConsent: {
    hasConsent: "yes" | "no" | null;
    fileId?: number | null;
    fileTitle?: string | null;
  };
  serviceType: string;
};

export const EnrollmentApellisForm: React.FC<{
  onNextStep: (stringParam?: string | undefined) => void;
}> = ({ onNextStep }) => {
  const {
    enrollment,
    isSubmitted,
    onAlert,
    onPatientSelected,
    programId,
    enrollmentProgramQueryLoading,
    enrollmentProgram,
  } = useEnrollmentContext();

  const { answers } = useApellisQuestionnaire();

  const parseValidData = (fieldName: string) => {
    if (
      answers?.questionnaire?.[fieldName] === "" ||
      answers?.questionnaire?.[fieldName] == null
    ) {
      return;
    }
    return JSON.parse(answers?.questionnaire?.[fieldName] as string);
  };

  const methods = useForm<EnrollmentFormDataType>({
    defaultValues: {
      patient: enrollment?.MedicationRequest?.patient ?? {},
      medications: enrollment?.MedicationRequest?.medications ?? undefined,
      PatientId: enrollment?.MedicationRequest?.PatientId ?? undefined,
      LocationId: enrollment?.MedicationRequest?.LocationId ?? undefined,
      PrescriberId: enrollment?.MedicationRequest?.PrescriberId ?? undefined,
      location: enrollment?.MedicationRequest?.location ?? {},
      prescriber: enrollment?.MedicationRequest?.prescriber ?? {},
      diagnoses: enrollment?.MedicationRequest?.diagnoses ?? [],
      questionnaire: {
        ...answers?.questionnaire,
        secondaryDiagnoses: parseValidData("secondaryDiagnoses") ?? [],
        BenefitsInvestigation: parseValidData("BenefitsInvestigation"),
        CoPayCardProgram: parseValidData("CoPayCardProgram"),
        PatientAssistanceProgram: parseValidData("PatientAssistanceProgram"),
        PriorAuthorizationAssistance: parseValidData(
          "PriorAuthorizationAssistance"
        ),
        sig: parseValidData("sig"),
      },
      serviceType:
        parseValidData("BenefitsInvestigation") ??
        parseValidData("CoPayCardProgram") ??
        parseValidData("PatientAssistanceProgram") ??
        parseValidData("PriorAuthorizationAssistance") ??
        null,
      selectedSignatureId: enrollment?.SignatureFileId ?? undefined,
      patientConsent: {
        hasConsent: convertBoolToYesNo(enrollment?.PatientConsent?.hasConsent),
        fileId: enrollment?.PatientConsent?.File?.id,
        fileTitle: enrollment?.PatientConsent?.File?.title,
      },
    },
    ...answers,
  });

  const {
    setError,
    setValue,
    formState: { errors },
    register,
  } = methods;

  const [update, { loading: updateErnollmentLoading }] =
    useUpdateNewEnrollmentById();

  useEffect(() => {
    setValue("medications", [
      {
        code: enrollmentProgram?.drugCode ?? "",
        name: enrollmentProgram?.drugName ?? "",
        quantity: null,
      },
    ]);
  }, [enrollmentProgram, setValue]);

  const [createNewEnrollment] = useCreateNewEnrollment();

  const history = useHistory();

  const updateSignature = (id: string) => {
    setValue("selectedSignatureId", id);
  };

  const updateEnrollment = async ({
    enrollmentId,
    data,
  }: {
    enrollmentId: string | undefined;
    data: EnrollmentFormDataType;
  }) => {
    await update({
      variables: {
        id: Number(enrollmentId),
        patch: {
          questionnaire: {
            answers: Object.entries({
              ...data.questionnaire,
              "sama-primaryInsurance-payerNameAndId": `${
                data.questionnaire?.["sama-primaryInsurance-payerId"] ?? ""
              } ${
                data.questionnaire?.[
                  "sama-primaryInsurance-InsuranceCompanyName"
                ] ?? ""
              }`,
              "sama-secondaryInsurance-payerNameAndId": `${
                data.questionnaire?.["sama-secondaryInsurance-payerId"] ?? ""
              } ${
                data.questionnaire?.[
                  "sama-secondaryInsurance-InsuranceCompanyName"
                ] ?? ""
              }`,
              secondaryDiagnoses: JSON.stringify(
                data.questionnaire.secondaryDiagnoses
              ),
              signatureDate: moment(
                data.questionnaire.signatureDate as unknown as string
              ).format("YYYY-MM-DD"),
            }).map(([id, value]) => ({
              id,
              value: value?.toString(),
            })) as QuestionnaireResponseAnswerInputObjectType[],
          },
          MedicationRequest: {
            patient: {
              ...data.patient,
              phone: data.patient.phone === "" ? null : data.patient.phone,
            },
            medications: data.medications,
            prescriber: data.prescriber,
            location: data.location,
            PatientId: data.PatientId,
            LocationId: data.LocationId,
            PrescriberId: data.PrescriberId,
            diagnoses: data.diagnoses,
          },
          SignatureFileId: data.selectedSignatureId,
          PatientConsent: {
            // should always have a value because `hasConsent` is validated
            hasConsent: convertYesNoToBool(data.patientConsent.hasConsent)!,
            fileId: data.patientConsent.fileId,
          },
        },
      },
    });
  };

  const onSubmit = async (data: EnrollmentFormDataType) => {
    let enrollmentId = enrollment?.id;

    try {
      if (enrollment?.id == null) {
        const createEnrollmentResult = await createNewEnrollment({
          variables: {
            EnrollmentProgramId: programId,
          },
        });
        enrollmentId = createEnrollmentResult?.data?.createNewEnrollment?.id;

        history.push(
          `${ROUTES.ENROLLMENTS_CREATE.path}/${EnrollmentRoutes.submission}/${enrollmentId}`
        );
        await updateEnrollment({ enrollmentId, data });
      } else {
        await updateEnrollment({ enrollmentId, data });
        onNextStep();
      }
    } catch (error) {
      setError("root.serverError", {
        type: "400",
      });

      onAlert((error as Error).message);
    }
  };

  return (
    <FormProvider {...methods}>
      {enrollmentProgramQueryLoading && <CircularProgress sx={{ mt: 10 }} />}
      {!enrollmentProgramQueryLoading && (
        <form
          id="EnrollmentApellisForm"
          name="EnrollmentApellisForm"
          onSubmit={methods.handleSubmit(onSubmit)}
          noValidate
        >
          <EnrollmentSection title="Patient">
            <PatientBlock
              onSelected={async (patient) => {
                await onPatientSelected(patient);
              }}
              required
              disabled={isSubmitted}
            />
          </EnrollmentSection>
          <EnrollmentSection title="Patient Information">
            <AddressBlock disabled={isSubmitted} required>
              <PatientInformationBlock
                disabled={isSubmitted}
                PhoneInputProps={{
                  usePhoneType: true,
                  disabled: isSubmitted,
                  PhoneTypeProps: {
                    name: "patient.phoneType",
                    disabled: isSubmitted,
                    required: true,
                  },
                  required: true,
                }}
              />
              <Stack direction="row" spacing={1} maxWidth={600}>
                <TextField
                  name="patient.email"
                  label="Email"
                  disabled={isSubmitted}
                  fullWidth
                  type="email"
                  rules={{
                    validate: (value) => {
                      if (value?.length > 0) {
                        return /\S+@\S+\.\S+/.test(value);
                      }
                      return true;
                    },
                  }}
                />
                <LanguageField
                  name="patient.language"
                  label="Preferred Language"
                  disabled={isSubmitted}
                  fullWidth
                />
              </Stack>
            </AddressBlock>
          </EnrollmentSection>
          <EnrollmentSection title="Service Type">
            <FormLabel required>Select type of services:</FormLabel>
            <EnrollmentServiceTypeBlock isSubmitted={isSubmitted} />
          </EnrollmentSection>
          <EnrollmentSection title="Insurance">
            <PrimaryAndSecondaryInsuranceBlock disabled={isSubmitted} isForApi>
              <EnrollmentFinancialInfoComponent disabled={isSubmitted} />
              <EnrollmentSection title="Pharmacy PBM (Optional)">
                <Stack spacing={2} maxWidth={600}>
                  <QuestionnaireField
                    id="pbmName"
                    text="Name"
                    disabled={isSubmitted}
                  />
                  <QuestionnaireField
                    id="pbmGroupId"
                    text="Group Id"
                    disabled={isSubmitted}
                  />
                  <QuestionnaireField
                    id="pbmBin"
                    text="BIN/PCN"
                    disabled={isSubmitted}
                  />
                  <PhoneField
                    name="questionnaire.pbmPhone"
                    fullWidth
                    disabled={isSubmitted}
                    label="Phone"
                  />
                </Stack>
              </EnrollmentSection>
            </PrimaryAndSecondaryInsuranceBlock>
          </EnrollmentSection>
          <EnrollmentSection title="JCODE/CPT code">
            <MedicalRequestBlock disabled />
          </EnrollmentSection>
          <EnrollmentSection title="Site of Service">
            <SiteOfServiceBlockForAssistRx disabled={isSubmitted} />
          </EnrollmentSection>
          <EnrollmentSection title="Provider Details">
            <PrescriberBlock required disabled={isSubmitted} />
          </EnrollmentSection>
          <EnrollmentSection title="Location Details">
            <OfficeBlock required disabled={isSubmitted} />
          </EnrollmentSection>
          <OfficeContactInfoBlock
            isSubmitted={isSubmitted}
            isRequired
            EmailInputProps={{ required: true }}
            FaxInputProps={{ required: true }}
          />
          <CareGiverInfoBlock isSubmitted={isSubmitted} />
          <EnrollmentSection title="Prescription Information">
            <PrescriptionInfoBlock isSubmitted={isSubmitted} />
          </EnrollmentSection>
          <EnrollmentSection title="Physician Declaration and Authorization">
            <SignatureBlock
              isSubmitted={isSubmitted}
              description="This form may not be processed without the Physician’s Signature and agreements to Declaration on Section 9."
            >
              <QuestionnaireField
                id="signatureType"
                type={QuestionTypeEnumType.Choice}
                disabled={isSubmitted}
                required
                text="Please select the signature type below:"
                answerOption={[
                  { valueString: "Dispense as written" },
                  { valueString: "Drug Substitution allowed" },
                ]}
              />
            </SignatureBlock>
            <Signature
              enrollment={enrollment}
              updateSignature={updateSignature}
              disabled={isSubmitted}
            />
            <input
              type="hidden"
              {...register("selectedSignatureId", {
                required: "Please select a signature",
                setValueAs: (v) => (v === "" ? null : v),
              })}
            />
            <ErrorMessage
              errors={errors}
              name="selectedSignatureId"
              render={({ message }) => (
                <FormHelperText error>{message}</FormHelperText>
              )}
            />
          </EnrollmentSection>
          <EnrollmentConsent
            description={
              <>
                This form may not be processed without the Patient’s Signature
                and agreements to Section 10.1, Section 10.2 & Section 10.3.{" "}
                <Link
                  href={enrollmentProgram?.pdfUrl ?? ""}
                  rel="noopener"
                  target="_blank"
                >
                  View form here
                </Link>
              </>
            }
            noLabelOverride="No, allow patient to be contacted for consent"
          />
          <EnrollmentActions
            disabled={isSubmitted}
            loading={updateErnollmentLoading}
          />
        </form>
      )}
    </FormProvider>
  );
};
