import {
  Button,
  Drawer,
  IconButton,
  Stack,
  Typography,
} from "@samacare/design";
import { useTheme } from "@samacare/design/core/styles";
import EditOutlined from "@samacare/design/core/icons/EditOutlined";
import DeleteOutlined from "@samacare/design/core/icons/DeleteOutlined";
import { LeftRightCenterAll } from "@samacare/component";
import { useConfirmDialog } from "../../../hooks/confirmDialogHook";
import { Maybe } from "@samacare/graphql";

interface DetailDrawerProps {
  open: boolean;
  id: number;
  domain: string;
  onEdit?: () => void;
  onDelete?: (id: number) => Promise<void>;
  onClose: () => void;
  title: string;
  subtitleLabel?: string;
  subtitle?: string | Maybe<string>;
}
export const DetailDrawer: React.FC<DetailDrawerProps> = (props) => {
  const theme = useTheme();

  const [ConfirmDeleteDialog, confirmDialogProps, setConfirmDeleteDialogOpen] =
    useConfirmDialog({
      message: `Delete the ${props.domain} named ${props.title}?`,
      confirm: async () => {
        if (props.onDelete) {
          await props.onDelete(props.id);
          props.onClose();
        }
      },
      zIndexOffset: 100,
    });

  return (
    <Drawer
      open={props.open}
      sx={{ zIndex: 5 }}
      anchor="right"
      onClose={props.onClose}
    >
      <Stack
        direction="column"
        padding={4}
        minWidth={600}
        sx={{ position: "relative" }}
        justifyContent="space-between"
        flexGrow={1}
      >
        <Stack spacing={2}>
          <Stack>
            <Typography variant="h6" color={theme.palette.text.primary}>
              {props.title}
            </Typography>
            {props.subtitle && (
              <Stack direction="row" spacing={1}>
                {props.subtitleLabel && (
                  <Typography variant="caption" color={theme.palette.grey.A700}>
                    {props.subtitleLabel}:
                  </Typography>
                )}
                <Typography
                  variant="caption"
                  color={theme.palette.text.secondary}
                >
                  {props.subtitle}
                </Typography>
              </Stack>
            )}
          </Stack>

          {props.children}

          <Stack
            direction="row"
            spacing={1}
            sx={{
              position: "absolute",
              top: ({ spacing }) => spacing(2),
              right: ({ spacing }) => spacing(1),
            }}
          >
            {props.onEdit && (
              <IconButton onClick={props.onEdit}>
                <EditOutlined sx={{ color: theme.palette.primary.main }} />
              </IconButton>
            )}
            {props.onDelete && (
              <IconButton onClick={() => setConfirmDeleteDialogOpen(true)}>
                <DeleteOutlined sx={{ color: theme.palette.error.main }} />
              </IconButton>
            )}
          </Stack>
        </Stack>
        <LeftRightCenterAll>
          <Button variant="outlined" onClick={props.onClose}>
            Close
          </Button>
        </LeftRightCenterAll>
      </Stack>
      <ConfirmDeleteDialog {...confirmDialogProps} />
    </Drawer>
  );
};

/* The below is from a different design that may be rotated back to after user testing.
Temporarily commenting out to make transition back to old design (if needed) easier.
*/
/* {(props.onEdit || props.onDelete) && (
          <Stack direction="row" spacing={1}>
            {props.onEdit && (
              <Button
                variant="outlined"
                onClick={props.onEdit}
                startIcon={<Edit />}
              >
                Edit
              </Button>
            )}
            {props.onDelete && (
              <Button
                variant="outlined"
                onClick={props.onDelete}
                startIcon={<Delete />}
              >
                Delete
              </Button>
            )}
          </Stack>
        )} */

/* <IconButton
          onClick={props.onClose}
          sx={{
            color: theme.palette.grey.A400,
            position: "absolute",
            top: ({ spacing }) => spacing(2),
            right: ({ spacing }) => spacing(1),
          }}
        >
          <CloseIcon />
        </IconButton> */
