import _ from "lodash";
import { useState, useCallback } from "react";
import * as React from "react";
import { useAlert } from "react-alert";
import Creatable from "react-select/creatable";
import { Box, Flex, PrimaryButton } from "@@ui-kit";
import { Input, Label, Radio } from "@@ui-kit/forms";
import {
  InsuranceCompany,
  withInsuranceCompanies,
  withUpsertInsuranceCompany,
} from "../../../../graphql/InsuranceCompany";
import Modal from "../../../../components/Modal";
import { ModalBodyLarge } from "../../../../components/ModalStyledComponents";
import filterInsuranceCompanyOptions from "../../../../util/filterInsuranceCompanyOptions";
import {
  InsuranceCompanySupportedCodingTypes,
  MutationUpsertInsuranceCompanyArgs,
  InsuranceCompany as InsuranceCompanyOption,
} from "@samacare/graphql";

export type SelectPhoneListProps = React.ComponentProps<typeof SelectPhoneList>;

export const SelectPhoneList: React.VoidFunctionComponent<{
  insuranceCompanies: InsuranceCompany[];
  name?: string;
  onClose: () => void;
  upsertInsuranceCompany: (options: {
    variables: MutationUpsertInsuranceCompanyArgs;
  }) => Promise<void>;
}> = (props) => {
  const { insuranceCompanies, onClose, upsertInsuranceCompany } = props;

  const alert = useAlert();
  const [parentCompany, setParentCompany] = useState<
    (typeof insuranceCompanies)[0] | null
  >(null);
  const [supportedCodingTypes, setSupportedCodingTypes] =
    useState<InsuranceCompanySupportedCodingTypes>(
      InsuranceCompanySupportedCodingTypes.Jcode
    );
  const [name, setName] = useState(props.name);

  const create = useCallback(async () => {
    if (name == null || name === "") {
      alert.error("Missing required field");
      return;
    }

    try {
      await upsertInsuranceCompany({
        variables: {
          name,
          parentCompanyName:
            parentCompany != null && parentCompany.id == null
              ? parentCompany.name
              : null,
          parentId:
            parentCompany != null && parentCompany.id != null
              ? parseInt(parentCompany.id)
              : null,
          searchTags: [],
          supportedCodingTypes: InsuranceCompanySupportedCodingTypes.Jcode,
          supportedPlanTypes: [],
          supportedStates: [],
        },
      });
      alert.success(
        "Created, please associate the newly created company to this form / fax"
      );
      onClose();
    } catch (err) {
      alert.error(`Error: ${(err as Error).message}`);
    }
  }, [alert, name, onClose, parentCompany, upsertInsuranceCompany]);

  return (
    <Modal header="Add Insurance Company" onClick={onClose} open>
      <ModalBodyLarge>
        <Flex flexDirection="column">
          <Box marginBottom="10px">
            <Label>
              Payer / Plan Name
              <Input
                onChange={(e) => {
                  setName(e.target.value);
                }}
                value={name}
              />
            </Label>
          </Box>

          <Box marginBottom="10px">
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <Label>
              Parent Company
              <Creatable
                getOptionLabel={(
                  option: (typeof insuranceCompanies)[0] &
                    Partial<{ name: string; label: string }>
                ) => option.name ?? option.label}
                getOptionValue={(option: (typeof insuranceCompanies)[0]) =>
                  option.id
                }
                onChange={(company) => {
                  setParentCompany(company);
                }}
                options={_.sortBy(insuranceCompanies, "name")}
                placeholder="Choose or create an insurance company"
                filterOption={(option: { data: InsuranceCompany }, value) => {
                  if (!option?.data?.searchTags) return true;
                  return filterInsuranceCompanyOptions(
                    option.data as InsuranceCompanyOption,
                    value
                  );
                }}
                value={parentCompany}
                isClearable
                isSearchable
              />
            </Label>
          </Box>

          <Box marginBottom="10px">
            <Label>
              Supported Coding Types
              <Flex flexDirection="column">
                <Label>
                  <Radio
                    checked={
                      supportedCodingTypes ===
                      InsuranceCompanySupportedCodingTypes.Jcode
                    }
                    onChange={() =>
                      setSupportedCodingTypes(
                        InsuranceCompanySupportedCodingTypes.Jcode
                      )
                    }
                    value={InsuranceCompanySupportedCodingTypes.Jcode}
                  />
                  J-code
                </Label>
                <Label>
                  <Radio
                    checked={
                      supportedCodingTypes ===
                      InsuranceCompanySupportedCodingTypes.Ndc
                    }
                    onChange={() =>
                      setSupportedCodingTypes(
                        InsuranceCompanySupportedCodingTypes.Ndc
                      )
                    }
                    value={InsuranceCompanySupportedCodingTypes.Ndc}
                  />
                  NDC
                </Label>
                <Label>
                  <Radio
                    checked={
                      supportedCodingTypes ===
                      InsuranceCompanySupportedCodingTypes.NdcAndJcode
                    }
                    onChange={() =>
                      setSupportedCodingTypes(
                        InsuranceCompanySupportedCodingTypes.NdcAndJcode
                      )
                    }
                    value={InsuranceCompanySupportedCodingTypes.NdcAndJcode}
                  />
                  NDC + J-code
                </Label>
              </Flex>
            </Label>
          </Box>

          <Box>
            <PrimaryButton fluid onClick={create}>
              Create
            </PrimaryButton>
          </Box>
        </Flex>
      </ModalBodyLarge>
    </Modal>
  );
};

/**
 * @deprecated Use a MUI component instead
 */
export default withUpsertInsuranceCompany(
  withInsuranceCompanies(SelectPhoneList)
);
