import SegmentSelector from "Segment/SegmentSelector";
import { useState, useEffect } from "react";
import useInsuranceTypeOptionsFull, {
  type InsuranceTypeOption,
} from "@samacare/form/hooks/useInsuranceTypeOptionsFull";

interface InsurancePlanTypeProps {
  title: React.ReactNode;
  id: string;
  help: string;
  value: string | null;
  onChange: (value: string | null) => void;
  highlightRequiredFields: boolean;
  supportedPlanTypes?: string[];
}

const filterOptions = (
  options: InsuranceTypeOption[],
  supportedTypes?: string[]
): InsuranceTypeOption[] =>
  supportedTypes && supportedTypes.length > 0
    ? options.filter(({ value }) => supportedTypes.includes(value))
    : options;

const InsurancePlanType: React.FC<InsurancePlanTypeProps> = ({
  id,
  title,
  help,
  value,
  onChange,
  highlightRequiredFields,
  supportedPlanTypes,
}) => {
  const typeOptions = useInsuranceTypeOptionsFull();
  const [selectedValue, setSelectedValue] =
    useState<InsuranceTypeOption | null>(null);

  const filteredOptions = filterOptions(typeOptions, supportedPlanTypes);
  const onlyOneOption = supportedPlanTypes?.length === 1;

  useEffect(() => {
    const selectedOption =
      filteredOptions.find(
        (option: InsuranceTypeOption) => option.value === value
      ) || null;
    setSelectedValue(selectedOption);
  }, [value, supportedPlanTypes]);

  return (
    <SegmentSelector
      id={id}
      title={title}
      help={help}
      required
      allowInTagger
      onChange={(option: InsuranceTypeOption) =>
        onChange(option?.value || null)
      }
      highlight={highlightRequiredFields}
      options={filteredOptions}
      value={selectedValue}
      isNotClearable={onlyOneOption}
    />
  );
};

export default InsurancePlanType;
