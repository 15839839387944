import { Stack } from "@samacare/design/core";
import { PhoneField, TextField } from "@samacare/form";

export type HorizonIpaBlockProps = {
  isDisabled?: boolean;
};

export const HorizonIpaBlock: React.FC<HorizonIpaBlockProps> = ({
  isDisabled = false,
}) => {
  return (
    <Stack spacing={2} maxWidth={600}>
      <TextField
        size="small"
        name="questionnaire.sama-primaryInsurance-IpaGroupName"
        label="IPA / Medical group Name"
        fullWidth
        disabled={isDisabled}
      />
      <PhoneField
        name="questionnaire.sama-primaryInsurance-IpaGroupPhoneNumber"
        label="IPA / Medical group Phone Number"
        fullWidth
        disabled={isDisabled}
      />
    </Stack>
  );
};
