import * as React from "react";
import gql from "graphql-tag";
import styled from "styled-components";
import { useQuery } from "@apollo/client";
import _ from "lodash";
import {
  Flex,
  PrimaryButton,
  DangerButton,
  Text,
  Link,
  RouterLink,
} from "@@ui-kit";
import {
  AuthorizationStepKey,
  Authorization,
  Account,
  AuthorizationType,
} from "@samacare/graphql";

import {
  SupportInstitutionQuery,
  SupportInstitutionQueryVariables,
} from "@@generated/graphql";

import { Form } from "./interfaces";
import { useSelector } from "../../../configureStore";
import AuthorizationReminderButton from "../../AuthorizationReminderButton";
import DeleteAuthorizationButton from "../../DeleteAuthorizationButton";
import DownloadFormsButton from "../../DownloadFormsButton";
import DownloadPortalAttachmentsButton from "../ContextPane/DownloadPortalAttachmentsButton";
import UploadToEmr from "../ContextPane/UploadToEmr";
import { useConfig, useTheme } from "../../../hooks";
import ROUTE_PATHS from "../../../routes/ROUTE_PATHS";
import * as stepUtils from "../../../util/stepUtils";
import { LeftRightCenterV } from "../../LeftRight";
import { EmailToggle } from "./EmailToggle";
import { Stack, Box, Button, Tooltip } from "@samacare/design/core";
import moment from "moment";
import {
  getAuthDescription,
  getInsuranceLabel,
  NO_ASSOCIATED_INSURANCE_LABEL,
} from "../../../util/authUtils";
import { TagsModal } from "../../../routes/PCAuthorizations/PatientTile/TagsModal";
import { TagsList } from "../TagsList";
import VisibilityIcon from "@samacare/design/core/icons/Visibility";
import EditIcon from "@samacare/design/core/icons/Edit";
import { RequestStatusButton } from "../../../routes/PCAuthorizations/PatientTile/RequestStatusButton";
import AuthorizationNotes from "../../MainList/AuthorizationNotes";
import { AuthorizationAssigneeSelect } from "../AssigneeSelect";
import { ActionBanners } from "../ActionBanners";
import { LastChecked } from "../LastChecked";
import ViewOnPortalLink from "../../ViewOnPortalLink";
import { useHasEmrIntegration } from "@@hooks/useHasEmrIntegrations";

interface ContainerProps {
  isNewSimplifiedUx?: boolean;
}

const Container = styled(Flex)<ContainerProps>`
  border: 1px solid ${(props) => props.theme.lightGray};
  border-radius: 3px;
  padding: 15px;
  width: ${(props) => (props.isNewSimplifiedUx ? "1100px" : "100%")};
  flex-direction: column;
  min-width: 1100px;
`;

const PatientContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
`;

const ButtonRowContainer = styled(LeftRightCenterV)`
  gap: 8px;
  flex-wrap: wrap;
  justify-content: flex-end;
`;

const SmallPrimaryButton = styled(PrimaryButton)`
  padding: 7px 10px;
`;

export const institutionsQuery = gql`
  query supportInstitution {
    institutions: institutionsFindAll {
      id
      name
    }
  }
`;

export type ContextHeaderProps = React.ComponentProps<typeof ContextHeader>;

export const ContextHeader: React.VoidFunctionComponent<{
  authorization: Authorization;
  account: Account;
  backToAuthorizations: () => void;
  form?: Form | null;
  handleFaxNumberUpdate?: () => void | undefined;
  hideDownloadButton: boolean;
  preventFaxNumberUpdate?: boolean;
  saveChanges?: () => void | null;
  toggleFormModal?: () => void | null;
  triggerReinputLogin?: () => void | null;
  isDisabled?: boolean;
  isNewSimplifiedUx?: boolean;
}> = ({
  authorization,
  account,
  backToAuthorizations,
  form = null,
  handleFaxNumberUpdate = undefined,
  hideDownloadButton = false,
  preventFaxNumberUpdate = null,
  saveChanges = null,
  toggleFormModal = null,
  triggerReinputLogin = null,
  isNewSimplifiedUx,
}) => {
  const config = useConfig();
  const theme = useTheme();
  const currentStep = useSelector((state) => state.form.currentStep);

  const [isShowTagsModal, setIsShowTagsModal] = React.useState(false);

  const { data, error, loading } = useQuery<
    SupportInstitutionQuery,
    SupportInstitutionQueryVariables
  >(institutionsQuery);

  const {
    hasUploadToModernizingMedicine,
    hasUploadToNextGen,
    hasUploadToOncoEmr,
    hasUploadToRedox,
  } = useHasEmrIntegration(authorization.InstitutionId!);

  if (account == null || loading) {
    return <div aria-label="Loading">Loading...</div>;
  }

  if (error) {
    return <div aria-label="Error">Failed to load institutions.</div>;
  }

  const patientName =
    authorization.patient != null
      ? `${authorization.patient.firstName} ${authorization.patient.lastName}`
      : "No patient selected";
  const patientDob = patientName && authorization.patient?.dob;
  const formattedDob = patientDob && moment(patientDob).format("MM/DD/YYYY");
  const isSamaUser = account != null && account.isSamaUser === true;
  const isPresubmit = authorization.submittedAt === null;
  const isPortalAuth =
    authorization.type === AuthorizationType.Portal ||
    authorization.type === AuthorizationType.PortalCopilot;
  const isFirstStep = _.first(authorization.steps)?.number === currentStep;
  const isResponseStep =
    stepUtils.isStep(
      authorization.steps,
      currentStep,
      AuthorizationStepKey.Summary
    ) || authorization.type === AuthorizationType.PortalCopilot;
  const isFormAuth =
    authorization.formId != null && authorization.isReferral === false;
  const hasSubmissionPhoneNumber = authorization.submissionPhoneNumber != null;
  const showChangeForm =
    [
      AuthorizationStepKey.RequestDetails,
      AuthorizationStepKey.PrescriberAndLocationDetails,
      AuthorizationStepKey.FormDetails,
    ].includes(
      stepUtils.getStepByNumber(authorization.steps, currentStep).key!
    ) &&
    form &&
    form.type !== config.CONSTANTS.AUTHORIZATION_FORM_TYPE.MANUALLY_TAGGED_FORM;

  const isReviewSignSubmitStep = stepUtils.isStep(
    authorization.steps,
    currentStep,
    AuthorizationStepKey.ReviewSignSubmit
  );
  const isExternalAuth =
    authorization.type === config.CONSTANTS.AUTHORIZATION_TYPES.EXTERNAL.value;

  const isReferral = authorization.isReferral === true;

  const { institutions = [] } = data ?? {};
  const institution = institutions.find(
    (inst) => inst.id === authorization.InstitutionId
  );

  if (institution == null) {
    return (
      <div aria-label="Error">
        Failed to load institution for authorization.
      </div>
    );
  }

  const insuranceLabel = getInsuranceLabel(authorization);
  // If there is no insurance we are on the first step and the whole section can be skipped
  const isShowInsuranceAndDrugBlock =
    insuranceLabel !== NO_ASSOCIATED_INSURANCE_LABEL;
  const hasTags = authorization.tags.length > 0;
  const authDesc = getAuthDescription(authorization);

  return (
    <Container isNewSimplifiedUx={isNewSimplifiedUx}>
      <Stack flexDirection="row" justifyContent="space-between">
        <PatientContainer>
          <Text size="18px" weight={700}>
            {`${patientName}${formattedDob ? ` (${formattedDob})` : ""}`}
          </Text>
          {isSamaUser && (
            <Text
              size="14px"
              weight={700}
              color={theme.darkGray}
              style={{ marginLeft: "24px" }}
            >
              {`Request for ${institution.name}`}
            </Text>
          )}
        </PatientContainer>
        <ButtonRowContainer>
          <EmailToggle />
          {isSamaUser && (
            <Tooltip title="Sama: Search for FullStory recordings that relate to this authorization">
              <RouterLink
                to={`${ROUTE_PATHS.STORY_PLAYER.path}?authorizationId=${authorization.id}`}
              >
                <SmallPrimaryButton inverted>Recordings</SmallPrimaryButton>
              </RouterLink>
            </Tooltip>
          )}
          {showChangeForm !== false &&
            showChangeForm !== null &&
            isFormAuth &&
            !isExternalAuth &&
            toggleFormModal != null && (
              <Tooltip title="Change the selected form to use for this Auth, this can also be used to change the insurance company or drug">
                <SmallPrimaryButton inverted onClick={toggleFormModal}>
                  Change Form..
                </SmallPrimaryButton>
              </Tooltip>
            )}
          {isPresubmit &&
            !isPortalAuth &&
            saveChanges != null &&
            !isFirstStep && (
              // We want to avoid passing the event object from onClick to save changes
              <Tooltip title="This will save your progress on the PA and leave it in 'Draft' status so that you can submit it later">
                <SmallPrimaryButton
                  inverted
                  onClick={() => {
                    saveChanges();
                  }}
                >
                  Save
                </SmallPrimaryButton>
              </Tooltip>
            )}
          {isResponseStep &&
            (hasUploadToOncoEmr ||
              hasUploadToModernizingMedicine ||
              hasUploadToNextGen ||
              hasUploadToRedox) && (
              <UploadToEmr
                authorization={authorization}
                hasUploadToOncoEmr={hasUploadToOncoEmr}
                hasUploadToModernizingMedicine={hasUploadToModernizingMedicine}
                hasUploadToNextGen={hasUploadToNextGen}
                hasUploadToRedox={hasUploadToRedox}
              >
                <SmallPrimaryButton inverted>EMR Upload</SmallPrimaryButton>
              </UploadToEmr>
            )}
          {/* Super hacky, but only enabling this for OC of Houston */}
          {!isPresubmit &&
            isPortalAuth &&
            authorization.InstitutionId === "17" && (
              <DownloadPortalAttachmentsButton authorization={authorization}>
                <SmallPrimaryButton inverted>
                  Download Attachments
                </SmallPrimaryButton>
              </DownloadPortalAttachmentsButton>
            )}
          {!isPresubmit && !hideDownloadButton && (
            <DownloadFormsButton authorization={authorization}>
              <SmallPrimaryButton inverted>
                Download Response Files
              </SmallPrimaryButton>
            </DownloadFormsButton>
          )}
          {triggerReinputLogin != null && (
            <SmallPrimaryButton inverted onClick={triggerReinputLogin}>
              Reinput Credentials
            </SmallPrimaryButton>
          )}
          {isReviewSignSubmitStep && (
            <AuthorizationReminderButton authorization={authorization}>
              <SmallPrimaryButton inverted>Reminder</SmallPrimaryButton>
            </AuthorizationReminderButton>
          )}
          <Tooltip title="Add / Remove tags on this PA">
            <SmallPrimaryButton
              inverted
              onClick={() => setIsShowTagsModal(true)}
            >
              Tags..
            </SmallPrimaryButton>
          </Tooltip>
          {isPresubmit && (
            <DeleteAuthorizationButton
              isReferral={authorization.isReferral}
              id={authorization.id}
              step={authorization.formDetails.currentStep as number}
              portalKey={authorization.portalKey}
              authorization={authorization}
              onDelete={() => {
                backToAuthorizations();
              }}
            >
              <DangerButton inverted style={{ padding: "7px 10px" }}>
                Delete..
              </DangerButton>
            </DeleteAuthorizationButton>
          )}
          <AuthorizationAssigneeSelect authorization={authorization} />
        </ButtonRowContainer>
      </Stack>

      {/* Second Row */}
      {(isShowInsuranceAndDrugBlock || hasTags) && (
        <Stack
          justifyContent="space-between"
          direction="row"
          alignItems="center"
          mt={1}
        >
          <Stack direction="row" alignItems="center">
            {isShowInsuranceAndDrugBlock && (
              <>
                {authDesc && (
                  <Box mr={3}>{getAuthDescription(authorization)}</Box>
                )}
                <Box>{getInsuranceLabel(authorization)}</Box>
                {isPortalAuth && (
                  <ViewOnPortalLink authorization={authorization} />
                )}
                {!isPortalAuth && isReferral && <Text>Referral request</Text>}
                {!isPortalAuth && !isReferral && (
                  <Stack ml={3} direction="row" alignItems="center">
                    {form != null ? (
                      <Button startIcon={<VisibilityIcon />}>
                        <Link
                          href={form.pdfURL}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ textDecoration: "none" }}
                        >
                          {form.type ===
                          config.CONSTANTS.AUTHORIZATION_FORM_TYPE
                            .MANUALLY_TAGGED_FORM
                            ? `View Manually Uploaded Form`
                            : `View ${form.title}`}
                        </Link>
                      </Button>
                    ) : (
                      <Box>Form not selected</Box>
                    )}
                    <div style={{ margin: "0 5px" }}>|</div>
                    {hasSubmissionPhoneNumber ? (
                      <Button
                        startIcon={<EditIcon />}
                        disabled={
                          preventFaxNumberUpdate != null &&
                          preventFaxNumberUpdate
                        }
                        onClick={handleFaxNumberUpdate}
                      >
                        {authorization.submissionPhoneNumber}
                      </Button>
                    ) : (
                      <Text>Number not selected</Text>
                    )}
                  </Stack>
                )}
              </>
            )}
          </Stack>
          <TagsList
            auth={authorization}
            onClick={() => setIsShowTagsModal(true)}
          />
        </Stack>
      )}

      {/* Third Row */}
      {isResponseStep && (
        <Stack direction="row" mt={1} justifyContent="space-between">
          <Stack direction="row" alignItems="center" gap={1}>
            <RequestStatusButton
              authorization={authorization}
              tooltipPlace="top-start"
              layoutDirection="row"
            />
            <LastChecked authorization={authorization} />
          </Stack>
          <Stack direction="row" sx={{ flexGrow: 1 }} justifyContent="flex-end">
            <Box sx={{ width: "600px", maxWidth: "600px" }}>
              <AuthorizationNotes
                authorizationId={authorization.id}
                latestNote={authorization.latestNote}
              />
            </Box>
          </Stack>
        </Stack>
      )}

      {/* Rows Four + */}
      {isResponseStep && (
        <ActionBanners authorization={authorization} currentAccount={account} />
      )}

      {isShowTagsModal && (
        <TagsModal
          auth={authorization}
          onClose={() => setIsShowTagsModal(false)}
        />
      )}
    </Container>
  );
};
