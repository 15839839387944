import React from "react";

import { LoadingButton, Tooltip } from "@samacare/design/core";
import DownloadIcon from "@samacare/design/core/icons/Download";
import DownloadDoneIcon from "@samacare/design/core/icons/DownloadDone";

export const CareMetxDownloadPdf: React.FC = () => {
  const [downloaded, setDownloaded] = React.useState(false);
  return (
    <Tooltip title={downloaded ? "Downloaded!" : "Download as PDF"}>
      <LoadingButton
        size="small"
        sx={{ minWidth: "auto" }}
        color={downloaded ? "success" : "primary"}
        variant="outlined"
        onClick={async () => {
          const printButton: HTMLElement | null | undefined = document
            .querySelector("iframe")
            ?.contentDocument?.querySelector("#cmx--btnPrintPage");
          if (printButton != null) {
            printButton.click();
            setDownloaded(true);
          }
        }}
      >
        {downloaded ? <DownloadDoneIcon /> : <DownloadIcon />}
      </LoadingButton>
    </Tooltip>
  );
};
