import { useGenderOptions } from "../hooks/useGenderOptions";
import {
  useFormContext,
  AutocompleteField,
  type AutocompleteFieldProps,
} from "@samacare/form";

export type GenderFieldProps = Omit<
  AutocompleteFieldProps<string, false, true, false>,
  "options"
> & {
  femaleandmaleonly?: boolean;
};

export const GenderField: React.FC<GenderFieldProps> = (props) => {
  const { femaleandmaleonly, ...restProps } = props;
  const { watch } = useFormContext();

  const genders = useGenderOptions();
  const femaleAndMaleOnlyGenders = genders.filter(
    (gender) => gender === "Male" || gender === "Female"
  );

  return (
    <AutocompleteField<string, false, true, false>
      disableClearable
      options={femaleandmaleonly ? femaleAndMaleOnlyGenders : genders}
      filterSelectedOptions
      label="Gender"
      {...restProps}
      value={watch(props.name) ? watch(props.name) : ""}
    />
  );
};
