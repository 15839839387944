import gql from "graphql-tag";
import { PureComponent, useContext } from "react";
import { Flex, PrimaryButton } from "@@ui-kit";
import { BaseButton } from "@@ui-kit/BaseButton";
import styled from "styled-components";
import _ from "lodash";
import colors from "Resources/colors";
import { withApollo } from "@apollo/client/react/hoc";
import { compose } from "recompose";
import { withAlert } from "react-alert";
import { MdCheckCircle as SuccessIcon } from "@react-icons/all-files/md/MdCheckCircle";

import { Warning } from "@@ui-kit/Icons";
import { MfaSetupModalBody } from "../MfaSetupModal";
import { doesHubRequireMFA } from "../../util/portalUtils";
import { useHubs } from "../../hooks";
import { withCurrentAccount } from "../../graphql/Account";
import { WhiteButton } from "@@ui-kit/WhiteButton";

import Modal from "../Modal";
import Carousel from "../Carousel";
import { ModalContent as BaseModalContent } from "../ModalStyledComponents";
import { LeftRightCenterV } from "@@components/LeftRight";
import { getPortalPreview } from "../../graphql/Portal";
import { RequestNewPortalIntegration } from "./RequestNewPortalIntegration";
import { WebExtensionContext } from "../../contexts/webExtension";
import { BaseText } from "Segment/StyledComponents";
import { getAllPortalsForInstitution } from "../../util/getAllPortalsForInstitution";
import { DownloadExtensionDialog } from "./components/DownloadExtensionDialog";

const baseWidth = 750;
const imageMargin = 10;

const SimpleButton = styled(BaseButton)`
  color: ${colors.text.primary};
  border-color: ${colors.lightGray};
  background-color: #fff;
  font-size: 14px;

  &:hover {
    color: #fff;
    border-color: ${colors.darkGray};
    background-color: ${colors.darkGray};
  }
`;

const ModalBody = styled.div`
  width: ${baseWidth + imageMargin * 2}px;
`;

const ModalFooter = styled.div`
  padding: 6px 0;
`;

const NewModalFooter = styled(LeftRightCenterV)`
  justify-content: space-between;
  padding: 12px 0 6px;
`;

const ModalContent = styled(BaseModalContent)`
  padding: 10px 0;
`;

const ModalText = styled.div`
  padding-bottom: 10px;
  text-align: left;
  color: ${(props) => (props.warn ? props.theme.red : "")};
`;

const WebExtensionNoticeContainer = styled.div`
  margin-bottom: 13px;
  text-align: left;
`;

const WebExtensionNoticeContent = styled.div`
  display: flex;
  align-items: center;
  line-height: 20px;
  margin-bottom: 13px;
`;

const PortalSelection = styled(LeftRightCenterV)`
  padding: 8px 8px;

  &:hover {
    cursor: pointer;
    background-color: ${colors.lightGray};
  }
`;

const ScrollContainer = styled.div`
  max-height: 400px;
  overflow: scroll;
  border: 1px solid ${colors.darkGrey};
  border-radius: 5px;
`;

const IconWrapper = styled.div`
  position: relative;
  min-width: 24px;
  height: 24px;
`;

const InnerIconWrapper = styled.div`
  position: absolute;
  top: -2px;
`;

const BtnsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
`;

const SetupLaterText = styled.div`
  color: ${colors.purple};
  margin-right: 24px;
  cursor: pointer;
  &:hover {
    cursor: pointer;
    font-weight: bold;
  }
`;

const { SITE_DOMAIN, SITE_PROTOCOL, NODE_ENV, CONSTANTS } = CONFIG;
const {
  UHC_PORTAL_KEYS,
  NAVINET_PORTAL_KEYS,
  AVAILITY_PORTAL_KEYS,
  EVICORE_PORTAL_KEYS,
  WEB_EXTENSION_PORTAL_KEYS,
  AVAILITY_API_TRACKING_PORTAL_KEYS,
} = CONSTANTS;
const urlRoot = `${SITE_PROTOCOL}${SITE_DOMAIN}${
  NODE_ENV === "dev" ? ":8080" : ""
}`;

const ALL_UHC_KEY = "ALL_UHC";
const ALL_NAVINET_KEY = "ALL_NAVINET";
const ALL_AVAILITY_KEY = "ALL_AVAILITY_KEY";
const ALL_EVICORE_KEY = "ALL_EVICORE_KEY";

const WarningIcon = (props) => (
  <IconWrapper style={props.style}>
    <InnerIconWrapper>
      <Warning color={colors.warningOrange} size="24px" />
    </InnerIconWrapper>
  </IconWrapper>
);

const addGroupedPortalToSingularPortals = (portals, groupKeys, key, title) => {
  const adjPortalList = _.reject(portals, (portal) =>
    _.includes(groupKeys, portal.key)
  );
  const groupedPortal = {
    title,
    key,
  };

  adjPortalList.push(groupedPortal);

  return adjPortalList;
};

const GROUPED_PORTAL_CONFIG = {
  [ALL_UHC_KEY]: {
    keys: UHC_PORTAL_KEYS,
    title: "United HealthCare / One HealthCare ID",
  },
  [ALL_NAVINET_KEY]: { keys: NAVINET_PORTAL_KEYS, title: "Navinet" },
  [ALL_AVAILITY_KEY]: { keys: AVAILITY_PORTAL_KEYS, title: "Availity" },
  [ALL_EVICORE_KEY]: { keys: EVICORE_PORTAL_KEYS, title: "Evicore" },
};

const PortalType = {
  LIVE_VIEWER: "LIVE_VIEWER",
  WEB_EXTENSION: "WEB_EXTENSION",
  AVAILITY_API: "AVAILITY_API",
};

export const reportMissingModalMutation = gql`
  mutation requestNewPortalIntegration(
    $insuranceCompany: String!
    $portalUrl: String!
    $usageFrequency: String!
  ) {
    requestNewPortalIntegration(
      input: {
        insuranceCompany: $insuranceCompany
        portalUrl: $portalUrl
        usageFrequency: $usageFrequency
      }
    ) {
      success
    }
  }
`;

const PortalList = ({
  onSelect,
  onSelectPortalMissing,
  portalsToRender,
  selectedGroupKey,
  onBackClick,
  portalSearchText,
  onSearch,
}) => {
  return (
    <div>
      <ModalBody>
        <ModalContent>
          <ModalText>Select portal to proceed.</ModalText>
          <ScrollContainer>
            <BaseText
              style={{
                width: "98%",
                margin: "10px 5px",
                borderRadius: "4px",
                height: "38px",
                borderColor: "hsl(0, 0%, 80%)",
              }}
              data-cy="fieldSearchPortalByName"
              value={portalSearchText}
              onChange={(e) => {
                onSearch(e.target.value);
              }}
              placeholder="Search by Portal Name"
            />
            {_.map(portalsToRender, (portal) => {
              return (
                <PortalSelection
                  data-cy={`actionSelectPortal_${portal.key}`}
                  onClick={() => onSelect(portal)}
                  key={`integratedPortalSelector_${portal.key}${
                    portal.portalType === PortalType.WEB_EXTENSION ? "_we" : ""
                  }`}
                >
                  <div>{portal.title}</div>
                </PortalSelection>
              );
            })}
          </ScrollContainer>
        </ModalContent>
        <ModalFooter>
          {selectedGroupKey.length > 0 && (
            <SimpleButton
              data-cy="actionPortalSelectionBack"
              onClick={onBackClick}
              style={{ marginRight: "8px" }}
            >
              Back
            </SimpleButton>
          )}
          <SimpleButton
            data-cy="actionReportMissingPortal"
            // inverted
            onClick={onSelectPortalMissing}
          >
            Don&apos;t See Your Portal?
          </SimpleButton>
        </ModalFooter>
      </ModalBody>
    </div>
  );
};

const PortalPreview = ({ onCancel, onConfirm, previewLinks }) => (
  <div>
    <ModalBody>
      <ModalContent>
        <ModalText>Please confirm this is the correct portal</ModalText>
        <ModalText
          style={{ display: "flex", alignItems: "center", marginBottom: "4px" }}
        >
          <WarningIcon style={{ marginRight: "8px" }} />
          <div>
            Alert: working directly on a payer website while logged into that
            website through SamaCare may cause unexpected errors
          </div>
        </ModalText>
        <Carousel width={baseWidth} imageLinks={previewLinks} />
      </ModalContent>
      <NewModalFooter>
        <SimpleButton
          data-cy="actionPortalSelectionConfirmBack"
          onClick={onCancel}
        >
          Back
        </SimpleButton>
        <PrimaryButton
          data-cy="actionPortalSelectionConfirmCorrect"
          onClick={onConfirm}
        >
          This is the correct portal
        </PrimaryButton>
      </NewModalFooter>
    </ModalBody>
  </div>
);

const WebPortalPreview = ({
  onCancel,
  onConfirm,
  previewLinks,
  currentAccount,
  selectedPortal,
}) => {
  const { isWebExtensionConnected } = useContext(WebExtensionContext);

  const handleWebExtensionClick = () => {
    window.open(CONFIG.CONSTANTS.WEB_EXTENSION_URL);
  };

  const { hubByPortalKey } = useHubs(currentAccount.institution);

  return (
    <div>
      <ModalBody>
        <ModalContent>
          <ModalText style={{ paddingBottom: "7px" }}>
            Submit with SamaCare extension
          </ModalText>
          <WebExtensionNoticeContainer>
            <WebExtensionNoticeContent>
              {isWebExtensionConnected ? (
                <>
                  <SuccessIcon
                    style={{ color: "green", marginRight: "8px" }}
                    size="24px"
                  />
                  Your extension is ready to go!
                </>
              ) : (
                <>
                  <WarningIcon style={{ marginRight: "8px" }} />
                  Looks like you haven’t installed our extension yet. Follow our
                  guide to get started! Come back to proceed after you have
                  installed it.
                </>
              )}
            </WebExtensionNoticeContent>
            {!isWebExtensionConnected && (
              <PrimaryButton
                data-cy="actionExtensionWarningInstall"
                onClick={handleWebExtensionClick}
              >
                SamaCare Web Extension
              </PrimaryButton>
            )}
          </WebExtensionNoticeContainer>
          <Carousel width={baseWidth} imageLinks={previewLinks} />
        </ModalContent>
        <NewModalFooter>
          <SimpleButton data-cy="actionExtensionWarningBack" onClick={onCancel}>
            Back
          </SimpleButton>
          <PrimaryButton
            data-cy="actionExtensionWarningProceed"
            disabled={!isWebExtensionConnected}
            onClick={() =>
              isWebExtensionConnected
                ? onConfirm(hubByPortalKey?.[selectedPortal.key])
                : handleWebExtensionClick()
            }
          >
            Proceed
          </PrimaryButton>
        </NewModalFooter>
      </ModalBody>
    </div>
  );
};

const NewPortalWarning = ({ selectedPortal, onSelect }) => (
  <div>
    <ModalBody>
      <ModalContent>
        <ModalText>
          We have recently launched this payer portal integration. During this
          time, we are keeping a close eye on it to make sure errors are
          minimal. If you experience an error during your authorization, we will
          be sure to fix it within 48 hours.
        </ModalText>
      </ModalContent>
      <ModalFooter>
        <Flex width={1} paddingX="50px">
          <PrimaryButton
            inverted
            fluid
            onClick={() => {
              onSelect(selectedPortal);
            }}
          >
            Ok, I understand
          </PrimaryButton>
        </Flex>
      </ModalFooter>
    </ModalBody>
  </div>
);

export class BasePortalSelectionModal extends PureComponent {
  state = {
    groupedPortals: {},
    isNewPortal: false,
    previewLinks: [],
    selectedGroupKey: "",
    selectedPortal: null,
    singularPortals: [],
    isReportingMissingPortal: false,
    isMfaPaneShown: false,
    portalSearchText: "",
    showDownloadExtensionDialog: false,
  };

  componentDidMount() {
    const {
      institution,
      isWebExtensionConnected,
      enableWebExtensionSidebarV2,
    } = this.props;
    if (!isWebExtensionConnected && enableWebExtensionSidebarV2) {
      this.setState({ showDownloadExtensionDialog: true });
    }

    const getPortalType = (portalKey) => {
      if (WEB_EXTENSION_PORTAL_KEYS.includes(portalKey)) {
        return PortalType.WEB_EXTENSION;
      }
      return PortalType.LIVE_VIEWER;
    };

    const portals = getAllPortalsForInstitution(institution).map((portal) => ({
      ...portal,
      portalType: getPortalType(portal.key),
    }));

    let singularPortals = portals;
    const groupedPortals = {};

    _.each(GROUPED_PORTAL_CONFIG, (value, key) => {
      const groupPortals = () => {
        const grouped = _.sortBy(
          _.filter(portals, (portal) => _.includes(value.keys, portal.key)),
          "title"
        );
        if (grouped.length > 1) {
          singularPortals = addGroupedPortalToSingularPortals(
            singularPortals,
            value.keys,
            key,
            value.title
          );
          groupedPortals[key] = grouped;
        }
      };

      groupPortals();
    });

    singularPortals = _.sortBy(singularPortals, (portal) =>
      portal.title.toLowerCase()
    );

    this.setState({ singularPortals, groupedPortals });
  }

  handlePortalMessageLogic = () => {
    const { onSelect } = this.props;
    const { selectedPortal } = this.state;

    if (
      selectedPortal.portalType === PortalType.LIVE_VIEWER &&
      selectedPortal.totalAuthCount != null &&
      selectedPortal.totalAuthCount <= 10
    ) {
      this.setState({ isNewPortal: true });
    } else {
      onSelect(selectedPortal);
    }
  };

  onClick = async (portal) => {
    const { client, alert } = this.props;

    try {
      const response = await client.query({
        query: getPortalPreview,
        variables: { key: portal.key },
      });
      const previewFileNames = _.get(response, "data.getPortalPreview");
      if (previewFileNames.length) {
        const previewLinks = _.map(
          previewFileNames,
          (name) => `${urlRoot}/assets/portal-previews/${portal.key}/${name}`
        );
        this.setState({ previewLinks, selectedPortal: portal });
      } else {
        this.setState({ selectedPortal: portal }, async () => {
          this.handlePortalMessageLogic();
        });
      }
    } catch (e) {
      alert.error(
        "There was an error selecting your portal, please contact SamaCare using the chat button at the bottom"
      );
    }
  };

  handlePostPortalPreviewSelection = () => {
    this.setState({ previewLinks: [] }, this.handlePortalMessageLogic);
  };

  handleWebExtensionProceedClick = (selectedHub) => {
    const { account } = this.props;
    if (
      doesHubRequireMFA(account, selectedHub) &&
      !_.includes(account?.mfaConfiguredHubKeys, selectedHub.hubKey) &&
      !_.includes(AVAILITY_API_TRACKING_PORTAL_KEYS, selectedHub.portalKey)
    ) {
      this.setState({ isMfaPaneShown: true });
    } else this.handlePostPortalPreviewSelection();
  };

  handleCancel = () => {
    this.setState({ previewLinks: [], selectedPortal: null });
  };

  handleMfaBackClick = () => {
    this.setState({ isMfaPaneShown: false });
  };

  render() {
    const {
      alert,
      closeModal,
      disabledPortals,
      onSelect,
      open,
      client,
      account,
      enableWebExtensionSidebarV2,
    } = this.props;
    const {
      selectedPortal,
      singularPortals,
      groupedPortals,
      previewLinks,
      selectedGroupKey,
      isNewPortal,
      isReportingMissingPortal,
      isMfaPaneShown,
      portalSearchText,
      showDownloadExtensionDialog,
    } = this.state;
    const hasPreview = !_.isEmpty(previewLinks);

    const portalsToRender = selectedGroupKey
      ? groupedPortals[selectedGroupKey]
      : singularPortals;

    const filteredOptions = _.filter(
      portalsToRender,
      ({ title, key }) =>
        _.includes(title.toUpperCase(), portalSearchText.toUpperCase()) ||
        _.includes(key.toUpperCase(), portalSearchText.toUpperCase())
    );

    let screen;
    let modalTitle = "Portal Submission";
    switch (true) {
      case isReportingMissingPortal:
        screen = "report_missing_portal";
        modalTitle = "Report Missing Modal";
        break;
      case showDownloadExtensionDialog && enableWebExtensionSidebarV2:
        screen = "download_extension";
        break;
      case !hasPreview && !isNewPortal:
        screen = "portal_list";
        break;
      case hasPreview && !isMfaPaneShown:
        screen = "portal_preview";
        modalTitle = selectedPortal.insuranceCompanyName;
        break;
      case isNewPortal:
        screen = "new_portal_warning";
        break;
      case isMfaPaneShown:
        screen = "mfa_setup";
        break;
      default:
        throw new Error("Expected to render modal contents, but found none");
    }

    return (
      <Modal
        id="pendo_portalSelectionModal"
        header={modalTitle}
        open={open}
        onClick={closeModal}
        headerStyleOverride={{ textAlign: "left" }}
      >
        {screen === "download_extension" && (
          <DownloadExtensionDialog
            open={showDownloadExtensionDialog}
            onClose={closeModal}
            webExtensionUrl={CONFIG.CONSTANTS.WEB_EXTENSION_URL}
          />
        )}
        {screen === "portal_list" && (
          <PortalList
            closeModal={closeModal}
            disabledPortals={disabledPortals}
            onBackClick={() => this.setState({ selectedGroupKey: "" })}
            onSelect={(portal) => {
              if (_.includes(_.keys(groupedPortals), portal.key)) {
                this.setState({ selectedGroupKey: portal.key });
              } else {
                this.onClick(portal);
              }
            }}
            onSelectPortalMissing={() =>
              this.setState({ isReportingMissingPortal: true })
            }
            portalsToRender={filteredOptions}
            selectedGroupKey={selectedGroupKey}
            onSearch={(value) => this.setState({ portalSearchText: value })}
            portalSearchText={portalSearchText}
          />
        )}
        {screen === "report_missing_portal" && (
          <RequestNewPortalIntegration
            onCancel={() => this.setState({ isReportingMissingPortal: false })}
            onSubmit={async (input) => {
              try {
                const response = await client.mutate({
                  mutation: reportMissingModalMutation,
                  variables: input,
                });
                if (!response.data.requestNewPortalIntegration.success) {
                  throw new Error("Failed to submit request.");
                }
                alert.info("Thanks! We've received your request.");
                closeModal();
              } catch {
                alert.error(
                  "Sorry, an unexpected error occurred. Try refreshing the page, and if the issue persists, contact us for help."
                );
              }
            }}
          />
        )}
        {screen === "portal_preview" &&
          selectedPortal.portalType === PortalType.LIVE_VIEWER && (
            <PortalPreview
              onCancel={this.handleCancel}
              onConfirm={this.handlePostPortalPreviewSelection}
              previewLinks={previewLinks}
            />
          )}
        {screen === "portal_preview" &&
          selectedPortal.portalType === PortalType.WEB_EXTENSION && (
            <WebPortalPreview
              onCancel={this.handleCancel}
              onConfirm={this.handleWebExtensionProceedClick}
              previewLinks={previewLinks}
              currentAccount={account}
              selectedPortal={selectedPortal}
            />
          )}
        {screen === "mfa_setup" &&
          selectedPortal.portalType === PortalType.WEB_EXTENSION && (
            <MfaSetupModalBody
              isOpen={isMfaPaneShown}
              portalKey={selectedPortal?.key}
              currentAccount={account}
              onDoneClick={this.handlePostPortalPreviewSelection}
            >
              <BtnsWrapper>
                <WhiteButton onClick={this.handleMfaBackClick}>
                  Back
                </WhiteButton>
                <SetupLaterText onClick={this.handlePostPortalPreviewSelection}>
                  Set up later
                </SetupLaterText>
              </BtnsWrapper>
            </MfaSetupModalBody>
          )}
        {screen === "new_portal_warning" && (
          <NewPortalWarning
            onSelect={onSelect}
            selectedPortal={selectedPortal}
          />
        )}
      </Modal>
    );
  }
}

export const PortalSelectionModal = compose(withCurrentAccount)(
  withApollo(withAlert()(BasePortalSelectionModal))
);

export default PortalSelectionModal;
