import {
  Account,
  Authorization,
  AuthorizationType,
  IntegratedEmrFilesDownloaded,
  IntegrationInstallation,
  Prescriber,
  TrackingStatus,
} from "@samacare/graphql";
import _ from "lodash";
import moment from "moment";
import { Forms } from "../routes/NewAuthorization/SelectFormModal/types";
import { isValidDate } from "./dateUtils";

export const getFriendlyString = (authStatus: string): string => {
  return _.upperFirst(authStatus.replace(/_/g, " "));
};

export const formatPrescriber = (p: Prescriber): string => {
  const defaultValue = `${_.get(p, "firstName") ?? ""} ${
    _.get(p, "lastName") ?? ""
  }`;
  return _.get(p, "label") || defaultValue;
};

export const isTrackedPortalAuth = (auth: Authorization): boolean => {
  return (
    auth.type === AuthorizationType.Portal ||
    auth.type === AuthorizationType.PortalExternal
  );
};

export const needCurrentUserAction = (
  auth: Authorization,
  acc: Account,
  isFullInstitutionAuth: boolean
): boolean => {
  return (
    isTrackedPortalAuth(auth) &&
    auth.statusCheckLoginRequiresAttn === true &&
    (auth.createdById === acc.id || isFullInstitutionAuth || acc.isSamaUser) &&
    auth.portalKey != null &&
    auth.trackingStatus != null &&
    [TrackingStatus.BlockedOnMfa, TrackingStatus.WrongCreds].includes(
      auth.trackingStatus
    )
  );
};

export const NO_ASSOCIATED_INSURANCE_LABEL = "No associated insurance";
export const getInsuranceLabel = (authorization: Authorization) => {
  const isExternalAuth =
    authorization.type ===
    window.CONFIG.CONSTANTS.AUTHORIZATION_TYPES.EXTERNAL.value;

  if (authorization.portalTitle != null && authorization.portalTitle !== "") {
    return `[Portal] ${authorization.portalTitle}`;
  }

  if (
    authorization.SiteId != null &&
    authorization.insuranceCompany?.name != null
  ) {
    return `[Portal] ${authorization.insuranceCompany?.name}`;
  }

  if (
    authorization.SiteId != null &&
    authorization.insuranceCompany?.name == null
  ) {
    return `[Portal] No associated insurance`;
  }

  if (isExternalAuth === true && authorization.insuranceCompany?.name != null) {
    return `[External] ${authorization.insuranceCompany?.name}`;
  }

  if (isExternalAuth === true && authorization.insuranceCompany?.name == null) {
    return "[External] No associated insurance";
  }

  if (
    authorization.formType != null &&
    authorization.formType ===
      window.CONFIG.CONSTANTS.AUTHORIZATION_FORM_TYPE.MANUALLY_TAGGED_FORM &&
    authorization.insuranceCompany?.name != null
  ) {
    return `[Manual] ${authorization.insuranceCompany?.name}`;
  }

  if (
    authorization.formType != null &&
    authorization.formType ===
      window.CONFIG.CONSTANTS.AUTHORIZATION_FORM_TYPE.MANUALLY_TAGGED_FORM &&
    authorization.insuranceCompany?.name == null
  ) {
    return "[Manual] No associated insurance";
  }

  return authorization.insuranceCompany?.name ?? NO_ASSOCIATED_INSURANCE_LABEL;
};

export const getAuthDescription = (authorization: Authorization): string => {
  const description = authorization.config?.[
    window.CONFIG.DEFAULT_FIELDS.REQUEST_DESCRIPTION.key
  ] as string | undefined;
  const dateOfService = authorization.config?.[
    window.CONFIG.DEFAULT_FIELDS.DATE_OF_SERVICE.key
  ] as string | undefined;

  if (dateOfService && isValidDate(dateOfService)) {
    return `[${dateOfService}] ${description ?? ""}`;
  }
  // Older auths won't have a date of service
  if (!_.isNil(description) && description !== "") {
    return `${description}`;
  }
  // Auths that aren't yet initiated will not have a description or date of service
  return "";
};

export const getFilteredFiles = (
  selectedCategoryParam: string,
  files: IntegratedEmrFilesDownloaded[],
  attachments: { awsKey: string }[]
): IntegratedEmrFilesDownloaded[] => {
  return _.sortBy(
    _.filter(
      files,
      (file) =>
        file?.category === selectedCategoryParam &&
        !_.find(attachments, { awsKey: file?.key })
    ),
    ({ date }: { date: string }) => moment(date)
  ).reverse() as IntegratedEmrFilesDownloaded[];
};

export const shouldDisplayEMRLoading = (input: {
  integrations: IntegrationInstallation[];
  isNextGenOrModMed: boolean;
  authorization: Authorization;
}): boolean => {
  const { integrations, isNextGenOrModMed, authorization } = input;
  const {
    integratedEMRFilesDownloaded,
    integratedEMRDocumentsDownloaded,
    createdAt,
  } = authorization;
  const { CONFIG } = window;

  const isOncoEmr =
    integrations.filter(
      (installation) =>
        [CONFIG.CONSTANTS.INTEGRATION_TITLE.oncoEMR].includes(
          installation.integrationId
        ) && installation.isEnabled
    ).length > 0;

  return (
    (isOncoEmr &&
      integratedEMRFilesDownloaded != null &&
      _.isEmpty(integratedEMRFilesDownloaded) &&
      moment().diff(createdAt, "minutes") <
        CONFIG.CONSTANTS.ONCOEMR_FORM_DELAY_MINUTES &&
      !integratedEMRDocumentsDownloaded) ||
    (isNextGenOrModMed &&
      !integratedEMRDocumentsDownloaded &&
      integratedEMRFilesDownloaded != null)
  );
};

export type AwsDocument = {
  name: string;
  id?: string;
  date?: string;
  awsKey: string;
  title: string;
  fileURL?: string;
};

export const containsUnnamedDocuments = (documents: AwsDocument[]): boolean =>
  !_.every(documents, (doc) => doc.name);

export const getDateValidationMessages = (params: {
  correspondenceStartDate?: string;
  correspondenceEndDate?: string;
  correspondenceCode?: string;
  authorizedProcedures?: { title: string; value: string }[];
}): {
  errorMessages: string[];
  warningMessages: string[];
} => {
  const {
    correspondenceStartDate,
    correspondenceEndDate,
    correspondenceCode,
    authorizedProcedures,
  } = params;

  let errorMessages: any[] = [];
  let warningMessages: any[] = [];
  const { DATE_FORMAT } = window.CONFIG.CONSTANTS;

  const isAnythingEntered =
    correspondenceStartDate ||
    correspondenceEndDate ||
    correspondenceCode ||
    !_.isEmpty(
      _.filter(
        authorizedProcedures,
        ({ title, value }) =>
          !_.isEmpty(_.trim(title)) && !_.isEmpty(_.trim(value))
      )
    );

  if (!isAnythingEntered) {
    return { errorMessages: ["No details entered"], warningMessages };
  }

  if (correspondenceStartDate) {
    if (!moment(correspondenceStartDate, DATE_FORMAT, true).isValid()) {
      errorMessages = [...errorMessages, "Invalid start date"];
    }

    if (
      moment(correspondenceStartDate, DATE_FORMAT, true) <
      moment().subtract(1, "month")
    ) {
      warningMessages = [...warningMessages, "Start date is in the past"];
    }
  }

  if (correspondenceEndDate) {
    if (!moment(correspondenceEndDate, DATE_FORMAT, true).isValid()) {
      errorMessages = [...errorMessages, "Invalid end date"];
    }

    if (
      moment(correspondenceEndDate, DATE_FORMAT, true) <
      moment().subtract(1, "month")
    ) {
      warningMessages = [...warningMessages, "End date is in the past"];
    }
  }

  if (
    !_.isEmpty(correspondenceEndDate) &&
    !_.isEmpty(correspondenceStartDate)
  ) {
    if (
      moment(correspondenceEndDate, DATE_FORMAT) <
      moment(correspondenceStartDate, DATE_FORMAT)
    ) {
      errorMessages = [...errorMessages, "End date before start date"];
    }
  }

  return { errorMessages, warningMessages };
};

export const sortForms = (forms: Forms) => {
  const favoriteForms = forms.favoriteForms || [];
  const favoriteFormIds = new Set(favoriteForms.map((f) => f.id));
  const allForms = _.uniqBy(
    [
      ...(forms.stateForms || []),
      ...(forms.payerForms || []),
      ...(forms.suggestedForms || []),
      ...favoriteForms,
    ],
    "id"
  );

  return _.orderBy(
    allForms,
    [
      (form) => favoriteFormIds.has(form.id),
      (form) => (form.approvalCount === null ? -Infinity : form.approvalCount),
    ],
    ["desc", "desc"]
  );
};
