import React from "react";
import { Box, Fade, Typography } from "@samacare/design/core";
import { SxProps, Theme, useTheme } from "@samacare/design/core/styles";

type GridWrapperProps = {
  children?: React.ReactNode;
  headerText?: string;
  sx?: SxProps<Theme> | undefined;
};

const GridWrapper: React.FC<GridWrapperProps> = ({
  children,
  headerText,
  sx,
}) => {
  const theme = useTheme();
  return (
    <Box
      p={2}
      position="relative"
      sx={{ margin: "24px", overflowY: "scroll", ...sx }}
      height="100%"
    >
      <Fade in>
        <Box
          sx={{
            width: "100%",
            height: headerText ? "calc(100% - 40px)" : "calc(100% - 14px)",
          }}
        >
          {headerText && (
            <Typography
              variant="h5"
              marginBottom={2}
              color={theme.palette.primary.main}
            >
              {headerText}
            </Typography>
          )}
          {children}
        </Box>
      </Fade>
    </Box>
  );
};

export default GridWrapper;
