import { VFC } from "react";
import { LeftRightCenterV } from "@samacare/component";
import { Box, Tooltip } from "@samacare/design";
import Error from "@samacare/design/core/icons/Error";

interface ErrorChipProps {
  tooltipText?: string;
  label: string;
}
export const ErrorChip: VFC<ErrorChipProps> = ({ tooltipText, label }) => (
  <LeftRightCenterV>
    <Tooltip title={tooltipText}>
      <Box>
        <LeftRightCenterV
          sx={{
            background: ({ palette }) => palette.error.main,
            borderRadius: "12px",
            height: "24px",
          }}
        >
          <Error
            sx={{
              marginLeft: ({ spacing }) => spacing(1),
              width: "0.8em",
              height: "0.8em",
              color: ({ palette }) => palette.error.contrastText,
            }}
          />
          <Box
            sx={{
              marginRight: ({ spacing }) => spacing(2),
              marginLeft: ({ spacing }) => spacing(1),
              color: ({ palette }) => palette.error.contrastText,
            }}
          >
            {label}
          </Box>
        </LeftRightCenterV>
      </Box>
    </Tooltip>
  </LeftRightCenterV>
);
