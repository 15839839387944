import { isPlainObject } from "lodash";

/**
 * parseAsNumber asserts value is a number and returns the value on success.
 * If the value is not a number, it throws a `TypeError`.
 */
export const parseAsNumber = <T = unknown>(value: T): number => {
  if (typeof value !== "number") {
    throw new TypeError(
      `Expected value to be a number but received: ${typeof value}`
    );
  }
  return value;
};

/**
 * parseAsNumberWithDefault asserts value is a number and returns the value on
 * success. If the value is not a number, it returns the provided default value.
 */
export const parseAsNumberWithDefault = <T = unknown>(
  value: T,
  defaultValue: number
): number => {
  try {
    return parseAsNumber(value);
  } catch (err) {
    return defaultValue;
  }
};

/**
 * parseAsString asserts value is a number and returns the value on success.
 * If the value is not a string, it throws a `TypeError`.
 */
export const parseAsString = <T = unknown>(value: T): string => {
  if (typeof value !== "string") {
    throw new TypeError(
      `Expected value to be a string but received: ${typeof value}`
    );
  }
  return value;
};

/**
 * parseAsNonNull asserts value is non-null and returns the value on success.
 * If the value is null, it throws a `TypeError`.
 */
export const parseAsNonNull = <T = unknown>(value: T | null): T => {
  if (value === null) {
    throw new TypeError("Expected value to be non-null but received: null");
  }
  return value;
};

/**
 * parseAsNonNullWithDefault asserts value is non-null and returns the value on
 * success. If the value is null, it returns the provided default value.
 */
export const parseAsNonNullWithDefault = <T = unknown>(
  value: T | null,
  defaultValue: T
): T => {
  try {
    return parseAsNonNull(value);
  } catch (err) {
    return defaultValue;
  }
};

/**
 * parseAsNonNil asserts value is non-null and non-undefined and returns the
 * value on success. If the value is null or undefined, it throws a `TypeError`.
 */
export const parseAsNonNil = <T = unknown>(value: T | null | undefined): T => {
  if (value == null) {
    throw new TypeError(
      `Expected value to be a non-null and non-undefined value but received: ${
        value === null ? "null" : "undefined"
      }`
    );
  }
  return value;
};

/**
 * parseAsUnknownRecordWithDefault asserts value is a plain object and returns
 * the value on success. If the value is not a plain object, it throws a
 * TypeError.
 */
export const parseAsUnknownRecord = <T = unknown>(
  value: T
): Record<string, unknown> => {
  if (!isPlainObject(value)) {
    throw new TypeError(
      `Expected value to be a plain object but received: ${typeof value}`
    );
  }
  return value as Record<string, unknown>;
};

/**
 * parseAsUnknownRecordWithDefault asserts value is a plain object and returns
 * the value on success. If the value is not a plain object, it returns the
 * provided default value.
 */
export const parseAsUnknownRecordWithDefault = <T = unknown>(
  value: T,
  defaultValue: Record<string, unknown>
): Record<string, unknown> => {
  try {
    return parseAsUnknownRecord(value);
  } catch (err) {
    return defaultValue;
  }
};

/**
 * parseServerErrorMessage takes a string value in the format of
 * "Server error: [WARN ONLY] - Validation failed on user input additional
 * details = This number already exists" and converts it to a readable error message.
 * For this example, the formatted error message is "This number already exists".
 */
export const parseServerErrorMessage = (value: string): string => {
  if (!value.includes("=")) return value;
  return value.split("=")[1]?.trim();
};

/**
 * parseHcpcsValuesFromRecord extracts HCPCS codes from the authorization config object. It iterates over properties
 * that match the pattern 'HCPCS_#' and collects non-null values. The function terminates the search when it encounters
 * an undefined property for the expected sequential HCPCS key, ensuring it doesn't look for non-sequential keys.
 * This function is useful for parsing configuration objects that contain HCPCS codes indexed by sequence numbers.
 */
export const parseHcpcsValuesFromRecord: (
  config: Record<string, any>
) => string[] = (config) => {
  const hcpcsValues: string[] = [];
  let index = 0;
  const hcpcsPrefix = "HCPCS_";
  while (config[`${hcpcsPrefix}${index}`] !== undefined) {
    const hcpcsCode = config[`${hcpcsPrefix}${index}`];
    if (hcpcsCode !== null) {
      hcpcsValues.push(hcpcsCode);
    }
    index++;
  }

  return hcpcsValues;
};
