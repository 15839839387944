import { useConfig } from "../../app/hooks";
import { getInsuranceType } from "../../app/util/getInsuranceType";

export type InsuranceTypeOption = {
  value: string;
  label: string;
};

const useInsuranceTypeOptionsFull = (): InsuranceTypeOption[] => {
  const { CONSTANTS } = useConfig();

  return Object.entries(CONSTANTS?.INSURANCE_TYPES).map(([value, label]) => ({
    value,
    label: getInsuranceType(label),
  }));
};

export default useInsuranceTypeOptionsFull;
