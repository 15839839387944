import { CircularProgress, Typography } from "@samacare/design";
import { FullBenefitsCheckDataType } from "@samacare/graphql";

import { useUnifiedBenefitsCheckContext } from "../UnifiedBenefitsCheckProvider";
import { CareMetxPavbluRequest } from "../components/CareMetxPavbluRequest";

export const CareMetxPavbluRequestPage: React.FC = () => {
  const res = useUnifiedBenefitsCheckContext();
  if (res.loading) {
    return <CircularProgress />;
  }

  if (res.unifiedBenefitsCheck == null) {
    return <Typography>Unable to load benefits check</Typography>;
  }

  const { drug, fullData } = res.unifiedBenefitsCheck;
  if (
    drug == null ||
    fullData == null ||
    fullData.type !== FullBenefitsCheckDataType.Caremetx
  ) {
    return <Typography>Benefits check is not supported by CareMetx</Typography>;
  }

  return <CareMetxPavbluRequest drug={drug} caremetxBv={fullData} />;
};
