import _ from "lodash";
import { FC, forwardRef } from "react";
import { useCombinedReferences } from "@samacare/hooks-ui";

import {
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
} from "@samacare/design";

import {
  useFormContext,
  useFormState,
  UseControllerProps,
  FieldError,
} from "react-hook-form";

export type TextFieldProps = MuiTextFieldProps & UseControllerProps;

const TextField: FC<TextFieldProps> = forwardRef<
  HTMLDivElement,
  TextFieldProps
>(
  (
    {
      name,
      value,
      shouldUnregister = false,
      required = false,
      disabled = false,
      rules = {},
      ...materialProperties
    },
    inputRef,
  ) => {
    const { register, control } = useFormContext() || {};
    if (!register)
      throw new Error("This component must be wrapped in a Form Provider!");

    const { errors } = useFormState({ control });

    const { ref, ...fields } = register(name, {
      ...rules,
      required,
      shouldUnregister,
      value,
    });

    const combinedRef = useCombinedReferences(inputRef, ref);
    const error = _.get(errors, name) as FieldError;

    return (
      <MuiTextField
        inputRef={combinedRef}
        defaultValue={value}
        id={`TextField-${name}`}
        error={Boolean(error)}
        helperText={error?.message}
        required={required}
        disabled={disabled}
        {...fields}
        {...materialProperties}
        inputProps={{ "data-cy": _.camelCase(`field.${name}`) }}
        InputLabelProps={{ shrink: true }}
      />
    );
  },
);

export default TextField;
