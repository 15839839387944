import gql from "graphql-tag";

export default gql`
  fragment serviceCodeInfo on ServiceCode {
    code
    drugName
    drugOptionId
    level
  }
`;
