import _ from "lodash";
import { ReactNode } from "react";

import { Box, Stack } from "@samacare/design";
import {
  useFormContext,
  AddressBlock,
  AutocompleteField,
  TextField,
} from "@samacare/form";
import { PrescriberInfoFragment } from "@@generated/graphql";
import { usePrescribers } from "../_common/hooks/usePrescribers";
import { RequestingProviderRoleCode } from "@samacare/graphql";

const ProviderTypeOrder: RequestingProviderRoleCode[] = [
  RequestingProviderRoleCode.Provider,
  RequestingProviderRoleCode.Facility,
];

const defaults = {
  npi: "",
  firstName: "",
  lastName: "",
  PrescriberId: "",
  taxId: "",
  specialty: "",
  streetAddress: "",
  city: "",
  state: "",
  zip: "",
  roleCode: null,
};

const toLabel = (value: string) => {
  return _.startCase(_.lowerCase(value));
};

const HiddenBox = () => <Box sx={{ visibility: "hidden", width: "100%" }} />;

export type ProviderBlockProps = {
  required?: boolean;
  hideProviderType?: boolean;
  providerObjName?: string;
  children?: ReactNode;
};
const ProviderBlock: React.FC<ProviderBlockProps> = (props) => {
  const {
    required = false,
    providerObjName = "requestingProvider",
    hideProviderType = false,
    children,
  } = props;
  const { reset, getValues, watch, setValue } = useFormContext();

  const prescribers = usePrescribers();

  const prescriberTag = `${providerObjName}.PrescriberId`;
  const PrescriberId = watch(prescriberTag);

  // when a provider is not selected, all the other fields are disabled
  const disabled = !PrescriberId;

  return (
    <Stack gap={2} maxWidth={600}>
      <Stack gap={1} direction="row" maxWidth={600}>
        <Stack direction="row" alignItems="center" width="100%">
          <AutocompleteField<PrescriberInfoFragment, false, false, false>
            name={`ProviderBlock_${providerObjName}`}
            label="Provider"
            options={prescribers}
            filterSelectedOptions
            fullWidth
            getOptionLabel={(option) =>
              option?.firstName != null && option?.lastName != null
                ? `${option?.firstName} ${option?.lastName}`
                : "Unknown"
            }
            onChange={(_event, change, reason) => {
              reset(getValues(), { keepDirty: true });
              setValue(
                providerObjName,
                reason === "selectOption"
                  ? {
                      ...defaults,
                      ...{
                        npi: change?.NPI,
                        firstName: change?.firstName,
                        lastName: change?.lastName,
                        specialty: change?.specialtyDescription,
                        taxId: change?.TIN,
                        PrescriberId: change?.id,
                      },
                    }
                  : defaults,
                { shouldDirty: true }
              );
            }}
          />
        </Stack>
        <TextField
          required={required}
          disabled={disabled}
          fullWidth
          rules={{
            validate: (value) => value.length === 10,
          }}
          name={`${providerObjName}.npi`}
          label="NPI"
        />
      </Stack>
      <TextField
        sx={{ display: "none" }}
        type="hidden"
        name={prescriberTag}
        value={PrescriberId}
      />
      {!hideProviderType && (
        <AutocompleteField<string, false, true, false>
          disableClearable
          getOptionLabel={(val) => toLabel(val)}
          options={ProviderTypeOrder}
          label="Provider type"
          name={`${providerObjName}.roleCode`}
          required
          disabled={disabled}
          value={watch(`${providerObjName}.roleCode`) ?? ""}
        />
      )}
      <Stack gap={1} direction="row" maxWidth={600}>
        <TextField
          required={required}
          disabled={disabled}
          fullWidth
          label="First name"
          name={`${providerObjName}.firstName`}
        />
        <TextField
          required={required}
          disabled={disabled}
          fullWidth
          label="Last name"
          name={`${providerObjName}.lastName`}
        />
        <HiddenBox />
      </Stack>
      <Stack gap={1} direction="row" maxWidth={600}>
        <TextField
          required={false}
          disabled={disabled}
          fullWidth
          label="Specialty"
          name={`${providerObjName}.specialty`}
        />
        <TextField
          required={false}
          disabled={disabled}
          fullWidth
          label="Tax ID"
          name={`${providerObjName}.taxId`}
        />
        <HiddenBox />
      </Stack>

      <AddressBlock
        required={false}
        disabled={disabled}
        AddressInputProps={{ name: `${providerObjName}.streetAddress` }}
        CityInputProps={{ name: `${providerObjName}.city` }}
        StateInputProps={{ name: `${providerObjName}.state` }}
        ZipInputProps={{ name: `${providerObjName}.zip` }}
      />
      {children}
    </Stack>
  );
};

export default ProviderBlock;
