import { useState } from "react";
import { IconButton, InputAdornment } from "@samacare/design/core";

import VisibilityIcon from "@samacare/design/core/icons/Visibility";
import VisibilityOffIcon from "@samacare/design/core/icons/VisibilityOff";

import { TextField, TextFieldProps } from "@samacare/form";

export type PasswordFieldProps = TextFieldProps & {
  create?: boolean;
};

export const PasswordField: React.FC<PasswordFieldProps> = (props) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <TextField
      type={showPassword ? "text" : "password"}
      required
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="Toggle password visibility"
              onClick={() => setShowPassword(!showPassword)}
              edge="end"
            >
              {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
};
