import { yupResolver } from "@hookform/resolvers/yup";
import { useAlert } from "react-alert";
import { object, string } from "yup";
import { FormProvider, useForm, TextField, Resolver } from "@samacare/form";
import { useEffect, useState } from "react";
import { Box, Divider } from "@samacare/design";
import { useMutation } from "@apollo/client";
import { Button, Stack, DialogContent } from "@samacare/design/core";
import Dialog from "@samacare/design/core/Dialog";
import DialogActions from "@samacare/design/core/DialogActions";
import DialogTitle from "@samacare/design/core/DialogTitle";
import {
  PRESCRIBERS_FIND_ALL_QUERY_NAME,
  createPrescriberByNpiFromRegistryMutation,
  useUpsertPrescriber,
} from "../../../graphql/Prescriber";
import { Prescriber } from "@samacare/graphql";

import {
  CreatePrescriberByNpiFromRegistryMutation,
  CreatePrescriberByNpiFromRegistryMutationVariables,
} from "@@generated/graphql";

const prescriberSchema = object<Prescriber>({
  firstName: string().required("First name is required"),
  lastName: string().required("Last name is required"),
  NPI: string()
    .required("NPI is required")
    .length(10, "NPI must be 10 digits")
    .matches(/^\d{10}$/, "NPI must only contain digits"),
  label: string(),
  TIN: string()
    .matches(/.{9,}/, {
      excludeEmptyString: true,
      message: "TIN must be 9 digits",
    })
    .matches(/^\d*$/, "TIN must only contain digits"),
  DEA: string(),
  licenseNumber: string(),
  specialtyDescription: string(),
  specialtyCode: string(),
  availityPayerAssignedProviderId: string(),
  availitySubmitterId: string(),
});

interface UpsertPrescriberDialogProps {
  prescriber: Prescriber | null;
  onClose: () => void;
}
export const UpsertPrescriberDialog: React.FC<UpsertPrescriberDialogProps> = (
  props
) => {
  const [isEdit, setIsEdit] = useState(false);
  const alert = useAlert();
  const upsertPrescriber = useUpsertPrescriber();
  const methods = useForm<Prescriber>({
    resolver: yupResolver(prescriberSchema) as Resolver<Prescriber, any>,
    // mode: "onChange",
    // reValidateMode: "onChange",
  });

  const [createPrescriberByNpiFromRegistry] = useMutation<
    CreatePrescriberByNpiFromRegistryMutation,
    CreatePrescriberByNpiFromRegistryMutationVariables
  >(createPrescriberByNpiFromRegistryMutation, {
    refetchQueries: () => [PRESCRIBERS_FIND_ALL_QUERY_NAME],
  });

  const onSave = async (prescriber: Prescriber) => {
    try {
      await upsertPrescriber(prescriber);
      alert.success("Success");
      props.onClose();
    } catch (err: unknown) {
      const e = err as Error;
      alert.error(`There was an error creating this prescriber, ${e.message}`);
    }
  };

  useEffect(() => {
    methods.reset(props.prescriber ?? {});
    if (props.prescriber) {
      setIsEdit(!!props.prescriber.id);
    }
  }, [props.prescriber, methods, setIsEdit]);

  const handleSearch = async (npi: string) => {
    const { data } = await createPrescriberByNpiFromRegistry({
      variables: { npi },
    });

    if (data) {
      const { success, message } = data.createPrescriberByNpiFromRegistry;

      if (success) {
        alert.info(
          "Successfully searched the NPI Registry and created a new Provider"
        );
        props.onClose();
      } else {
        alert.error(message);
        setIsEdit(true);
      }
    }
  };

  const title = isEdit ? "Edit Provider" : "Add Provider";
  const data = methods.watch();

  return (
    <Dialog
      open={!!props.prescriber}
      onClose={props.onClose}
      scroll="paper"
      maxWidth="lg"
    >
      <DialogTitle>{title}</DialogTitle>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSave)}>
          <DialogContent sx={{ width: 600 }}>
            <Stack direction="row" spacing={2} pb={2}>
              <TextField
                fullWidth
                label="NPI"
                name="NPI"
                required
                inputProps={{ maxLength: 10 }}
              />
              <Box display="flex" width="100%">
                <Button
                  variant="contained"
                  disabled={data.NPI == null || isEdit}
                  onClick={async () => {
                    await handleSearch(data.NPI as string);
                  }}
                >
                  Search
                </Button>
              </Box>
            </Stack>
            <Divider />
            <Stack direction="column" spacing={2} pt={2}>
              <Stack direction="row" spacing={2}>
                <TextField
                  // eslint-disable-next-line jsx-a11y/no-autofocus
                  autoFocus
                  fullWidth
                  label="First Name"
                  name="firstName"
                  disabled={!isEdit}
                />
                <TextField
                  fullWidth
                  label="Last Name"
                  name="lastName"
                  disabled={!isEdit}
                />
              </Stack>
              <Stack direction="row" spacing={2}>
                <TextField
                  fullWidth
                  label="Specialty"
                  name="specialtyDescription"
                  disabled={!isEdit}
                />
                <TextField
                  fullWidth
                  label="Specialty Code"
                  name="specialtyCode"
                  disabled={!isEdit}
                />
              </Stack>
              <Stack direction="row" spacing={2}>
                <TextField
                  fullWidth
                  label="TIN"
                  name="TIN"
                  disabled={!isEdit}
                />
                <TextField
                  fullWidth
                  label="DEA"
                  name="DEA"
                  disabled={!isEdit}
                />
              </Stack>
              <Stack direction="row" spacing={2}>
                <TextField
                  fullWidth
                  label="License Number"
                  name="licenseNumber"
                  disabled={!isEdit}
                />
                <TextField
                  fullWidth
                  label="Label"
                  name="label"
                  disabled={!isEdit}
                />
              </Stack>
              <Stack direction="row" spacing={2}>
                <TextField
                  fullWidth
                  label="Availity Payer Assigned Provider Id"
                  name="availityPayerAssignedProviderId"
                  disabled={!isEdit}
                />
                <TextField
                  fullWidth
                  label="Availity Submitter ID"
                  name="availitySubmitterId"
                  disabled={!isEdit}
                />
              </Stack>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button
              data-cy="actionCloseEditPrescriberDialog"
              variant="outlined"
              onClick={() => {
                methods.reset();
                props.onClose();
              }}
            >
              Close
            </Button>
            <Button
              data-cy="actionSavePrescriber"
              type="submit"
              variant="contained"
              color="primary"
              disabled={!isEdit}
            >
              {isEdit ? "Save" : "Add"}
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};
