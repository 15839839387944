import {
  IntegrationInstallation,
  GetInstitutionNameFromNextGen,
  GetInstitutionNamesFromModernizingMedicine,
  Institution,
} from "@samacare/graphql";

import {
  UpsertNextGenIntegrationInstallationMutation,
  UpsertRedoxIntegrationInstallationMutation,
  UpsertModernizingMedicineIntegrationInstallationMutation,
} from "@@generated/graphql";

import { useConfig } from "@@hooks/config";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
  LoadingButton,
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  SelectChangeEvent,
  DialogActions,
  Alert,
} from "@samacare/design/core";
import { TextField, FormProvider, useForm } from "@samacare/form";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import getIntegrationInstallationByInstitutionIdQueryFile from "./getIntegrationInstallationByInstitutionId.gql";
import {
  getInstitutionNamesFromModernizingMedicine as getInstitutionNamesFromModernizingMedicineQuery,
  getInstitutionNameFromNextGen as getInstitutionNameFromNextGenQuery,
  upsertModernizingMedicineIntegrationInstallation as upsertModernizingMedicineIntegrationInstallationMutation,
  upsertNextGenIntegrationInstallation as upsertNextGenIntegrationInstallationMutation,
  upsertRedoxIntegrationInstallation as upsertRedoxIntegrationInstallationMutation,
} from "../../../graphql/Emr";
import { ConfirmationDialog } from "@@components/ConfirmationDialog";
import moment from "moment";
import AlertTitle from "@samacare/design/core/AlertTitle";
import {
  ModernizingMedicineConfirmationContent,
  NextGenConfirmationContent,
  RedoxConfirmationContent,
} from "./ConfirmationModalContent";
import { object, string } from "yup";

const SITE_ID = "siteId";
const PRACTICE_ID = "practiceId";
const DOCUMENT_TYPE = "documentType";
const USERNAME = "username";
const PASSWORD = "password";
const URL = "url";
const FACILITY_CODE = "facilityCode";

type NextGenInputs = {
  siteId: string;
  practiceId: string;
  documentType: string;
};

type ModMedInputs = {
  username: string;
  password: string;
  url: string;
};

type RedoxInputs = {
  facilityCode: string;
};

const nextGenSchema = object<NextGenInputs>({
  siteId: string().required("Site ID is required"),
  practiceId: string().required("Practice ID is required"),
  documentType: string().required("Document Type is required"),
});

const modMedSchema = object<ModMedInputs>({
  username: string().required("Username is required"),
  password: string().required("Password is required"),
  url: string().required("URL is required"),
});

const redoxSchema = object<RedoxInputs>({
  facilityCode: string().required("Facility Code is required"),
});

export const IntegrationStatus: React.VoidFunctionComponent<{
  integrationInstallation: IntegrationInstallation | undefined;
}> = ({ integrationInstallation }) => {
  const { CONSTANTS } = useConfig();

  const apiIntegrations = [
    CONSTANTS.INTEGRATION_TITLE.redoxAPI,
    CONSTANTS.INTEGRATION_TITLE.nextGen,
    CONSTANTS.INTEGRATION_TITLE.modernizingMedicine,
  ];
  const shouldShowSuccessAlert =
    integrationInstallation?.isEnabled &&
    apiIntegrations.includes(integrationInstallation?.integrationId);
  const shouldShowErrorAlert =
    integrationInstallation?.integrationId != null &&
    !integrationInstallation?.isEnabled;

  return (
    <Box width={({ spacing }) => spacing(45)}>
      {shouldShowErrorAlert && (
        <Alert severity="error">
          <AlertTitle>Disabled integration</AlertTitle>
          {integrationInstallation?.configError ??
            "Unknown error, please try again"}
        </Alert>
      )}
      {shouldShowSuccessAlert && (
        <Alert severity="success">
          <AlertTitle>Enabled integration</AlertTitle>
          {integrationInstallation?.patientSearchLastSuccessfullyCheckedAt && (
            <p>{`Enabled ${moment(
              integrationInstallation?.patientSearchLastSuccessfullyCheckedAt
            ).format("MM/DD/YYYY")}`}</p>
          )}
        </Alert>
      )}
    </Box>
  );
};

export const IntegrationModalInputs: React.VoidFunctionComponent<{
  emrSelected: string | null;
}> = ({ emrSelected }) => {
  const { CONSTANTS } = useConfig();

  return (
    <form>
      {emrSelected === CONSTANTS.INTEGRATION_TITLE.nextGen && (
        <Stack direction="column" spacing={2}>
          {[SITE_ID, PRACTICE_ID, DOCUMENT_TYPE].map((field) => (
            <TextField
              fullWidth
              key={field}
              required
              label={_.startCase(field)}
              name={field}
            />
          ))}
        </Stack>
      )}

      {emrSelected === CONSTANTS.INTEGRATION_TITLE.modernizingMedicine && (
        <Stack direction="column" spacing={2}>
          {[USERNAME, PASSWORD, URL].map((field) => (
            <TextField
              fullWidth
              key={field}
              required
              label={_.startCase(field)}
              name={field}
            />
          ))}
        </Stack>
      )}

      {emrSelected === CONSTANTS.INTEGRATION_TITLE.redoxAPI && (
        <TextField
          fullWidth
          required
          label={_.startCase(FACILITY_CODE)}
          name={FACILITY_CODE}
        />
      )}
    </form>
  );
};

export const EmrConfigModal: React.VoidFunctionComponent<{
  onClose: () => void;
  institution: Institution;
  open: boolean;
  integrationInstallation: IntegrationInstallation | undefined;
}> = ({ onClose, institution, open, integrationInstallation }) => {
  const { CONSTANTS } = useConfig();
  const { id: institutionId, name: institutionName } = institution;
  const alert = useAlert();
  const [emrSelected, setEmrSelected] = useState<string | null>(
    integrationInstallation?.integrationId ?? null
  );
  const [showConfirm, setShowConfirm] = useState(false);

  const apiIntegrations = [
    CONSTANTS.INTEGRATION_TITLE.redoxAPI,
    CONSTANTS.INTEGRATION_TITLE.nextGen,
    CONSTANTS.INTEGRATION_TITLE.modernizingMedicine,
  ];

  const [getInstitutionNameFromNextGen, getInstitutionNameFromNextGenResult] =
    useLazyQuery<{
      getInstitutionNameFromNextGen: GetInstitutionNameFromNextGen;
    }>(getInstitutionNameFromNextGenQuery, { fetchPolicy: "network-only" });

  const [
    getInstitutionNamesFromModernizingMedicine,
    getInstitutionNamesFromModernizingMedicineResult,
  ] = useLazyQuery<{
    getInstitutionNamesFromModernizingMedicine: GetInstitutionNamesFromModernizingMedicine;
  }>(getInstitutionNamesFromModernizingMedicineQuery, {
    fetchPolicy: "network-only",
  });

  const [upsertNextGenIntegrationInstallation, { loading: nextGenLoading }] =
    useMutation<UpsertNextGenIntegrationInstallationMutation>(
      upsertNextGenIntegrationInstallationMutation,
      {
        refetchQueries: [
          {
            query: getIntegrationInstallationByInstitutionIdQueryFile,
            variables: { institutionId },
          },
        ],
      }
    );

  const [
    upsertModernizingMedicineIntegrationInstallation,
    { loading: modMedLoading },
  ] = useMutation<UpsertModernizingMedicineIntegrationInstallationMutation>(
    upsertModernizingMedicineIntegrationInstallationMutation,
    {
      refetchQueries: [
        {
          query: getIntegrationInstallationByInstitutionIdQueryFile,
          variables: { institutionId },
        },
      ],
    }
  );

  const [upsertRedoxIntegrationInstallation, { loading: redoxLoading }] =
    useMutation<UpsertRedoxIntegrationInstallationMutation>(
      upsertRedoxIntegrationInstallationMutation,
      {
        refetchQueries: [
          {
            query: getIntegrationInstallationByInstitutionIdQueryFile,
            variables: { institutionId },
          },
        ],
      }
    );

  const methods = useForm<NextGenInputs & ModMedInputs & RedoxInputs>({
    defaultValues: {
      [SITE_ID]: "",
      [PRACTICE_ID]: "",
      [DOCUMENT_TYPE]: "",
      [USERNAME]: "",
      [PASSWORD]: "",
      [URL]: "",
      [FACILITY_CODE]: "",
    },
  });

  useEffect(() => {
    // if integration installation already exists for the given institution, populate the corresponding input fields

    if (integrationInstallation) {
      const { integrationId, settings, isEnabled, facilityCode } =
        integrationInstallation;
      setEmrSelected(integrationId);

      if (isEnabled && settings) {
        methods.reset({
          [SITE_ID]: settings.siteId ?? "",
          [PRACTICE_ID]: settings.practiceId ?? "",
          [DOCUMENT_TYPE]: settings.documentType ?? "",
          [USERNAME]: settings.username ?? "",
          [PASSWORD]: settings.password ?? "",
          [URL]: settings.firmUrlPrefix ?? "",
          [FACILITY_CODE]: facilityCode ?? "",
        });
      }
    }
  }, [integrationInstallation]);

  const handleClose = () => {
    onClose();
    methods.reset();
  };

  const handleDisable = async () => {
    try {
      if (emrSelected === CONSTANTS.INTEGRATION_TITLE.nextGen) {
        const { siteId, documentType, practiceId } =
          methods.getValues() as NextGenInputs;
        await upsertNextGenIntegrationInstallation({
          variables: {
            siteId,
            practiceId,
            documentType,
            institutionId,
            isEnabled: false,
          },
        });
      }

      if (emrSelected === CONSTANTS.INTEGRATION_TITLE.modernizingMedicine) {
        const {
          password,
          username,
          url: firmUrlPrefix,
        } = methods.getValues() as ModMedInputs;
        await upsertModernizingMedicineIntegrationInstallation({
          variables: {
            username,
            password,
            firmUrlPrefix,
            institutionId,
            isEnabled: false,
          },
        });
      }

      if (emrSelected === CONSTANTS.INTEGRATION_TITLE.redoxAPI) {
        const { facilityCode } = methods.getValues() as RedoxInputs;
        await upsertRedoxIntegrationInstallation({
          variables: { facilityCode, institutionId, isEnabled: false },
        });
      }
    } catch (e) {
      alert.error(
        `Unable to disable ${_.startCase(emrSelected as string)} integration`
      );
    }
  };

  useEffect(() => {
    function disableIntegration() {
      void handleDisable();
    }
    // show confirmation modal if institution query returns valid result
    if (
      (getInstitutionNameFromNextGenResult.data &&
        !getInstitutionNameFromNextGenResult.data?.getInstitutionNameFromNextGen
          .errorMessage) ||
      (getInstitutionNamesFromModernizingMedicineResult.data &&
        !getInstitutionNamesFromModernizingMedicineResult.data
          ?.getInstitutionNamesFromModernizingMedicine.errorMessage)
    ) {
      setShowConfirm(true);
    }

    // throw error message if institution query returns invalid result
    if (
      (emrSelected === CONSTANTS.INTEGRATION_TITLE.modernizingMedicine &&
        (getInstitutionNamesFromModernizingMedicineResult.data
          ?.getInstitutionNamesFromModernizingMedicine.errorMessage ||
          getInstitutionNamesFromModernizingMedicineResult.error)) ||
      (emrSelected === CONSTANTS.INTEGRATION_TITLE.nextGen &&
        (getInstitutionNameFromNextGenResult?.data
          ?.getInstitutionNameFromNextGen.errorMessage ||
          getInstitutionNameFromNextGenResult.error))
    ) {
      const errMessage =
        getInstitutionNameFromNextGenResult?.data?.getInstitutionNameFromNextGen
          ?.errorMessage ?? "please follow up with eng to debug";

      disableIntegration();
      alert.error(`Unable to confirm institution name: ${errMessage}`);
    }
  }, [
    getInstitutionNamesFromModernizingMedicineResult,
    getInstitutionNameFromNextGenResult,
  ]);

  const handleProceed = async (
    data: NextGenInputs & ModMedInputs & RedoxInputs
  ) => {
    await methods.trigger();

    const { siteId, practiceId, url: firmUrlPrefix, username, password } = data;
    try {
      switch (emrSelected) {
        case CONSTANTS.INTEGRATION_TITLE.nextGen:
          await nextGenSchema.validate(data, { abortEarly: false });
          getInstitutionNameFromNextGen({
            variables: { institutionId, siteId, practiceId },
          });
          break;
        case CONSTANTS.INTEGRATION_TITLE.modernizingMedicine:
          await modMedSchema.validate(data, { abortEarly: false });
          getInstitutionNamesFromModernizingMedicine({
            variables: { firmUrlPrefix, username, password, institutionId },
          });
          break;
        case CONSTANTS.INTEGRATION_TITLE.redoxAPI:
          await redoxSchema.validate(data, { abortEarly: false });
          setShowConfirm(true);
          break;
        default:
          break;
      }
    } catch (e) {
      alert.error(
        `Unable to update ${_.startCase(
          emrSelected as string
        )} integration. Please check the given inputs.`
      );
    }
  };

  const handleConfirm = async () => {
    try {
      if (emrSelected === CONSTANTS.INTEGRATION_TITLE.nextGen) {
        const { siteId, documentType, practiceId } =
          methods.getValues() as NextGenInputs;
        await upsertNextGenIntegrationInstallation({
          variables: {
            siteId,
            practiceId,
            documentType,
            institutionId,
            isEnabled: true,
          },
        });
      }

      if (emrSelected === CONSTANTS.INTEGRATION_TITLE.modernizingMedicine) {
        const {
          password,
          username,
          url: firmUrlPrefix,
        } = methods.getValues() as ModMedInputs;
        await upsertModernizingMedicineIntegrationInstallation({
          variables: {
            username,
            password,
            firmUrlPrefix,
            institutionId,
            isEnabled: true,
          },
        });
      }

      if (emrSelected === CONSTANTS.INTEGRATION_TITLE.redoxAPI) {
        const { facilityCode } = methods.getValues() as RedoxInputs;
        await upsertRedoxIntegrationInstallation({
          variables: { facilityCode, institutionId, isEnabled: true },
        });
      }
    } catch (e) {
      alert.error(
        `Unable to update ${_.startCase(emrSelected as string)} integration`
      );
    }
  };

  const formData = methods.watch();

  return (
    <>
      <Dialog onClose={handleClose} fullWidth={true} scroll="paper" open={open}>
        <DialogTitle>EMR Setup</DialogTitle>
        <DialogContent>
          <Stack
            direction="column"
            spacing={2}
            sx={{ paddingTop: ({ spacing }) => spacing(2) }}
          >
            <FormControl
              disabled={integrationInstallation?.integrationId != null}
              id="Select EMR"
              fullWidth
            >
              <InputLabel>Select EMR</InputLabel>
              <Select
                id="Select EMR"
                label="Select EMR"
                labelId="Select EMR"
                onChange={(e: SelectChangeEvent<unknown>) =>
                  setEmrSelected(e.target.value as string)
                }
                value={emrSelected}
              >
                {apiIntegrations.map((field) => (
                  <MenuItem key={field} value={field}>
                    {field === CONSTANTS.INTEGRATION_TITLE.redoxAPI
                      ? "WeInfuse"
                      : _.startCase(field)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormProvider {...methods}>
              <IntegrationModalInputs emrSelected={emrSelected} />
            </FormProvider>
            {emrSelected && (
              <Box display="flex">
                <LoadingButton
                  onClick={async () => handleProceed(formData)}
                  variant="contained"
                  color="primary"
                  loading={
                    getInstitutionNameFromNextGenResult.loading ||
                    getInstitutionNamesFromModernizingMedicineResult.loading
                  }
                >
                  {integrationInstallation?.isEnabled ? "Update " : "Enable "}
                  Integration
                </LoadingButton>
              </Box>
            )}
            {!modMedLoading && !nextGenLoading && !redoxLoading && (
              <IntegrationStatus
                integrationInstallation={integrationInstallation}
              />
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              onClose();
            }}
          >
            Done
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmationDialog
        loading={modMedLoading || nextGenLoading || redoxLoading}
        open={showConfirm}
        onClose={() => {
          setShowConfirm(false);
        }}
        onConfirm={async () => {
          await handleConfirm();
          setShowConfirm(false);
        }}
      >
        <Typography sx={{ my: 2 }} variant="h6" color="black">
          EMR Setup
        </Typography>
        <div>
          {emrSelected === CONSTANTS.INTEGRATION_TITLE.nextGen && (
            <NextGenConfirmationContent
              data={
                getInstitutionNameFromNextGenResult?.data
                  ?.getInstitutionNameFromNextGen.institutionName as string
              }
            />
          )}
          {emrSelected === CONSTANTS.INTEGRATION_TITLE.modernizingMedicine && (
            <ModernizingMedicineConfirmationContent
              data={
                getInstitutionNamesFromModernizingMedicineResult?.data
                  ?.getInstitutionNamesFromModernizingMedicine
                  .institutionNames as string[]
              }
            />
          )}
          {emrSelected === CONSTANTS.INTEGRATION_TITLE.redoxAPI && (
            <RedoxConfirmationContent
              institutionName={institutionName}
              facilityCode={methods.getValues().facilityCode}
            />
          )}
        </div>
      </ConfirmationDialog>
    </>
  );
};
