import * as React from "react";
import { Button } from "@samacare/design/core";
import { useTheme } from "styled-components";

interface DownloadCopilotButtonProps {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  sx?: React.CSSProperties;
}

export const DownloadCopilotButton: React.FC<DownloadCopilotButtonProps> = ({
  onClick,
  sx,
}) => {
  const theme = useTheme();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    onClick(event);
  };

  return (
    <Button
      variant="contained"
      sx={{
        background: theme.orange,
        "&:hover": {
          background: theme.warningOrange,
        },
        ...sx,
      }}
      onClick={handleClick}
    >
      Download CoPilot
    </Button>
  );
};
