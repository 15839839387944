import * as React from "react";

export function useCombinedReferences<T>(
  ...refs: React.Ref<T>[]
): React.RefCallback<T> {
  return React.useCallback(
    (element: T) => {
      // eslint-disable-next-line no-restricted-syntax
      for (const ref of refs) {
        if (typeof ref === "function") ref(element);
        else if (ref && typeof ref === "object")
          (ref as React.MutableRefObject<T>).current = element;
      }
    },
    [refs],
  );
}
