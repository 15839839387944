import IconButton from "@samacare/design/core/IconButton";
import { Portal } from "@samacare/graphql";
import DeleteIcon from "@samacare/design/core/icons/Delete";
import { useConfirmDialog } from "../../../hooks/confirmDialogHook";
import { useDeletePortal } from "../../../hooks/useDeletePortal";

interface DeletePortalIconButtonProps {
  portal: Portal;
}
export const DeletePortalIconButton: React.VFC<DeletePortalIconButtonProps> = (
  props
) => {
  const deletePortal = useDeletePortal();
  const [ConfirmDialog, confirmDialogProps, setConfirmDialogOpen] =
    useConfirmDialog({
      message: `Delete the portal named ${props.portal.title}?`,
      confirm: async () => deletePortal(+props.portal.id),
      zIndexOffset: 1,
    });

  return (
    <>
      <IconButton onClick={() => setConfirmDialogOpen(true)}>
        <DeleteIcon />
      </IconButton>
      <ConfirmDialog {...confirmDialogProps} />
    </>
  );
};
