import _ from "lodash";
import moment from "moment";
import gql from "graphql-tag";
import { useLazyQuery } from "@apollo/client";
import { useCallback, useEffect } from "react";
import { styled } from "@samacare/design/core/styles";
import useAutocomplete from "@samacare/design/core/hooks/useAutocomplete";

import {
  Stack,
  Button,
  Grid,
  Popper,
  Paper,
  InputProps,
} from "@samacare/design/core";

import { Patient } from "@samacare/graphql";

import {
  GetPatientByNameQuery,
  GetPatientByNameQueryVariables,
} from "@@generated/graphql";
import {
  useFormContext,
  DateField,
  DateFieldProps,
  TextField,
  TextFieldProps,
  getValidDate,
} from "@samacare/form";

type PatientBlockProps = {
  required?: boolean;
  disabled?: boolean;
  IdInputProps?: TextFieldProps;
  FirstNameInputProps?: TextFieldProps;
  LastNameInputProps?: TextFieldProps;
  DateOfBirthInputProps?: DateFieldProps;
  onSelected?(patientId: string, patient: Patient): Promise<void>;
  onClear?(): void;
};

export const CustomPaper = styled(Paper)(({ theme }) => ({
  marginTop: "15px",

  "& li": {
    borderLeft: "4px solid white",
    paddingLeft: "4px",
  },

  "& .Mui-focused": {
    borderLeft: "4px solid",
    backgroundColor: theme.palette.colors.N100,
  },
}));

const AutocompleteListbox = styled("ul", {
  name: "MuiAutocomplete",
  slot: "Listbox",
  overridesResolver: (props, styles) => styles.listbox,
})(() => ({
  listStyle: "none",
  margin: 0,
  padding: "8px 0",
  maxHeight: "40vh",
  overflow: "auto",
  position: "relative",
}));

const GET_PATIENT_BY_NAME = gql`
  query GetPatientByName($firstName: String, $lastName: String) {
    patientsByName(firstName: $firstName, lastName: $lastName) {
      id
      dob
      firstName
      lastName
      gender
      institutionPatientId
      zip
      city
      state
      phone
      address
      primaryInsurance {
        memberId
        InsuranceCompanyId
        groupNumber
        planType
        insuranceState
        supportsCoverageCheck
      }
      secondaryInsurance {
        memberId
        InsuranceCompanyId
        groupNumber
        planType
        insuranceState
        supportsCoverageCheck
      }
    }
  }
`;

const formatMRN = (input: string) => {
  if (input.length > 13) {
    return `${input.slice(0, 5)}...${input.slice(-5)}`;
  }
  return input;
};

const PatientBlock: React.FC<PatientBlockProps> = ({
  required = false,
  disabled = false,
  IdInputProps = {
    name: "PatientId",
  },
  FirstNameInputProps = {
    name: "patient.firstName",
    label: "First name",
  },
  LastNameInputProps = {
    name: "patient.lastName",
    label: "Last name",
  },
  DateOfBirthInputProps = {
    name: "patient.dob",
    label: "Date of birth",
  },
  onSelected = async () => {},
  onClear = () => {},
}) => {
  const { watch, reset, getValues } = useFormContext();

  const id = watch(IdInputProps.name);
  const firstName = watch(FirstNameInputProps.name);
  const lastName = watch(LastNameInputProps.name);

  const [search, { data }] = useLazyQuery<
    GetPatientByNameQuery,
    GetPatientByNameQueryVariables
  >(GET_PATIENT_BY_NAME);

  const throttledSearch = useCallback(
    _.debounce(search, 500, {
      leading: false,
      trailing: true,
    }),
    []
  );

  const isDisabled = disabled || id != null;

  const {
    anchorEl,
    setAnchorEl,
    popupOpen,
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    getClearProps,
    groupedOptions,
  } = useAutocomplete({
    id: IdInputProps.name,
    disabled: isDisabled,
    value: getValues().patient as Patient,
    options: (data?.patientsByName as unknown as Patient[]) ?? [],
    multiple: false,
    componentName: "PatientBlock",
    getOptionLabel: (option) => option?.id ?? "Unknown",
    isOptionEqualToValue: (option, value) => option?.id === value?.id,
    onChange: async (event, value) => {
      if (value !== null) {
        reset(
          {
            ...getValues(),
            patient: {
              ...getValues().patient,
              ...value,
              dob: getValidDate(value?.dob),
            },
            PatientId: value.id,
          },
          { keepDirty: true }
        );

        await onSelected(value.id, value);
      } else {
        reset(
          {
            ...getValues(),
            patient: null,
            PatientId: null,
          },
          { keepDirty: true }
        );

        onClear();
      }
    },
  });

  useEffect(
    () =>
      throttledSearch({
        variables: {
          firstName,
          lastName,
        },
      }),
    [firstName, lastName, throttledSearch]
  );

  const sharedStyles = {
    padding: "0 !important",
    marginBottom: "10px",
  };

  const { onClick: handleClear } = getClearProps();
  const { onMouseDown: handleInputMouseDown } = getInputProps();

  const { ref: inputPropsRef, ...inputProps } =
    getInputProps() as Partial<InputProps>;

  const rootProps = getRootProps() as React.HTMLAttributes<HTMLDivElement>;

  return (
    <Stack sx={{ position: "relative" }}>
      <Stack gap={1} direction="row" maxWidth={600} ref={setAnchorEl}>
        <TextField
          {...FirstNameInputProps}
          required={required}
          disabled={isDisabled}
          onMouseDown={!isDisabled ? handleInputMouseDown : undefined}
          fullWidth
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          {...LastNameInputProps}
          required={required}
          disabled={isDisabled}
          onMouseDown={!isDisabled ? handleInputMouseDown : undefined}
          fullWidth
          InputLabelProps={{ shrink: true }}
        />
        <DateField
          {...DateOfBirthInputProps}
          required={required}
          disabled={isDisabled}
          fullWidth
          onMouseDown={!isDisabled ? handleInputMouseDown : undefined}
          InputLabelProps={{ shrink: true }}
        />
        {isDisabled && (
          <Button
            type="button"
            variant="contained"
            onClick={handleClear}
            sx={{ minWidth: 100 }}
            disabled={disabled}
          >
            Change
          </Button>
        )}
      </Stack>
      <div
        {...rootProps}
        style={{ ...rootProps.style, position: "absolute", top: "100%" }}
      >
        <TextField
          {...IdInputProps}
          variant="standard"
          InputProps={{ inputRef: inputPropsRef, ...inputProps }}
          type="hidden"
        />
        {groupedOptions.length > 0 && (
          <Popper
            open={popupOpen}
            anchorEl={anchorEl}
            sx={{
              width: anchorEl ? anchorEl.clientWidth : null,
              zIndex: 5,
            }}
          >
            <CustomPaper>
              {groupedOptions.length > 0 && (
                <>
                  <Grid
                    item
                    sx={{
                      marginTop: "10px",
                      fontWeight: 700,
                    }}
                  >
                    Matching patients (click to select)
                  </Grid>
                  <AutocompleteListbox
                    {...(getListboxProps() as React.HTMLAttributes<HTMLUListElement>)}
                  >
                    {(groupedOptions as unknown as Patient[]).map(
                      (option, index) => {
                        if (option == null) {
                          return null;
                        }

                        return (
                          <li
                            key={`${option.firstName}${option.lastName}${option.institutionPatientId}`}
                            {...(getOptionProps({
                              option,
                              index,
                            }) as React.HTMLAttributes<HTMLLIElement>)}
                          >
                            <Grid container spacing={2} sx={{ margin: 0 }}>
                              <Grid
                                item
                                xs={4}
                                sx={sharedStyles}
                              >{`${option.firstName} ${option.lastName}`}</Grid>
                              <Grid item xs={4} sx={sharedStyles}>
                                {moment.parseZone(option.dob).format("LL")}
                              </Grid>
                              {!_.isNil(option.institutionPatientId) && (
                                <Grid
                                  item
                                  xs={3}
                                  sx={sharedStyles}
                                >{`MRN: ${formatMRN(
                                  option.institutionPatientId
                                )}`}</Grid>
                              )}
                            </Grid>
                          </li>
                        );
                      }
                    )}
                  </AutocompleteListbox>
                </>
              )}
            </CustomPaper>
          </Popper>
        )}
      </div>
    </Stack>
  );
};

export default PatientBlock;
