import * as React from "react";
import { useTheme } from "styled-components";
import { Alert, Stack, Typography, Box } from "@samacare/design/core";

import { ChromeWebStoreImage } from "./ChromeWebStoreImage";
import { DownloadCopilotButton } from "./DownloadCopilotButton";

export default function ExtensionAlertBanner() {
  const theme = useTheme();
  const handleWebExtensionClick = () => {
    window.open(
      window.CONFIG.CONSTANTS.WEB_EXTENSION_URL,
      "_blank",
      "noopener,noreferrer"
    );
  };

  return (
    <Stack sx={{ width: "100%", mb: 2 }} spacing={2}>
      <Box
        component="a"
        href={window.CONFIG.CONSTANTS.WEB_EXTENSION_URL}
        target="_blank"
        rel="noopener noreferrer"
        sx={{
          textDecoration: "none",
          width: "100%",
        }}
      >
        <Alert
          action={
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                height: "100%",
                marginRight: 1.25,
              }}
            >
              <DownloadCopilotButton onClick={handleWebExtensionClick} />
            </Box>
          }
          sx={{
            backgroundColor: theme.lightYellow,
            "& .MuiAlert-icon": {
              display: "none",
            },
            py: 1,
            px: 2,
            minHeight: "64px",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <Stack direction="row" alignItems="center" spacing={2}>
            <ChromeWebStoreImage />
            <Typography sx={{ fontWeight: "700", fontSize: "16px" }}>
              Now Available - CoPilot Chrome Extension, Captures and Stores PAs
              From any Portal
            </Typography>
          </Stack>
        </Alert>
      </Box>
    </Stack>
  );
}
