import { ApolloError, useQuery } from "@apollo/client";
import { createContext, useContext, useMemo } from "react";
import { useParams } from "react-router-dom";

import CircularProgress from "@samacare/design/core/CircularProgress";
import { Account } from "@samacare/graphql";

import { SearchUnifiedBenefitsCheckByIdQuery } from "@@generated/graphql";
import { useAccount } from "../../providers/AccountProvider";
import SearchUnifiedBenefitsCheckByIdQueryGql from "./graphql/SearchUnifiedBenefitsCheckById.gql";

export type SearchUnifiedBenefitsCheckByIdResultItem = NonNullable<
  SearchUnifiedBenefitsCheckByIdQuery["unifiedBenefitsChecks"]
>[0];

export type SearchUnifiedBenefitsCheckByIdResultItemDrug = NonNullable<
  SearchUnifiedBenefitsCheckByIdResultItem["drug"]
>;

export type SearchUnifiedBenefitsCheckByIdResultItemFullData = NonNullable<
  SearchUnifiedBenefitsCheckByIdResultItem["fullData"]
>;

type UnifiedBenefitsCheckContextType = {
  loading: boolean;
  error?: ApolloError | null;
  currentAccount?: Account | null;
  unifiedBenefitsCheck?: SearchUnifiedBenefitsCheckByIdResultItem | null;
};

const UnifiedBenefitsCheckContext =
  createContext<UnifiedBenefitsCheckContextType>({
    loading: false,
    error: null,
    currentAccount: null,
    unifiedBenefitsCheck: null,
  });

export const useUnifiedBenefitsCheckContext =
  (): UnifiedBenefitsCheckContextType =>
    useContext<UnifiedBenefitsCheckContextType>(UnifiedBenefitsCheckContext);

export const UnifiedBenefitsCheckProvider: React.FC = ({ children }) => {
  const currentAccount = useAccount();
  const params = useParams<{ id?: string | undefined }>();
  const query = useQuery<SearchUnifiedBenefitsCheckByIdQuery>(
    SearchUnifiedBenefitsCheckByIdQueryGql,
    {
      fetchPolicy: "network-only",
      variables: {
        InstitutionId: currentAccount?.institution?.id,
        id: params.id,
      },
      skip: currentAccount?.institution?.id == null || params.id == null,
    }
  );

  const value = useMemo(() => {
    const { loading, error, data } = query;
    const results = data?.unifiedBenefitsChecks;
    if (results != null && results.length > 1) {
      throw new Error(
        `Unexpected error: more than 1 unified benefits check returned when searching by id: ${params.id}`
      );
    }

    return {
      loading,
      error,
      currentAccount,
      unifiedBenefitsCheck: results?.[0],
    };
  }, [params, query, currentAccount]);

  if (query.loading) {
    return <CircularProgress />;
  }

  return (
    <UnifiedBenefitsCheckContext.Provider value={value}>
      {children}
    </UnifiedBenefitsCheckContext.Provider>
  );
};
