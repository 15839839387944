import _ from "lodash";
import moment from "moment";
import { connect } from "react-redux";
import { PureComponent } from "react";
import { withAlert } from "react-alert";
import { compose } from "recompose";
import styled from "styled-components";
import strings from "Resources/strings";
import { BaseCleave as DateInput, BaseText } from "Segment/StyledComponents";

import {
  withLatestUpdateAuthorizationCorrespondence,
  withUpdateAuthorizationCorrespondence,
} from "../graphql/AuthorizationCorrespondence";
import AlertModal from "./AlertModal";
import BaseButton from "./BaseButton";
import LoadingButton from "./LoadingButton";
import { getDateValidationMessages } from "../util/authUtils";
import { withUpdateAuthorization } from "../graphql/Authorization";
import { isValidDate } from "../util/dateUtils";

const SectionTitle = styled.div`
  color: ${(props) => props.theme.gray};
  font-weight: 700;
  margin-top: 10px;
  margin-right: auto;
`;

const inputTopMargin = "5px";

const HelperText = styled.div`
  font-size: 14px;
  color: ${(props) => props.theme.red};
  margin-top: 10px;
`;

const DetailsButton = styled(BaseButton)`
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 3px;
`;

export class UpdateAuthorizationDetailsButton extends PureComponent {
  constructor(props) {
    super(props);

    if (
      props.correspondenceId &&
      props.authorization &&
      props.authorization.id
    ) {
      throw new Error(
        "Expected only correspondenceId OR authorization.id not both."
      );
      /*
        This component is a little wonky.
        When given an authorization.id, it creates a new latest authorization correspondence, inheriting props from the previous latestCorrespondence
        When given a correspondenceId, it updates the correspondence passed to it.
        When passed both, it defaults to the correspondence, so we're forcing users to be explicit
      */
    }

    this.state = { isLoading: false, openModal: false, ...this.getDetails() };
  }

  onUpdateAuthorizationCorrespondence = async () => {
    const {
      authorization,
      correspondenceId,
      alert,
      latestCorrespondence,
      updateAuthorizationCorrespondence,
      updateLatestAuthorizationCorrespondence,
      updateAuthorization,
    } = this.props;

    const {
      correspondenceStartDate,
      correspondenceEndDate,
      correspondenceCode,
    } = this.state;
    this.setState({ isLoading: true });
    const optionalParams = {};

    if (!_.isEmpty(correspondenceStartDate)) {
      optionalParams.startDate = moment(correspondenceStartDate).toDate();
    } else if (latestCorrespondence && latestCorrespondence.startDate) {
      optionalParams.startDate = null;
    }
    if (!_.isEmpty(correspondenceEndDate)) {
      optionalParams.endDate = moment(correspondenceEndDate).toDate();
    } else if (latestCorrespondence && latestCorrespondence.endDate) {
      optionalParams.endDate = null;
    }
    if (!_.isEmpty(_.trim(correspondenceCode))) {
      optionalParams.code = correspondenceCode;
    } else if (latestCorrespondence && latestCorrespondence.code) {
      optionalParams.code = null;
    }

    try {
      if (correspondenceId) {
        await updateAuthorizationCorrespondence({
          variables: {
            id: correspondenceId,
            patch: optionalParams,
          },
        });
      } else {
        const PAConfig = { ...authorization.config };
        PAConfig[CONFIG.DEFAULT_FIELDS.DATE_OF_SERVICE.key] =
          optionalParams.startDate
            ? moment(optionalParams.startDate).format(
                CONFIG.CONSTANTS.DATE_FORMAT
              )
            : ""; //case when user clean up date in input element - we need reflect it in authorization
        PAConfig[CONFIG.DEFAULT_FIELDS.END_DATE_OF_SERVICE.key] =
          optionalParams.endDate
            ? moment(optionalParams.endDate).format(
                CONFIG.CONSTANTS.DATE_FORMAT
              )
            : ""; //case when user clean up date in input element - we need reflect it in authorization

        await updateAuthorization({
          variables: {
            id: parseInt(authorization.id),
            patch: {
              config: { ...PAConfig },
            },
          },
        });
        if (optionalParams.code) {
          //keeping original behaviour if user enter code
          //not storing dates in to correspondance. Here dates are relates to PA only
          const authorizationId = authorization.id;
          await updateLatestAuthorizationCorrespondence({
            variables: {
              authorizationId,
              code: optionalParams.code,
            },
          });
        }
      }

      this.setState({ openModal: false });
    } catch (e) {
      alert.error(strings.AUTHORIZATION_DETAILS.ERROR_UPDATING);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  onCloseModal = () => {
    // close modal and reset uncommitted changes
    this.setState({ openModal: false, ...this.getDetails() });
  };

  getDetails = () => {
    const { latestCorrespondence, authorization } = this.props;
    let startDate = "";
    let endDate = "";
    const code = _.get(latestCorrespondence, "code")
      ? latestCorrespondence.code
      : "";

    //authorization is primary source of truth. Ref: ENG-1251
    if (authorization) {
      const startDatePA =
        authorization?.config[CONFIG.DEFAULT_FIELDS.DATE_OF_SERVICE.key];
      startDate = isValidDate(startDatePA)
        ? moment(startDatePA).format(CONFIG.CONSTANTS.DATE_FORMAT)
        : "";

      const endDatePA =
        authorization?.config[CONFIG.DEFAULT_FIELDS.END_DATE_OF_SERVICE.key];
      endDate = isValidDate(endDatePA)
        ? moment(endDatePA).format(CONFIG.CONSTANTS.DATE_FORMAT)
        : "";
    } else {
      //Keeping it for backward compatibility. This component is used in other places in app.
      //Ex.: client/app/routes/Support/AssociationModal/SetAutomatedCorrespondence.jsx
      startDate = _.get(latestCorrespondence, "startDate")
        ? moment(latestCorrespondence.startDate).format(
            CONFIG.CONSTANTS.DATE_FORMAT
          )
        : "";
      endDate = _.get(latestCorrespondence, "endDate")
        ? moment(latestCorrespondence.endDate).format(
            CONFIG.CONSTANTS.DATE_FORMAT
          )
        : "";
    }

    return {
      correspondenceStartDate: startDate,
      correspondenceEndDate: endDate,
      correspondenceCode: code,
    };
  };

  render() {
    const { isSupport, authorizationType } = this.props;
    const {
      openModal,
      correspondenceStartDate,
      correspondenceEndDate,
      correspondenceCode,
      authorizedProcedures,
      isLoading,
    } = this.state;

    const { errorMessages, warningMessages } = getDateValidationMessages({
      correspondenceStartDate,
      correspondenceEndDate,
      correspondenceCode,
      authorizedProcedures,
    });
    return (
      <div style={{ marginTop: "5px" }}>
        <AlertModal
          styleOverride={isSupport ? { marginLeft: 325 } : {}}
          buttons={
            <LoadingButton
              style={{ width: "100%" }}
              disabled={!_.isEmpty(errorMessages)}
              loading={isLoading}
              onClick={this.onUpdateAuthorizationCorrespondence}
            >
              Update
            </LoadingButton>
          }
          content={
            <div style={{ display: "flex", flexDirection: "column" }}>
              <SectionTitle>Authorization begins</SectionTitle>
              <DateInput
                disabled={isLoading}
                onChange={(e) => {
                  this.setState({ correspondenceStartDate: e.target.value });
                }}
                options={{ blocks: [2, 2, 4], delimiter: "/" }}
                placeholder={
                  strings.AUTHORIZATION_DETAILS.START_DATE_PLACEHOLDER
                }
                value={correspondenceStartDate || ""}
                style={{ marginTop: inputTopMargin }}
              />
              <SectionTitle>Authorization ends</SectionTitle>
              <DateInput
                disabled={isLoading}
                onChange={(e) => {
                  this.setState({ correspondenceEndDate: e.target.value });
                }}
                options={{ blocks: [2, 2, 4], delimiter: "/" }}
                placeholder={strings.AUTHORIZATION_DETAILS.END_DATE_PLACEHOLDER}
                value={correspondenceEndDate || ""}
                style={{ marginTop: inputTopMargin }}
              />
              <SectionTitle>Authorization Approval Number</SectionTitle>
              <BaseText
                disabled={isLoading}
                onChange={(e) => {
                  this.setState({ correspondenceCode: e.target.value });
                }}
                placeholder={
                  strings.AUTHORIZATION_DETAILS.AUTHORIZATION_NUMBER_PLACEHOLDER
                }
                value={correspondenceCode || ""}
                style={{ marginTop: inputTopMargin }}
              />
              {(!_.isEmpty(errorMessages) || !_.isEmpty(warningMessages)) && (
                <HelperText>
                  {[...(errorMessages || []), ...(warningMessages || [])].join(
                    ", "
                  )}
                </HelperText>
              )}
            </div>
          }
          header={strings.AUTHORIZATION_DETAILS.UPDATE_DETAILS}
          open={openModal}
          closeModal={this.onCloseModal}
        />
        {authorizationType ===
        CONFIG.CONSTANTS.AUTHORIZATION_TYPES.PORTAL_EXTERNAL.key ? null : (
          <DetailsButton
            onClick={(e) => {
              e.stopPropagation();
              this.setState({ openModal: true, ...this.getDetails() });
            }}
            data-cy="clickToEdit"
          >
            + Click to edit
          </DetailsButton>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isSupport: _.get(state.router, "location.pathname").includes("support"),
});

// connect must be executed after compose so that the redux authorizationList store values
// are available for use by the initial authorizationsPaginated fetch on mount
/**
 * @deprecated Use a functional component instead (non HOC)
 */
export default connect(mapStateToProps)(
  compose(
    withLatestUpdateAuthorizationCorrespondence,
    withUpdateAuthorizationCorrespondence,
    withUpdateAuthorization
  )(withAlert()(UpdateAuthorizationDetailsButton))
);
