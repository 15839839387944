import Autocomplete from "@samacare/design/core/Autocomplete";
import TextField from "@samacare/design/core/TextField";
import useStates, {
  type StateOption,
} from "@samacare/form/hooks/useStateOptions";

interface PayerStatesProps {
  payerStates: string[];
  setPayerStates: (states: string[]) => void;
}

export const SupportedStates: React.FC<PayerStatesProps> = ({
  payerStates,
  setPayerStates,
}) => {
  const states = useStates();

  const handleStateChange = (_: unknown, value: StateOption[]) => {
    setPayerStates(value.map((v) => v.id));
  };

  return (
    <Autocomplete
      disableClearable
      selectOnFocus
      handleHomeEndKeys
      autoHighlight
      onChange={handleStateChange}
      value={states.filter((s) => payerStates.includes(s.id))}
      options={states}
      multiple
      renderInput={(params) => (
        <TextField
          {...params}
          InputLabelProps={{ shrink: true }}
          label="States"
        />
      )}
    />
  );
};
