import * as React from "react";
import styled from "styled-components";
import { useForm } from "@samacare/form";
import { PrimaryButton } from "@@ui-kit/PrimaryButton";
import { Flex, Box } from "@@ui-kit/Flexbox";
import { Fieldset, Input, Label, Radio } from "@@ui-kit/forms";
import {
  ModalContent as BaseModalContent,
  ModalFooter as BaseModalFooter,
} from "../ModalStyledComponents";

// TODO(ndhoule): insert me anywhere
const baseWidth = 750;
const imageMargin = 10;

export const ModalBody = styled.div`
  width: ${baseWidth + imageMargin * 2}px;
`;

const ModalFooter = styled(BaseModalFooter)`
  justify-content: center;
`;

const ModalContent = styled(BaseModalContent)`
  padding: 10px 0;
`;

const ModalText = styled.div<{ warn?: boolean }>`
  padding-bottom: 10px;
  text-align: center;
  color: ${({ theme, warn = false }) => (warn ? theme.red : "")};
`;

const LabelHeader = styled.h2`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px;
`;

interface FormValues {
  insuranceCompany: string;
  portalUrl: string;
  usageFrequency: "daily" | "weekly" | "monthly";
}

export type RequestNewPortalIntegrationProps = React.ComponentType<
  typeof RequestNewPortalIntegration
>;

export const RequestNewPortalIntegration: React.VoidFunctionComponent<{
  onCancel: () => void;
  onSubmit: (options: unknown) => void;
}> = ({ onCancel, onSubmit }) => {
  const {
    formState: { errors, isDirty, isValid },
    handleSubmit,
    register,
  } = useForm<FormValues>({ mode: "onChange" });

  return (
    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <ModalBody>
        <ModalContent>
          <ModalText>
            <Flex flexDirection="column" textAlign="left">
              <Box marginBottom="10px">
                <Label htmlFor="portal-url">
                  <LabelHeader>Where do you normally log in?</LabelHeader>
                  <Input
                    id="portal-url"
                    error={errors.portalUrl != null}
                    placeholder="https://www.my-portal-website.com"
                    {...register("portalUrl", { required: true })}
                  />
                </Label>
              </Box>

              <Box marginBottom="10px">
                <Fieldset error={errors.usageFrequency != null}>
                  <Box>
                    <legend>
                      <LabelHeader>
                        How often do you use this portal to submit
                        authorizations (team-wide)?
                      </LabelHeader>
                    </legend>
                  </Box>

                  <Box>
                    <Box marginBottom="4px">
                      <Label htmlFor="usage-frequency-daily">
                        <Radio
                          id="usage-frequency-daily"
                          value="0-3 per week"
                          {...register("usageFrequency", { required: true })}
                        />
                        0-3 per week
                      </Label>
                    </Box>

                    <Box marginBottom="4px">
                      <Label htmlFor="usage-frequency-weekly">
                        <Radio
                          id="usage-frequency-weekly"
                          value="3-10 per week"
                          {...register("usageFrequency", { required: true })}
                        />
                        3-10 per week
                      </Label>
                    </Box>

                    <Box marginBottom="4px">
                      <Label htmlFor="usage-frequency-monthly">
                        <Radio
                          id="usage-frequency-monthly"
                          value="10+ per week"
                          {...register("usageFrequency", { required: true })}
                        />
                        10+ per week
                      </Label>
                    </Box>
                  </Box>
                </Fieldset>
              </Box>

              <Box marginBottom="10px">
                <Label>
                  <LabelHeader>
                    What payer/insurance plan does this portal support (feel
                    free to list more than one if there are multiple)?
                  </LabelHeader>
                  <Input
                    error={errors.insuranceCompany != null}
                    placeholder="Insurance Company..."
                    {...register("insuranceCompany", {
                      required: true,
                    })}
                  />
                </Label>
              </Box>
            </Flex>
          </ModalText>
        </ModalContent>
        <ModalFooter>
          <Flex width={1} paddingX="50px">
            <Box width={1 / 2} paddingRight="5px">
              <PrimaryButton fluid inverted onClick={onCancel}>
                Back
              </PrimaryButton>
            </Box>
            <Box width={1 / 2}>
              <PrimaryButton
                disabled={!isDirty || !isValid}
                fluid
                inverted
                type="submit"
              >
                Submit
              </PrimaryButton>
            </Box>
          </Flex>
        </ModalFooter>
      </ModalBody>
    </form>
  );
};
