import gql from "graphql-tag";
import { graphql } from "@apollo/client/react/hoc";
import _ from "lodash";

import { withDefaultProps } from "./utils";
import insuranceCompanyInfo from "./fragments/insuranceCompanyInfo";
import { useMutation, useQuery } from "@apollo/client";
import {
  InsuranceCompany as InsuranceCompanyType,
  Mutation,
  MutationUpsertInsuranceCompanyArgs,
} from "@samacare/graphql";
import { InsuranceCompaniesQuery } from "../../generated/graphql";

export interface InsuranceCompany {
  id: string;
  name: string;
  searchTags: (string | null)[] | null;
  planParentCompanyId: string | null;
  isArchived: boolean;
  supportedPlanTypes: string[];
  supportedStates: string[];
}

const INSURANCE_COMPANIES_QUERY = "insuranceCompanies";
export const insuranceCompaniesQuery = gql`
  query insuranceCompanies {
    insuranceCompaniesFindAll {
      ...insuranceCompanyInfo
    }
  }
  ${insuranceCompanyInfo}
`;
export const withInsuranceCompanies = graphql(insuranceCompaniesQuery, {
  props: withDefaultProps(({ data }) => ({
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    insuranceCompanies: _.get(data, "insuranceCompaniesFindAll"),
  })),
});

export const useInsuranceCompanies = () => {
  const { data, loading } = useQuery<InsuranceCompaniesQuery>(
    insuranceCompaniesQuery
  );

  return {
    insuranceCompanies: (data?.insuranceCompaniesFindAll ??
      []) as InsuranceCompany[],
    loading,
  };
};

const upsertInsuranceCompanyMutation = gql`
  mutation upsertInsuranceCompany(
    $id: Int
    $name: String!
    $parentCompanyName: String
    $parentId: Int
    $searchTags: [String!]
    $supportedCodingTypes: InsuranceCompanySupportedCodingTypes!
    $supportedPlanTypes: [String!]!
    $supportedStates: [String!]!
  ) {
    upsertInsuranceCompany(
      id: $id
      name: $name
      parentCompanyName: $parentCompanyName
      parentId: $parentId
      searchTags: $searchTags
      supportedCodingTypes: $supportedCodingTypes
      supportedPlanTypes: $supportedPlanTypes
      supportedStates: $supportedStates
    ) {
      ...insuranceCompanyInfo
    }
  }
  ${insuranceCompanyInfo}
`;

export const withUpsertInsuranceCompany = graphql(
  upsertInsuranceCompanyMutation,
  {
    name: "upsertInsuranceCompany",
    options: () => ({
      refetchQueries: () => [INSURANCE_COMPANIES_QUERY],
      awaitRefetchQueries: true,
    }),
  }
);

const withUpdateInsuranceCompanyMutation = gql`
  mutation updateInsuranceCompany(
    $id: Int!
    $patch: InsuranceCompanyUpdatePatch
  ) {
    updateInsuranceCompanyById(id: $id, patch: $patch) {
      ...insuranceCompanyInfo
    }
  }
  ${insuranceCompanyInfo}
`;
export const withUpdateInsuranceCompany = graphql(
  withUpdateInsuranceCompanyMutation,
  {
    name: "updateInsuranceCompany",
    options: () => ({
      refetchQueries: () => [INSURANCE_COMPANIES_QUERY],
      awaitRefetchQueries: true,
    }),
  }
);

export const useUpsertInsuranceCompany = () => {
  const [upsertInsuranceCompany] = useMutation<
    { upsertInsuranceCompany: Mutation["upsertInsuranceCompany"] },
    MutationUpsertInsuranceCompanyArgs
  >(upsertInsuranceCompanyMutation, {
    refetchQueries: [INSURANCE_COMPANIES_QUERY],
  });

  const upsert = async (insuranceCompany: InsuranceCompanyType) => {
    const result = await upsertInsuranceCompany({
      variables: {
        id: +insuranceCompany.id,
        name: insuranceCompany.name,
        parentCompanyName: insuranceCompany.planParentCompanyId,
        parentId: insuranceCompany.planParentCompanyId
          ? +insuranceCompany.planParentCompanyId
          : null,
        searchTags: insuranceCompany.searchTags as string[],
        supportedCodingTypes: insuranceCompany.supportedCodingTypes,
        supportedStates: insuranceCompany.supportedStates,
        supportedPlanTypes: insuranceCompany.supportedPlanTypes,
      },
    });

    return result.data?.upsertInsuranceCompany;
  };

  return upsert;
};
