import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useAlert } from "react-alert";
import WarningIcon from "@samacare/design/core/icons/Warning";
import BaseButton from "../../../components/BaseButton";
import {
  useCurrentAccount,
  useUpsertAccountForSamaUsersMutation,
} from "../../../graphql/Account";
import { TableContainer, TableHeader } from "../AdminStyles";
import AccountRow from "./AccountRow";
import {
  Button,
  LoadingButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Typography,
} from "@samacare/design/core";
import { FormProvider, useForm, TextField, Resolver } from "@samacare/form";
import { object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Stack from "@samacare/design/core/Stack";
import { commonEmailDomains } from "./commonEmailDomains";
import { ConfirmationDialog } from "../../../components/ConfirmationDialog";

interface Account {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
}

const generateAccountRow = (
  user: Account,
  isSamaUser: boolean,
  isAdmin: boolean
) => (
  <AccountRow
    key={`account-id-${user.id}`}
    account={user}
    isSamaUser={isSamaUser}
    isAdmin={isAdmin}
  />
);

type NewAccount = {
  firstName: string;
  lastName: string;
  email: string;
};
const schema = object<NewAccount>({
  firstName: string().required("First name is required"),
  lastName: string().required("Last name is required"),
  email: string()
    .email("Email must be a valid email")
    .required("Email is required"),
});
const CreateAccountDialog: React.FC<{
  open: boolean;
  institutionEmailDomains: string[];
  onCreate: (acct: NewAccount) => void;
  onClose: () => void;
  loading: boolean;
}> = ({ open, onCreate, onClose, institutionEmailDomains, loading }) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [currentAccount] = useCurrentAccount();

  const methods = useForm<NewAccount>({
    resolver: yupResolver(schema) as Resolver<NewAccount, any>,
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
    },
  });

  useEffect(() => {
    if (open) {
      methods.reset();
    }
  }, [open, methods]);

  const emailAddress = methods.watch("email");
  const emailDomain =
    emailAddress.includes("@") && _.last(emailAddress.split("@"));
  const warnings = [];
  if (emailDomain) {
    if (commonEmailDomains.includes(emailDomain))
      warnings.push("Organization email addresses are preferred");

    if (!institutionEmailDomains.includes(emailDomain))
      warnings.push("Email domain does not match other members");
  }

  const fieldData = methods.watch();

  return (
    <>
      <Dialog open={open} onClose={onClose} scroll="paper" maxWidth="xs">
        <DialogTitle>Create user</DialogTitle>
        <DialogTitle>
          <Typography variant="body1" color="primary.main">
            {currentAccount?.institution?.name}
          </Typography>
        </DialogTitle>
        <FormProvider {...methods}>
          <form>
            <DialogContent>
              <Stack direction="column" spacing={2}>
                <Stack direction="row" spacing={2}>
                  <TextField
                    fullWidth
                    required
                    label="First Name"
                    name="firstName"
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    autoFocus
                  />
                  <TextField
                    fullWidth
                    label="Last Name"
                    name="lastName"
                    required
                  />
                </Stack>
                <TextField fullWidth label="Email" name="email" required />
                <FormHelperText>
                  {warnings.length ? (
                    <>
                      <WarningIcon fontSize="small" color="warning" />
                      {_.first(warnings)}
                    </>
                  ) : (
                    " "
                  )}
                </FormHelperText>
              </Stack>
            </DialogContent>
          </form>
          <DialogActions>
            <Button
              data-cy="actionCloseCreateAccount"
              variant="outlined"
              onClick={() => {
                onClose();
              }}
            >
              Close
            </Button>
            <LoadingButton
              onClick={async () =>
                methods.trigger().then((valid) => valid && setShowConfirm(true))
              }
              data-cy="actionCreateAccount"
              variant="contained"
              color="primary"
              loading={loading}
            >
              Create
            </LoadingButton>
          </DialogActions>
        </FormProvider>
      </Dialog>
      <ConfirmationDialog
        open={showConfirm}
        onClose={() => {
          setShowConfirm(false);
        }}
        onConfirm={async () => {
          setShowConfirm(false);
          onCreate(fieldData);
        }}
      >
        <Typography sx={{ my: 2 }} variant="h6" color="black">
          Confirm user details
        </Typography>
        <div>
          Institution name:&nbsp;
          <b>{currentAccount?.institution?.name}</b>
        </div>
        <div>
          User name:&nbsp;
          <b>
            {fieldData.firstName} {fieldData.lastName}
          </b>
        </div>
        <div>
          User email:&nbsp;
          <b>{fieldData.email}</b>
        </div>
        <p>
          Is this correct?
          <br />
          If not, please update info and try again
        </p>
      </ConfirmationDialog>
    </>
  );
};

interface AccountsTableProps {
  accountList: Account[];
  isSamaUser: boolean;
  isAdmin: boolean;
}
const AccountsTable: React.FC<AccountsTableProps> = ({
  accountList,
  isSamaUser,
  isAdmin,
}) => {
  const alert = useAlert();
  const [upsertAccountForSamaUsers] = useUpsertAccountForSamaUsersMutation();
  const [showCreateAccount, setShowCreateAccount] = useState(false);
  const [loading, setLoading] = useState(false);
  let selectedColumns = ["Name (click to edit)", "Phone"];

  if (isSamaUser || isAdmin) {
    selectedColumns = selectedColumns.concat(["IsAdmin", "Is Read Only", ""]);
  }

  const institutionEmailDomains = _.compact(
    _.uniq(_.map(accountList, (acct) => _.last(acct.email.split("@"))))
  );

  const sortedAccountList = useMemo(
    () => _.sortBy(accountList, ["lastName", "firstName"]),
    [accountList]
  );

  const onCreateAccount = async (newAccount: NewAccount) => {
    try {
      setLoading(true);
      await upsertAccountForSamaUsers({
        variables: {
          patch: {
            ...newAccount,
          },
        },
      });
      alert.success("Success");
      setShowCreateAccount(false);
    } catch (err: unknown) {
      const e = err as Error;
      alert.error(`There was an error creating this account, ${e.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <TableContainer>
        <thead>
          <tr>
            {selectedColumns.map((columnName) => (
              <TableHeader key={`user-column-${columnName}`}>
                {columnName !== _.last(selectedColumns) ? (
                  <div style={{ padding: "0 16px" }}>{columnName}</div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ padding: "0 16px" }}>{columnName}</div>
                    <BaseButton
                      data-cy="actionNewAccount"
                      style={{ width: "75px" }}
                      onClick={async () => setShowCreateAccount(true)}
                    >
                      New
                    </BaseButton>
                  </div>
                )}
              </TableHeader>
            ))}
          </tr>
        </thead>
        <tbody>
          {sortedAccountList.map((acct) =>
            generateAccountRow(acct, isSamaUser, isAdmin)
          )}
        </tbody>
      </TableContainer>

      <CreateAccountDialog
        open={showCreateAccount}
        institutionEmailDomains={institutionEmailDomains}
        onCreate={onCreateAccount}
        onClose={() => setShowCreateAccount(false)}
        loading={loading}
      />
    </>
  );
};

export default AccountsTable;
