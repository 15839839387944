import _ from "lodash";
import { Stack, FormHelperText } from "@samacare/design/core";
import { QuestionnaireField } from "../../components/Questionnaire";
import { QuestionTypeEnumType } from "@samacare/graphql";
import { useFormContext, ErrorMessage, useWatch } from "@samacare/form";

export type EnrollmentServiceTypeBlockProps = {
  isSubmitted?: boolean;
  isRequired?: boolean;
};

export const EnrollmentServiceTypeBlock: React.FC<
  EnrollmentServiceTypeBlockProps
> = ({ isSubmitted = false, isRequired = false }) => {
  const { register, formState, control } = useFormContext();

  const questionnaireData = useWatch({
    name: [
      "questionnaire.BenefitsInvestigation",
      "questionnaire.PriorAuthorizationAssistance",
      "questionnaire.CoPayCardProgram",
      "questionnaire.PatientAssistanceProgram",
    ],
    control,
  });

  const isServiceTypeSelected = _.some(questionnaireData, (item) => item);

  return (
    <Stack my={2} ml={2} spacing={2} direction="column">
      <input
        type="hidden"
        {...register("serviceType", {
          validate: () => isServiceTypeSelected || "Please select a service",
        })}
      />
      <ErrorMessage
        errors={formState.errors}
        name="serviceType"
        render={({ message }) => (
          <FormHelperText error>{message}</FormHelperText>
        )}
      />
      <QuestionnaireField
        id="BenefitsInvestigation"
        type={QuestionTypeEnumType.Boolean}
        text="Benefits Investigation"
        disabled={isSubmitted}
        required={isRequired}
      />
      <QuestionnaireField
        id="PriorAuthorizationAssistance"
        type={QuestionTypeEnumType.Boolean}
        text="Prior Authorization Assistance"
        disabled={isSubmitted}
        required={isRequired}
      />
      <QuestionnaireField
        id="CoPayCardProgram"
        type={QuestionTypeEnumType.Boolean}
        text="Co-Pay Card Program"
        disabled={isSubmitted}
        required={isRequired}
      />
      <QuestionnaireField
        id="PatientAssistanceProgram"
        type={QuestionTypeEnumType.Boolean}
        text="Patient Assistance Program"
        disabled={isSubmitted}
        required={isRequired}
      />
    </Stack>
  );
};
