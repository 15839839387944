import * as React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  GridCloseIcon,
  IconButton,
} from "@samacare/design/core";
import { useTheme } from "styled-components";

export const DialogModal: React.VoidFunctionComponent<{
  open: boolean;
  onClose: () => void;
  title: string;
  content: React.ReactNode;
  actions: React.ReactNode;
  showCloseIcon?: boolean;
  sx?: object;
}> = ({ open, onClose, title, content, actions, showCloseIcon = true, sx }) => {
  const theme = useTheme();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="paper"
      maxWidth="lg"
      sx={{
        "& .MuiDialog-paper": {
          borderTop: `4px solid ${theme.lightYellow}`,
          borderRadius: "8px",
          width: "600px",
          ...sx,
        },
      }}
    >
      <DialogTitle
        sx={{
          textAlign: "left",
          py: 1.25,
          px: 3,
          fontSize: "20px !important",
          fontWeight: "bold",
        }}
      >
        {title}
      </DialogTitle>
      {showCloseIcon && (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 6,
            color: theme.gray,
          }}
        >
          <GridCloseIcon />
        </IconButton>
      )}
      <DialogContent
        sx={{
          p: 2.5,
          m: 2.5,
          mt: 0,
        }}
      >
        {content}
      </DialogContent>
      <DialogActions
        sx={{
          backgroundColor: theme.lightYellow,
          py: 1.25,
          px: 3,
          justifyContent: "flex-end",
        }}
      >
        {actions}
      </DialogActions>
    </Dialog>
  );
};
