import React from "react";
import { Box, Typography } from "@samacare/design/core";
import { useTheme } from "styled-components";

interface WidgetIconProps {
  width?: number;
}

export const WidgetIcon: React.FC<WidgetIconProps> = ({ width = 64 }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        position: "relative",
        marginRight: 0,
        display: "flex",
        borderRadius: "6px 0 0 6px",
        overflow: "hidden",
        width: `${width}px`,
        height: "48px",
        backgroundColor: theme.purple,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          backgroundColor: theme.purple,
          width: "48px",
        }}
      >
        <Box
          component="img"
          src="../../../../assets/sc-icon-white.svg"
          alt="Icon"
          sx={{ width: "18px", height: "auto" }}
        />
        <Typography
          component="h3"
          sx={{
            margin: 0,
            color: theme.white,
            fontWeight: 500,
            fontSize: "12px",
            marginTop: 0.5,
          }}
        >
          Save
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexGrow: 1,
          backgroundColor: theme.darkestPurple,
        }}
      >
        <Box
          component="img"
          src="../../../../assets/drag-indicator.svg"
          alt="Drag Indicator"
          sx={{ width: "16px" }}
        />
      </Box>
    </Box>
  );
};
