import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { useEffect, useCallback } from "react";
import * as React from "react";
import { Controller, useForm } from "@samacare/form";
import { Box, Flex, PrimaryButton } from "@@ui-kit";
import { Input, Select, Form } from "@@ui-kit/forms";
import {
  Institution,
  InsuranceCompany,
  AuthorizationCorrespondence,
} from "@samacare/graphql";

import {
  PrescribersFindAllQuery,
  PrescribersFindAllQueryVariables,
} from "@@generated/graphql";

const getPrescribers = gql`
  query prescribersFindAll($InstitutionIdOverride: String) {
    prescribersFindAll(InstitutionIdOverride: $InstitutionIdOverride) {
      id
      firstName
      lastName
    }
  }
`;

interface SearchAuthorizationsValues {
  firstName: string;
  lastName: string;
  AuthorizationId: string;
  memberId: string;
  institutionSelection: { value: string; label: string } | null;
  prescriberSelection: { value: string; label: string } | null;
  insuranceCompanySelection: { value: string; label: string } | null;
}

const defaultFormValues: SearchAuthorizationsValues = {
  firstName: "",
  lastName: "",
  AuthorizationId: "",
  memberId: "",
  institutionSelection: { label: "", value: "" },
  prescriberSelection: { label: "", value: "" },
  insuranceCompanySelection: { label: "", value: "" },
};

export const SearchFilters: React.VoidFunctionComponent<{
  onSearch: (searchOptions: {
    lastName: string;
    firstName: string;
    AuthorizationId: string;
    memberId: string;
    InstitutionId: string;
    PrescriberId: string;
    InsuranceCompanyId: string;
  }) => void;
  selectedResponse: AuthorizationCorrespondence | null;
  institutions: Institution[] | undefined;
  insuranceCompanies: InsuranceCompany[] | undefined;
}> = ({ onSearch, institutions, insuranceCompanies, selectedResponse }) => {
  const context = useForm<SearchAuthorizationsValues>({
    defaultValues: defaultFormValues,
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const {
    formState: { isDirty },
    getValues,
    handleSubmit,
    register,
    setValue,
    // trigger: triggerValidation,
    // watch,
  } = context;
  const selectedInstititution = getValues("institutionSelection");
  const { data, refetch } = useQuery<
    PrescribersFindAllQuery,
    PrescribersFindAllQueryVariables
  >(getPrescribers, {
    variables: { InstitutionIdOverride: selectedInstititution?.value },
    skip: selectedInstititution === null || selectedInstititution.value === "",
  });

  const refetchPatients = useCallback(
    (InstitutionIdOverride: string) => {
      void refetch({ InstitutionIdOverride });
    },
    [refetch]
  );
  useEffect(() => {
    if (institutions == null) return;
    const institution = institutions.find(
      ({ id }) => id === selectedResponse?.InstitutionId
    );
    setValue(
      "institutionSelection",
      institution == null
        ? null
        : { label: institution.name, value: institution.id }
    );

    if (institution) {
      void refetch({ InstitutionIdOverride: institution.id });
    }

    setValue("insuranceCompanySelection", null);
    setValue("prescriberSelection", null);
    setValue("firstName", "");
    setValue(
      "AuthorizationId",
      selectedResponse == null ||
        selectedResponse.preAssociationAuthorizationId == null
        ? ""
        : selectedResponse.preAssociationAuthorizationId
    );
    setValue(
      "lastName",
      selectedResponse == null || selectedResponse.patientLastName == null
        ? ""
        : selectedResponse.patientLastName
    );
    setValue(
      "memberId",
      selectedResponse == null || selectedResponse.patientMemberId == null
        ? ""
        : selectedResponse.patientMemberId
    );
  }, [selectedResponse, setValue, institutions, refetch]);

  if (institutions == null || insuranceCompanies == null) {
    return <div>Loading...</div>;
  }

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Form
        context={context}
        onSubmit={handleSubmit(
          ({
            firstName,
            lastName,
            AuthorizationId,
            memberId,
            institutionSelection,
            prescriberSelection,
            insuranceCompanySelection,
          }) => {
            onSearch({
              lastName,
              firstName,
              AuthorizationId,
              memberId,
              InstitutionId:
                institutionSelection !== null ? institutionSelection.value : "",
              PrescriberId:
                prescriberSelection !== null ? prescriberSelection.value : "",
              InsuranceCompanyId:
                insuranceCompanySelection !== null
                  ? insuranceCompanySelection.value
                  : "",
            });
          }
        )}
      >
        <Flex marginLeft="10px" flexDirection="column">
          <Flex flexDirection="row">
            <Input placeholder="First Name" {...register("firstName")} />
            <Input
              style={{ marginLeft: "5px" }}
              placeholder="Last Name"
              {...register("lastName")}
            />
            <Input
              style={{ marginLeft: "5px" }}
              placeholder="Member ID"
              {...register("memberId")}
            />
            <Input
              style={{ marginLeft: "5px" }}
              placeholder="Auth ID"
              {...register("AuthorizationId")}
            />
          </Flex>
          <Flex flexDirection="row" marginTop="10px">
            <div
              style={{ display: "flex", flexDirection: "column", flex: "1" }}
            >
              <Controller
                name="institutionSelection"
                render={({ field }) => (
                  <div>
                    <Select
                      placeholder="Practice"
                      isClearable
                      options={institutions.map(({ id, name }) => ({
                        label: name,
                        value: id,
                      }))}
                      {...field}
                      // @ts-ignore
                      onChange={(val: { value: string; label: string }) => {
                        if (val !== null && val.value !== "") {
                          void refetchPatients(val.value);
                        }
                        // Clear out prescriber selection when selecting insurance company
                        setValue("prescriberSelection", null);

                        field.onChange(val);
                      }}
                    />
                  </div>
                )}
              />
            </div>
          </Flex>
          <Flex flexDirection="row" marginTop="10px">
            <Flex flexDirection="row" marginRight="5px">
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Controller
                  name="prescriberSelection"
                  render={({ field }) => (
                    <div style={{ width: "350px", marginRight: "5px" }}>
                      <Select
                        placeholder="Provider"
                        isClearable
                        options={
                          data
                            ? data.prescribersFindAll.map(
                                ({ id, firstName, lastName }) => ({
                                  label: `${firstName as string} ${
                                    lastName as string
                                  }`,
                                  value: id,
                                })
                              )
                            : []
                        }
                        {...field}
                      />
                    </div>
                  )}
                />
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Controller
                  name="insuranceCompanySelection"
                  render={({ field }) => (
                    <div style={{ width: "350px", marginRight: "5px" }}>
                      <Select
                        placeholder="Insurer"
                        isClearable
                        options={insuranceCompanies.map(({ id, name }) => ({
                          label: name,
                          value: id,
                        }))}
                        {...field}
                      />
                    </div>
                  )}
                />
              </div>
            </Flex>
            <Box marginTop="auto" paddingLeft="10px" width={1 / 8}>
              <PrimaryButton disabled={!isDirty} fluid type="submit">
                Search
              </PrimaryButton>
            </Box>
          </Flex>
        </Flex>
      </Form>
    </div>
  );
};
